import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Block, Color, FlexCol} from '../../../../Components/Widget';
import {SHIPPING_TYPE} from '../../../../Domain/Order';
import {ReturnInvoice} from './ReturnInvoice';
import RectButton, {
  BUTTON_SIZE,
  BUTTON_SKIN,
} from '../../../../Components/RectButton';
import {useDimension} from '../../../../Hooks/AppHooks';
import {ReturnItems} from './ReturnItemList';
import {
  getPhoneValidator,
  getRequiredValidator,
  validateForm,
} from '../../../../Utils/validateUtil';
import {Actions, NavActions} from '../../../../Contexts/AppContext';
import Confirm from '../../../../Components/Modal/Confirm';
import OrderConfirmIcon from '../../../../images/illustration-order-confirm.svg';
import {AddressItem} from './AddressItem';
import useAdministrativeRegions from '../../../../Hooks/useAdministrativeRegions';
import {AddCvsStoreButton} from '../../../../Components/Address/AddCvsStoreButton';
import {AddAddressButton} from '../../../../Components/Address/AddAddressButton';
import {Row} from '../../BoxUI';
import TextInput from '../../../../Components/TextInput';
import Select from '../../../../Components/Select';

export const returnReason = {
  INVALID_SIZE: 'invalid-size',
  INVALID_COLOR: 'invalid-color',
  INVALID_IMAGINATION: 'invalid-imagination',
  INVALID_VARIANT: 'invalid-variant',
  ELSE: 'else',
};

export const returnReasonDisPlay = {
  [returnReason.INVALID_SIZE]: {zh: '尺寸不合', en: 'invalid-size'},
  [returnReason.INVALID_COLOR]: {zh: '顏色色偏', en: 'invalid-color'},
  [returnReason.INVALID_IMAGINATION]: {
    zh: '與想像不符',
    en: 'invalid-imagination',
  },
  [returnReason.INVALID_VARIANT]: {zh: '送錯商品', en: 'invalid-variant'},
  [returnReason.ELSE]: {zh: '其他', en: 'else'},
};

export const returnReasonOptions = [
  {display: '尺寸不合', value: returnReason.INVALID_SIZE},
  {display: '顏色色偏', value: returnReason.INVALID_COLOR},
  {display: '與想像不符', value: returnReason.INVALID_IMAGINATION},
  {display: '送錯商品', value: returnReason.INVALID_VARIANT},
  {display: '其他', value: returnReason.ELSE},
];

const Return = props => {
  const {subOrderId, orderId} = props;
  const {desktop} = useDimension();
  const [subOrder, setSubOrder] = useState(null);

  const [addrList, setAddrList] = useState([]);

  const fetchAddress = useCallback(async () => {
    try {
      setAddrList(await Actions.fetchMyAddrs());
    } catch (ex) {
      console.warn(ex);
    }
  }, []);

  useEffect(() => {
    fetchAddress();
  }, [fetchAddress]);

  const [selectedAddressId, setSelectedAddressId] = useState(-1);

  const preCheckoutAddress = useMemo(() => {
    if (!subOrder) {
      return null;
    }
    if (subOrder.shipping_type === SHIPPING_TYPE.cvs) {
      return null;
    }
    return {
      receiverName: subOrder.receiver_name,
      receiverPhone: subOrder.receiver_phone,
      city: subOrder.city,
      district: subOrder.district,
      detail: subOrder.address,
      shippingType: subOrder.shipping_type,
    };
  }, [subOrder]);

  const currentAddress = useMemo(() => {
    if (!addrList || !subOrder) {
      return null;
    }
    const primaryAddress = addrList.find(
      addr => addr.primary && addr.shippingType === SHIPPING_TYPE.home,
    );

    if (selectedAddressId !== -1) {
      return addrList.find(addr => addr.id === selectedAddressId);
    }

    if (!!preCheckoutAddress) {
      return preCheckoutAddress;
    }
    if (!!primaryAddress) {
      setSelectedAddressId(primaryAddress.id);
      return primaryAddress;
    }
    return null;
  }, [addrList, preCheckoutAddress, selectedAddressId, subOrder]);

  const [addressValues, setAddressValues] = useState({
    receiverName: '',
    receiverPhone: '',
    city: '',
    district: '',
    detail: '',
  });
  const [addressErrors, setAddressErrors] = useState({
    receiverName: null,
    receiverPhone: null,
    city: null,
    district: null,
    detail: null,
  });
  const {townOptions, countyOptions} = useAdministrativeRegions(
    addressValues.city,
  );

  const addressValidators = useMemo(() => {
    return {
      receiverName: getRequiredValidator(),
      receiverPhone: getPhoneValidator(),
      city: getRequiredValidator(),
      district: getRequiredValidator(),
      detail: getRequiredValidator(),
    };
  }, []);

  const onAddressChange = field => e => {
    setAddressValues({...addressValues, [field]: e.target.value});
    setAddressErrors({
      ...addressErrors,
      [field]: addressValidators[field].passRequest(e.target.value),
    });
  };

  const fetchSubOrder = useCallback(async () => {
    const resp = await Actions.fetchSubOrder(subOrderId);
    setSubOrder(resp);
  }, [subOrderId]);

  useEffect(() => {
    fetchSubOrder();
  }, [fetchSubOrder]);

  useEffect(() => {
    if (!subOrder) {
      return;
    }
    setValues(values => ({
      ...values,
      returnList: subOrder.order_items.map(item => ({
        ...item,
        id: item.variant.id,
        returnReason: '',
        returnQuantity: 1,
        note: '',
        isOn: false,
      })),
    }));
    setErrors(errors => ({
      ...errors,
      returnList: subOrder.order_items.map(item => ({
        id: item.variant.id,
        returnReason: null,
      })),
    }));
  }, [subOrder]);

  const [values, setValues] = useState({returnList: []});
  const [errors, setErrors] = useState({returnList: []});
  const [returnDetail, setReturnDetail] = useState({
    returnAmount: 0,
    returnCashAmount: 0,
    returnRebateAmount: 0,
  });

  const validators = useMemo(() => {
    return {
      returnReason: getRequiredValidator(),
    };
  }, []);

  const onReturnItemValidate = id => field => {
    const value = values.returnList.find(item => item.id === id)[field];
    const error = validators[field].passRequest(value);
    const nextReturnListErrors = [...errors.returnList];
    nextReturnListErrors.find(item => item.id === id)[field] = error;
    setErrors({
      ...errors,
      returnList: nextReturnListErrors,
    });
  };

  const getIsReturnListFormAllPassValidation = shouldValidateItemIds => {
    const nextReturnListErrors = shouldValidateItemIds.map(id => {
      return [...errors.returnList].find(item => item.id === id);
    });

    Object.keys(validators).forEach(field => {
      nextReturnListErrors.forEach(item => {
        item[field] = validators[field].passRequest(
          values.returnList.find(i => i.id === item.id)[field],
        );
      });
    });

    setErrors({
      ...errors,
      returnList: [...errors.returnList, nextReturnListErrors],
    });

    let isAllPass = false;
    Object.keys(validators).forEach(field => {
      isAllPass = nextReturnListErrors.every(item => item[field] === null);
    });
    return isAllPass;
  };

  const returnPreCalculate = useCallback(async () => {
    try {
      const itemsReadyToPreCalculate = values.returnList.filter(
        item => item.isOn,
      );

      const _post = {
        id: subOrderId,
        items: itemsReadyToPreCalculate.map(item => ({
          variant_id: item.id,
          quantity: item.returnQuantity,
        })),
      };

      const resp = await Actions.returnPreCalculate(_post);

      setReturnDetail(prev => ({
        ...prev,
        returnAmount: resp.return_amount,
        returnCashAmount: resp.return_cash_amount,
        returnRebateAmount: resp.return_rebate_amount,
      }));
    } catch (e) {
      setReturnDetail(null);
    }
  }, [subOrderId, values.returnList]);

  useEffect(() => {
    returnPreCalculate();
  }, [returnPreCalculate]);

  const shouldValidateItemIds = useMemo(() => {
    return values.returnList
      .filter(item => item.isOn === true)
      .map(item => item.id);
  }, [values.returnList]);

  const onSubmit = async () => {
    const isAddressValidatePass = validateForm({
      values: currentAddress || addressValues,
      errors: addressErrors,
      setErrors: setAddressErrors,
      validators: addressValidators,
    });

    const isReturnItemListFormAllPassValidation = getIsReturnListFormAllPassValidation(
      shouldValidateItemIds,
    );

    if (!isReturnItemListFormAllPassValidation) {
      alert('請選擇退貨原因');
      return;
    }
    if (!isAddressValidatePass) {
      return;
    }

    const itemsReadyToReturn = values.returnList.filter(item => item.isOn);

    const submitAddress = currentAddress || {
      ...addressValues,
      city:
        countyOptions.find(county => county.value === addressValues.city)
          ?.display || '',
      district:
        townOptions.find(town => town.value === addressValues.district)
          ?.display || '',
    };

    Actions.setGlobalModalContent(
      <Confirm
        icon={<img src={OrderConfirmIcon} alt="order confirm" />}
        title="退單資訊是否正確無誤？"
        subTitle="提醒您！每筆訂單中，單一品牌僅能申請退貨「一次」"
        cancelLabel="返回"
        confirmLabel="確定"
        onClick={async () => {
          const _post = {
            id: subOrderId,
            values: {
              receiver_name: submitAddress.receiverName,
              receiver_phone: submitAddress.receiverPhone,
              city: submitAddress.city,
              district: submitAddress.district,
              address: submitAddress.detail,
              items: itemsReadyToReturn.map(item => ({
                variant_id: item.id,
                quantity: item.returnQuantity,
                cancel_reason:
                  item.returnReason === returnReason.ELSE
                    ? item.note
                    : item.returnReason,
              })),
            },
          };

          try {
            const resp = await Actions.returnOrder(_post);
            const {success, id} = resp;
            if (!success) {
              throw new Error('api failed');
            }
            NavActions.navigate(
              `/profile?action=return_detail&active=orders&orderId=${orderId}&returnOrderId=${id}`,
            );
          } catch (e) {
            alert('退貨失敗');
          } finally {
            Actions.setGlobalModalContent(null);
          }
        }}
      />,
    );
  };

  const onCheckReturnItem = item => {
    const nextReturnList = [...values.returnList];
    const target = values.returnList.find(_item => _item['id'] === item.id);
    nextReturnList.find(_item => _item.id === item.id).isOn = !target.isOn;
    setValues({...values, returnList: nextReturnList});
  };

  const onReturnItemValueChange = field => id => e => {
    const nextReturnList = [...values.returnList];
    nextReturnList.find(item => item.id === id)[field] = e.target.value;
    setValues({...values, returnList: nextReturnList});
  };

  const counterOperatorFuncs = useMemo(
    () => ({
      onPlus: id => {
        const nextReturnList = [...values.returnList];
        const target = nextReturnList.find(item => item.id === id);
        if (target.returnQuantity >= target.quantity) {
          return;
        }
        target.returnQuantity++;
        setValues({...values, returnList: nextReturnList});
      },
      onMinus: id => {
        const nextReturnList = [...values.returnList];
        const target = nextReturnList.find(item => item.id === id);
        if (target.returnQuantity <= 1) {
          return;
        }
        target.returnQuantity--;
        setValues({...values, returnList: nextReturnList});
      },
    }),
    [values],
  );

  if (!subOrder) {
    return null;
  }

  return (
    <Wrapper desktop={desktop}>
      <section className="return-items">
        <h1>1. 退貨項目</h1>
        <ReturnItems
          header={{
            store_name: subOrder.store_name,
            brand_name: subOrder.brand_name,
          }}
          returnList={values.returnList}
          onCheckReturnItem={onCheckReturnItem}
          onReturnItemValueChange={onReturnItemValueChange}
          counterOperatorFuncs={counterOperatorFuncs}
          onReturnItemValidate={onReturnItemValidate}
          errors={errors.returnList}
        />
      </section>

      <section className="pickup-address">
        <h1>2. 取件地址</h1>
        {currentAddress ? (
          <AddressItem
            fetchAddress={fetchAddress}
            initSelectedAddrId={selectedAddressId}
            item={currentAddress}
            addrs={addrList}
            onSelectAddress={id => {
              setSelectedAddressId(id);
            }}
            isEnableEdit={true}
            AddCvsStoreButton={AddCvsStoreButton}
            AddAddressButton={AddAddressButton}
            editButtonWording="變更取件地址"
          />
        ) : (
          <div style={{padding: desktop ? '0 0' : '0 20px'}}>
            <Row
              style={{
                marginBottom: !desktop ? 0 : 24,
                flexDirection: desktop ? 'row' : 'column',
              }}>
              <TextInput
                label="收件人姓名"
                value={addressValues.receiverName}
                error={addressErrors.receiverName}
                onChange={onAddressChange('receiverName')}
                style={{width: '100%', marginBottom: !desktop ? 24 : 0}}
              />

              {desktop && <div style={{width: 12}} />}

              <TextInput
                label="收件人手機"
                maxLength={10}
                value={addressValues.receiverPhone}
                error={addressErrors.receiverPhone}
                onChange={onAddressChange('receiverPhone')}
                style={{width: '100%', marginBottom: !desktop ? 24 : 0}}
              />
            </Row>

            <Row
              style={{
                marginBottom: !desktop ? 0 : 24,
                flexDirection: desktop ? 'row' : 'column',
              }}>
              <Select
                label="縣市"
                options={countyOptions}
                value={addressValues.city}
                error={addressErrors.city}
                onChange={onAddressChange('city')}
                style={{width: '100%', marginBottom: !desktop ? 24 : 0}}
              />

              {desktop && <div style={{width: 12}} />}

              <Select
                disabled={addressValues.city === ''}
                label="鄉鎮"
                options={townOptions}
                value={addressValues.district}
                error={addressErrors.district}
                onChange={onAddressChange('district')}
                style={{width: '100%', marginBottom: !desktop ? 24 : 0}}
              />
            </Row>

            <TextInput
              label="地址"
              value={addressValues.detail}
              error={addressErrors.detail}
              onChange={onAddressChange('detail')}
              style={{width: '100%', marginBottom: !desktop ? 24 : 0}}
            />
          </div>
        )}
      </section>

      <section className="return-invoice">
        <h1>3. 退款明細</h1>
        <ReturnInvoice returnDetail={returnDetail} isDetailPage={false} />
      </section>

      <section className="return-action">
        <FlexCol>
          <Block warning>
            辦理退貨，請還原包裝/紙箱，切勿併箱，避免因爭而無法順利退款。
            宅配公司會在{'\n'}申請後 2~3
            個工作天內與您電話聯絡，並至取件地址跟您收取 退貨包裹。
          </Block>
          <FlexCol style={{padding: '4px 20px', margin: '20px 0 60px 0'}}>
            <strong>注意事項</strong>
            <ul style={{color: Color.mainDark_70}}>
              <li>
                若有使用購物金會一併返還，若因本筆訂單贈送購物金或達成會員續晉卡，也會一併取消。
              </li>
              <li>
                若您申請之退貨原因與實際商品狀況不符，TUN保留變更及核准退貨金額之權利。
              </li>
            </ul>
          </FlexCol>
          <RectButton
            onClick={onSubmit}
            isDisabled={!returnDetail || shouldValidateItemIds.length <= 0}
            text="申請退貨"
            skin={BUTTON_SKIN.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            style={{width: 200, alignSelf: 'center', marginBottom: 44}}
          />
        </FlexCol>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${props =>
    !props.desktop ? Color.mainDark_10 : Color.mainWhite};
  & > section {
    padding: ${props => (!props.desktop ? '0 0' : '0 70px')};
    margin: 20px 0;
    background-color: white;

    & > h1 {
      padding: ${props => (!props.desktop ? '20px' : '0')};
      margin-bottom: 20px;
    }
  }

  & .filler {
    flex: 1;
  }

  & > section.return-items {
  }
  & > section.return-action {
    padding: ${props => (!props.desktop ? '20px' : '0 70px')};
  }
`;

export default Return;
