import React, {useState} from 'react';
import styled from 'styled-components';
import {Box, Pad, Sep, Txt} from './BoxUI';
import * as SvgIcon from '../../Components/SvgIcon';
import Gold from '../../images/icon-member-gold.svg';
import Silver from '../../images/icon-member-silver.svg';
import Diamond from '../../images/icon-member-diamond.svg';
import {FlexRow} from '../../Components/Widget';
import {MEMBERSHIP_LEVEL} from '../../Domain/Member';

const StyledLevelSelector = styled.div`
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid rgba(20, 20, 20, 0.3);
  display: flex;

  & button {
    background: transparent;
    border: none;
    outline: none;
    width: 30%;
    height: 46px;
    transform: translateY(2px);
  }

  & .active {
    border-bottom: 2px solid #141414;
  }
`;

const Selector = props => {
  const {setActiveVal, activeVal, buttons} = props;
  return (
    <StyledLevelSelector className="level-selector">
      {buttons.map((btn, idx) => {
        return (
          <button
            key={idx}
            className={activeVal === btn.value ? 'active' : ''}
            onClick={() => {
              setActiveVal(btn.value);
            }}>
            {btn.display}
          </button>
        );
      })}
    </StyledLevelSelector>
  );
};

function CreditRules(props) {
  const {goBack, isMobile} = props;
  const [level, setLevel] = useState(MEMBERSHIP_LEVEL.D);

  const mobileHeader = (
    <div className="header">
      <div className="cell" />
      {level === MEMBERSHIP_LEVEL.F && (
        <div className="cell">
          <FlexRow>
            <img src={Silver} alt="" />
            <Txt.M>銀卡</Txt.M>
          </FlexRow>
        </div>
      )}
      {level === MEMBERSHIP_LEVEL.G && (
        <div className="cell">
          <FlexRow>
            <img src={Gold} alt="" />
            <Txt.M>金卡</Txt.M>
          </FlexRow>
        </div>
      )}
      {level === MEMBERSHIP_LEVEL.D && (
        <div className="cell">
          <FlexRow>
            <img src={Diamond} alt="" />
            <Txt.M>鑽卡</Txt.M>
          </FlexRow>
        </div>
      )}
    </div>
  );

  const desktop = (
    <Wrapper mobile={isMobile}>
      <FlexRow className="pointer" onClick={goBack}>
        <SvgIcon.ChevronLeft />
        <div>返回</div>
      </FlexRow>
      <Pad.XL />
      <Txt.XXL style={{fontWeight: 'bold'}}>購物金規則</Txt.XXL>
      <Sep.XL>
        <Txt.L>獲得管道</Txt.L>
      </Sep.XL>

      <Txt.M>
        <b>1. 消費回饋</b>
      </Txt.M>
      <Sep.M />
      <Txt.M style={{color: 'rgba(20, 20, 20, 0.7)'}}>
        依照 <span style={{fontWeight: 'bold'}}>會員等級</span>{' '}
        產生不同比例的回饋，訂單完成七天後將匯入會員帳號
      </Txt.M>

      <div className="table">
        <div className="header">
          <div className="cell" />
          <div className="cell">
            <FlexRow>
              <img src={Silver} alt="" />
              <Txt.M>銀卡</Txt.M>
            </FlexRow>
          </div>
          <div className="cell">
            <FlexRow>
              <img src={Gold} alt="" />
              <Txt.M>金卡</Txt.M>
            </FlexRow>
          </div>
          <div className="cell">
            <FlexRow>
              <img src={Diamond} alt="" />
              <Txt.M>鑽卡</Txt.M>
            </FlexRow>
          </div>
        </div>

        <div className="row">
          <Txt.M className="cell">消費累計回饋金</Txt.M>
          <Txt.M className="cell">
            <Txt.M>消費回饋</Txt.M>
            <Txt.M className="price">1%</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>消費回饋</Txt.M>
            <Txt.M className="price">2%</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>消費回饋</Txt.M>
            <Txt.M className="price">4%</Txt.M>
          </Txt.M>
        </div>
      </div>

      <Pad.XL />

      <Txt.M>
        <b>2. 升等獎勵、續卡獎勵</b>
      </Txt.M>
      <Sep.M />
      <Txt.M style={{color: 'rgba(20, 20, 20, 0.7)'}}>
        在成功升等 / 成功續卡後匯入會員帳號
      </Txt.M>

      <div className="table">
        <div className="header">
          <div className="cell" />
          <div className="cell">
            <FlexRow>
              <img src={Silver} alt="" />
              <Txt.M>銀卡</Txt.M>
            </FlexRow>
          </div>
          <div className="cell">
            <FlexRow>
              <img src={Gold} alt="" />
              <Txt.M>金卡</Txt.M>
            </FlexRow>
          </div>
          <div className="cell">
            <FlexRow>
              <img src={Diamond} alt="" />
              <Txt.M>鑽卡</Txt.M>
            </FlexRow>
          </div>
        </div>

        <div className="row">
          <Txt.M className="cell">「單筆消費滿額」升等</Txt.M>
          <Txt.M className="cell">
            <Txt.M>單筆消費滿</Txt.M>
            <Txt.M className="price">NT$ 20,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>單筆消費滿</Txt.M>
            <Txt.M className="price">NT$ 40,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>單筆消費滿</Txt.M>
            <Txt.M className="price">NT$ 120,000</Txt.M>
          </Txt.M>
        </div>

        <div className="row">
          <Txt.M className="cell">「消費累計滿額」升等</Txt.M>
          <Txt.M className="cell">
            <Txt.M>一年內消費滿</Txt.M>
            <Txt.M className="price">NT$ 30,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>一年內消費滿</Txt.M>
            <Txt.M className="price">NT$ 60,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>一年內消費滿</Txt.M>
            <Txt.M className="price">NT$ 150,000</Txt.M>
          </Txt.M>
        </div>

        <div className="row">
          <Txt.M className="cell">
            <Txt.M>升等獎勵</Txt.M>
            <Txt.M>*限單筆消費滿額獲得</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>-</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>升等禮金</Txt.M>
            <Txt.M className="price">NT$ 2,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>一般/銀卡會員升等禮金</Txt.M>
            <Txt.M className="price">NT$ 12,000</Txt.M>
            <Txt.M>金卡會員升等禮金</Txt.M>
            <Txt.M className="price">NT$ 6,000</Txt.M>
          </Txt.M>
        </div>

        <div className="row">
          <Txt.M className="cell">續卡獎勵</Txt.M>
          <Txt.M className="cell">
            <Txt.M>續卡獎勵</Txt.M>
            <Txt.M className="price">NT$ 1,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>續卡獎勵</Txt.M>
            <Txt.M className="price">NT$ 2,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>續卡獎勵</Txt.M>
            <Txt.M className="price">NT$ 4,000</Txt.M>
          </Txt.M>
        </div>
      </div>

      <Txt.S className="info-text">
        ・一般會員只能藉由單筆消費滿額的方式升級
      </Txt.S>
      <Txt.S className="info-text">・累計消費為消費金額折扣後之累計</Txt.S>

      <Pad.XL />

      <Txt.M>
        <b>3. 生日禮金</b>
      </Txt.M>
      <Sep.M />
      <Txt.M style={{color: 'rgba(20, 20, 20, 0.7)'}}>
        依照 <b style={{color: 'black'}}>會員等級</b>{' '}
        於生日當月贈送生日禮金，將於生日當月第一天匯入會員帳號 在成功升等 /
        成功續卡後匯入會員帳號
      </Txt.M>
      <div className="table">
        <div className="header">
          <div className="cell" />
          <div className="cell">
            <FlexRow>
              <img src={Silver} alt="" />
              <Txt.M>銀卡</Txt.M>
            </FlexRow>
          </div>
          <div className="cell">
            <FlexRow>
              <img src={Gold} alt="" />
              <Txt.M>金卡</Txt.M>
            </FlexRow>
          </div>
          <div className="cell">
            <FlexRow>
              <img src={Diamond} alt="" />
              <Txt.M>鑽卡</Txt.M>
            </FlexRow>
          </div>
        </div>

        <div className="row">
          <Txt.M className="cell">專屬生日禮</Txt.M>
          <Txt.M className="cell">
            <Txt.M>生日禮金</Txt.M>
            <Txt.M className="price">NT$ 500</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>生日禮金</Txt.M>
            <Txt.M className="price">NT$ 1,000</Txt.M>
          </Txt.M>
          <Txt.M className="cell">
            <Txt.M>生日禮金</Txt.M>
            <Txt.M className="price">NT$ 2,000</Txt.M>
          </Txt.M>
        </div>
      </div>

      <Pad.XL />

      <div style={{height: 1, backgroundColor: 'rgba(20, 20, 20, 0.3)'}} />

      <Sep.XL>
        <Txt.L>使用規則</Txt.L>
      </Sep.XL>

      <Txt.S className="info-text">
        ・續晉卡獎勵與消費回饋最低使用面額為 NT$ 1,000，生日禮金最低使用面額為
        NT$ 500
      </Txt.S>
      <Txt.S className="info-text">
        ・各項回饋之使用只能以最低使用面額的倍數，且購買商品總金額不得低於欲抵用之總數，不得找零，也不得溢收
      </Txt.S>
      <Txt.S className="info-text">・所有回饋券皆無折抵上限</Txt.S>

      <Pad.XL />

      <div style={{height: 1, backgroundColor: 'rgba(20, 20, 20, 0.3)'}} />

      <Sep.XL>
        <Txt.L>使用期限</Txt.L>
      </Sep.XL>

      <Txt.S className="info-text">
        ・續晉卡獎勵：系統將續晉卡獎勵匯入會員帳號後，起算一年內需使用完畢
      </Txt.S>
      <Txt.S className="info-text">
        ・消費回饋：系統將消費回饋匯入會員帳號後，匯入日起算至該年 12/31
      </Txt.S>
      <Txt.S className="info-text">
        ・生日禮金：系統將生日禮金匯入會員帳號後，匯入當月需使用完畢
      </Txt.S>
      <Txt.S className="info-text">
        ・敬請注意：經折抵使用之回饋金，將在退貨退款完成後返回，且將有三天使用期限
      </Txt.S>
    </Wrapper>
  );

  const mobile = (
    <div>
      <Selector
        activeVal={level}
        buttons={[
          {
            value: MEMBERSHIP_LEVEL.F,
            display: '銀卡',
          },
          {
            value: MEMBERSHIP_LEVEL.G,
            display: '金卡',
          },
          {
            value: MEMBERSHIP_LEVEL.D,
            display: '鑽卡',
          },
        ]}
        setActiveVal={setLevel}
      />

      <Wrapper mobile={isMobile}>
        <FlexRow className="pointer" onClick={goBack}>
          <SvgIcon.ChevronLeft />
          <div>返回</div>
        </FlexRow>
        <Pad.XL />
        <Txt.XXL>購物金規則</Txt.XXL>
        <Sep.XL>
          <Txt.L>獲得管道</Txt.L>
        </Sep.XL>

        <Txt.M>
          <b>1. 消費回饋</b>
        </Txt.M>
        <Sep.M />
        <Txt.M style={{color: 'rgba(20, 20, 20, 0.7)'}}>
          依照 <span style={{fontWeight: 'bold'}}>會員等級</span>{' '}
          產生不同比例的回饋，訂單完成七天後將匯入會員帳號
        </Txt.M>

        <div className="table">
          {mobileHeader}

          <div className="row">
            <Txt.M className="cell">消費累計回饋金</Txt.M>
            {level === MEMBERSHIP_LEVEL.F && (
              <Txt.M className="cell">
                <Txt.M>消費回饋</Txt.M>
                <Txt.M className="price">1%</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.G && (
              <Txt.M className="cell">
                <Txt.M>消費回饋</Txt.M>
                <Txt.M className="price"> 2%</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.D && (
              <Txt.M className="cell">
                <Txt.M>消費回饋</Txt.M>
                <Txt.M className="price">4%</Txt.M>
              </Txt.M>
            )}
          </div>
        </div>

        <Pad.XL />

        <Txt.M>
          <b>2. 升等獎勵、續卡獎勵</b>
        </Txt.M>
        <Sep.M />
        <Txt.M style={{color: 'rgba(20, 20, 20, 0.7)'}}>
          在成功升等 / 成功續卡後匯入會員帳號
        </Txt.M>

        <div className="table">
          {mobileHeader}

          <div className="row">
            <Txt.M className="cell">「單筆消費滿額」升等</Txt.M>
            {level === MEMBERSHIP_LEVEL.F && (
              <Txt.M className="cell">
                <Txt.M>單筆消費滿</Txt.M>
                <Txt.M className="price">NT$ 20,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.G && (
              <Txt.M className="cell">
                <Txt.M>單筆消費滿</Txt.M>
                <Txt.M className="price">NT$ 40,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.D && (
              <Txt.M className="cell">
                <Txt.M>單筆消費滿</Txt.M>
                <Txt.M className="price">NT$ 120,000</Txt.M>
              </Txt.M>
            )}
          </div>

          <div className="row">
            <Txt.M className="cell">「消費累計滿額」升等</Txt.M>
            {level === MEMBERSHIP_LEVEL.F && (
              <Txt.M className="cell">
                <Txt.M>一年內消費滿</Txt.M>
                <Txt.M className="price">NT$ 30,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.G && (
              <Txt.M className="cell">
                <Txt.M>一年內消費滿</Txt.M>
                <Txt.M className="price">NT$ 60,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.D && (
              <Txt.M className="cell">
                <Txt.M>一年內消費滿</Txt.M>
                <Txt.M className="price">NT$ 150,000</Txt.M>
              </Txt.M>
            )}
          </div>

          <div className="row">
            <Txt.M className="cell">
              <Txt.M>升等獎勵</Txt.M>
              <Txt.M>*限單筆消費滿額獲得</Txt.M>
            </Txt.M>
            {level === MEMBERSHIP_LEVEL.F && (
              <Txt.M className="cell">
                <Txt.M>-</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.G && (
              <Txt.M className="cell">
                <Txt.M>升等禮金</Txt.M>
                <Txt.M className="price">NT$ 4,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.D && (
              <Txt.M className="cell">
                <Txt.M>一般/銀卡會員升等禮金</Txt.M>
                <Txt.M className="price">NT$ 24,000</Txt.M>
                <Txt.M>金卡會員升等禮金</Txt.M>
                <Txt.M className="price">NT$ 12,000</Txt.M>
              </Txt.M>
            )}
          </div>

          <div className="row">
            <Txt.M className="cell">續卡獎勵</Txt.M>
            {level === MEMBERSHIP_LEVEL.F && (
              <Txt.M className="cell">
                <Txt.M>續卡獎勵</Txt.M>
                <Txt.M className="price">NT$ 1,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.G && (
              <Txt.M className="cell">
                <Txt.M>續卡獎勵</Txt.M>
                <Txt.M className="price">NT$ 2,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.D && (
              <Txt.M className="cell">
                <Txt.M>續卡獎勵</Txt.M>
                <Txt.M className="price">NT$ 4,000</Txt.M>
              </Txt.M>
            )}
          </div>
        </div>

        <Txt.S className="info-text">
          ・一般會員只能藉由單筆消費滿額的方式升級
        </Txt.S>
        <Txt.S className="info-text">・累計消費為消費金額折扣後之累計</Txt.S>

        <Pad.XL />

        <Txt.M>
          <b>3. 生日禮金</b>
        </Txt.M>
        <Sep.M />
        <Txt.M style={{color: 'rgba(20, 20, 20, 0.7)'}}>
          依照 <b style={{color: 'black'}}>會員等級</b>{' '}
          於生日當月贈送生日禮金，將於生日當月第一天匯入會員帳號 在成功升等 /
          成功續卡後匯入會員帳號
        </Txt.M>
        <div className="table">
          {mobileHeader}
          <div className="row">
            <Txt.M className="cell">專屬生日禮</Txt.M>
            {level === MEMBERSHIP_LEVEL.F && (
              <Txt.M className="cell">
                <Txt.M>生日禮金</Txt.M>
                <Txt.M className="price">NT$ 500</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.G && (
              <Txt.M className="cell">
                <Txt.M>生日禮金</Txt.M>
                <Txt.M className="price">NT$ 1,000</Txt.M>
              </Txt.M>
            )}
            {level === MEMBERSHIP_LEVEL.D && (
              <Txt.M className="cell">
                <Txt.M>生日禮金</Txt.M>
                <Txt.M className="price">NT$ 2,000</Txt.M>
              </Txt.M>
            )}
          </div>
        </div>

        <Pad.XL />

        <div style={{height: 1, backgroundColor: 'rgba(20, 20, 20, 0.3)'}} />

        <Sep.XL>
          <Txt.L>使用規則</Txt.L>
        </Sep.XL>

        <Txt.S className="info-text">
          ・續晉卡獎勵與消費回饋最低使用面額為 NT$ 1,000，生日禮金最低使用面額為
          NT$ 500
        </Txt.S>
        <Txt.S className="info-text">
          ・各項回饋之使用只能以最低使用面額的倍數，且購買商品總金額不得低於欲抵用之總數，不得找零，也不得溢收
        </Txt.S>
        <Txt.S className="info-text">・所有回饋券皆無折抵上限</Txt.S>

        <Pad.XL />

        <div style={{height: 1, backgroundColor: 'rgba(20, 20, 20, 0.3)'}} />

        <Sep.XL>
          <Txt.L>使用期限</Txt.L>
        </Sep.XL>

        <Txt.S className="info-text">
          ・續晉卡獎勵：系統將續晉卡獎勵匯入會員帳號後，起算一年內需使用完畢
        </Txt.S>
        <Txt.S className="info-text">
          ・消費回饋：系統將消費回饋匯入會員帳號後，匯入日起算至該年 12/31
        </Txt.S>
        <Txt.S className="info-text">
          ・生日禮金：系統將生日禮金匯入會員帳號後，匯入當月需使用完畢
        </Txt.S>
        <Txt.S className="info-text">
          ・敬請注意：經折抵使用之回饋金，將在退貨退款完成後返回，且將有三天使用期限
        </Txt.S>
      </Wrapper>
    </div>
  );
  return isMobile ? mobile : desktop;
}

const Wrapper = styled(Box.XXL)`
  & .info-text {
    color: rgba(20, 20, 20, 0.7);
    margin-bottom: 5px;
  }
  & .pointer {
    :hover {
      cursor: pointer;
    }
  }

  & > .table {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: auto;
    border: 1px solid rgba(20, 20, 20, 0.3);
    border-bottom: none;

    & > .header {
      display: flex;
      flex: 1;

      & > .cell {
        flex: 1;
        width: ${({mobile}) => (mobile ? '140px' : '250px')};
        padding: 12px;
        border-bottom: 1px solid rgba(20, 20, 20, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:first-child {
          text-align: left;
          background-color: rgba(20, 20, 20, 0.1);
        }
      }
    }

    & > .row {
      display: flex;
      flex: 1;

      & > .cell {
        flex: 1;
        width: ${({mobile}) => (mobile ? '140px' : '250px')};
        padding: 12px;
        border-bottom: 1px solid rgba(20, 20, 20, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: rgba(20, 20, 20, 0.5);
        & > .price {
          font-weight: 500;
          color: rgba(20, 20, 20, 1);
        }
        &:first-child {
          align-items: flex-start;
          background-color: rgba(20, 20, 20, 0.1);
        }
      }
    }
  }
`;

export default CreditRules;
