import React from 'react';
import styled from 'styled-components';
import {CREDIT_CARD_TYPE_PIC} from '../../../Domain/Order';
import creditCardType from 'credit-card-type';
import {Actions} from '../../../Contexts/AppContext';
import * as ModalContent from './ModalContent.card';

function CardItem(props) {
  const {card, mobile, overlayRef, modalRef, fetchCards} = props;
  const anchorRef = React.useRef();

  const setPrimaryCard = async () => {
    await Actions.setDefaultCard(card.id);
    fetchCards();
  };

  const openConfirmRemoveModal = id => {
    modalRef.current.open(
      <ModalContent.RemoveCardModalContent
        mobile={mobile}
        onConfirm={async () => {
          await Actions.deleteCard(id);
          await fetchCards();
        }}
        onClose={() => modalRef.current.close()}
      />,
    );
  };

  function openMobileOverlayMenu(id, isPrimary) {
    overlayRef.current.open({
      domElem: anchorRef.current,
      content: (
        <OverlayMenu>
          {!isPrimary && (
            <button
              onClick={() => {
                setPrimaryCard();
                overlayRef.current.close();
              }}>
              設為預設信用卡
            </button>
          )}
          <button
            onClick={() => {
              openConfirmRemoveModal(id);
              overlayRef.current.close();
            }}>
            刪除
          </button>
        </OverlayMenu>
      ),
    });
  }

  return (
    <CardItemWrapper>
      {mobile && card.is_primary && (
        <div className="primary-mobile">預設信用卡</div>
      )}

      <div className="row">
        {CREDIT_CARD_TYPE_PIC[creditCardType(card.first_six)[0].type]}
        <div className="uid">{'*' + card.last_four}</div>
        <div style={{flex: 1}} />
        {mobile ? (
          <>
            <button
              ref={anchorRef}
              onClick={() => {
                openMobileOverlayMenu(card.id, card.is_primary);
              }}>
              編輯
            </button>
          </>
        ) : (
          <>
            {card.is_primary && <div className="primary">預設信用卡</div>}
            <button onClick={setPrimaryCard} disabled={card.is_primary}>
              設為預設
            </button>
            <div
              style={{
                height: 30,
                backgroundColor: 'rgba(20, 20, 20, 0.3)',
                width: 1,
              }}
            />
            <button
              onClick={() => {
                openConfirmRemoveModal(card.id);
              }}>
              刪除
            </button>
          </>
        )}
      </div>
    </CardItemWrapper>
  );
}

const CardItemWrapper = styled.div`
  border: solid 1px rgba(20, 20, 20, 0.1);
  border-radius: 2px;
  background-color: white;
  color: #141414;
  margin-bottom: 16px;

  & button {
    cursor: pointer;
  }

  & > .primary-mobile {
    background-color: rgba(20, 20, 20, 0.06);
    padding: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
  }

  & > .row {
    padding: 20px;
    display: flex;
    align-items: center;
    & > img {
      height: 32px;
      width: 32px;
      background-color: #eee;
      object-fit: contain;
      margin-right: 12px;
    }

    & > .card {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      margin-right: 4px;
    }

    & > .uid {
      font-size: 16px;
      line-height: 1.5;
      color: rgba(20, 20, 20, 0.7);
    }

    & > .primary {
      margin-right: 40px;
      padding: 6px 8px;
      border-radius: 2px;
      background-color: rgba(20, 20, 20, 0.06);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
    }

    & > button {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 6px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      letter-spacing: normal;
      color: #141414;
      text-decoration: underline;
    }

    & > button:disabled {
      color: rgba(20, 20, 20, 0.3);
    }
  }
`;

const OverlayMenu = styled.div`
  padding: 20px;
  min-width: 100px;
  background-color: white;
  border-radius: 2px;

  & > button {
    outline: none;
    border: none;
    background-color: transparent;
    white-space: nowrap;
    margin-bottom: 15px;
  }

  & > button:last-child {
    margin-bottom: 0px;
  }
`;

export default CardItem;
