import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import {ChevronLeft, Cross} from '../../Components/SvgIcon';
import useCountDownTimer from '../../Hooks/useCountDownTimer';
import {Actions, Context} from '../../Contexts/AppContext';
import {
  Color,
  FlexColCenter,
  FlexRow,
  FlexRowCenter,
  FontSize,
  UnderlineButton,
} from '../../Components/Widget';
import {
  getPasswordValidator,
  getPhoneValidator,
} from '../../Utils/validateUtil';
import PhoneUpdate from '../../images/illustration-phone-update.svg';

export function AskPasswordModalContent(props) {
  const {onClose, onConfirm, mobile} = props;
  const [password, setPassword] = React.useState('');

  const [error, setError] = useState(null);

  const onValidate = e => {
    setError(getPasswordValidator().passRequest(e.target.value));
  };

  const onValidatePassword = async password => {
    const errorMsg = getPasswordValidator().passRequest(password);
    setError(errorMsg);

    if (errorMsg) {
      return;
    }

    const isPass = await Actions.validatePassword(password);

    if (!isPass) {
      setError('密碼錯誤，請重新嘗試');
    } else {
      onConfirm();
    }
  };

  return (
    <ModalContentWrapper mobile={mobile}>
      <div style={{display: 'flex'}}>
        <div style={{flex: 1}} />
        <div onClick={onClose}>
          <Cross style={{cursor: 'pointer'}} />
        </div>
      </div>

      <h2>變更手機號碼</h2>
      <p>為了帳號安全，請輸入密碼驗證身分</p>

      <TextInput
        label="密碼 (8 ~ 12 位英數字組合)"
        type="password"
        value={password}
        onBlur={onValidate}
        error={error}
        onChange={e => setPassword(e.target.value)}
        style={{marginBottom: 24}}
      />

      <RectButton
        text="確定"
        skin={BUTTON_SKIN.PRIMARY}
        onClick={() => {
          onValidatePassword(password);
        }}
        style={{width: '100%'}}
      />
    </ModalContentWrapper>
  );
}

export function UpdatePhoneModalContent(props) {
  const {onBack, onClose, onConfirm, mobile} = props;
  const [phone, setPhone] = React.useState('');
  const [error, setError] = useState(null);

  const onValidate = e => {
    setError(getPhoneValidator().passRequest(e.target.value));
  };

  const onSubmit = async () => {
    const errorMsg = getPhoneValidator().passRequest(phone);
    setError(errorMsg);
    if (errorMsg) {
      return;
    }
    const isCustomerExist = await Actions.getCustomerExist(phone);
    if (isCustomerExist) {
      setError('此手機號碼已註冊');
      return;
    }
    onConfirm(phone);
  };

  return (
    <ModalContentWrapper mobile={mobile}>
      <div style={{display: 'flex'}}>
        <div onClick={onBack}>
          <ChevronLeft />
        </div>
        <div style={{flex: 1}} />
        <div onClick={onClose}>
          <Cross style={{cursor: 'pointer'}} />
        </div>
      </div>

      <h2>輸入新手機號碼</h2>
      <p>請輸入您的手機，我們會寄送簡訊驗證碼</p>

      <TextInput
        label="新手機號碼"
        maxLength={10}
        error={error}
        onBlur={onValidate}
        type="phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        style={{marginBottom: 24}}
      />

      <RectButton
        text="發送簡訊"
        skin={BUTTON_SKIN.PRIMARY}
        onClick={onSubmit}
        style={{width: '100%'}}
      />
    </ModalContentWrapper>
  );
}

export function OtpModalContent(props) {
  const {onBack, onClose, onConfirm, mobile, phone} = props;
  const app = useContext(Context);
  const {currentUser} = app;

  const updateCustomer = async customer => {
    return await Actions.updateCustomer({
      ...customer,
      phone: phone,
    });
  };

  const maximumErrorCounts = 5;
  const [seconds, setSeconds] = useCountDownTimer(() => {
    setIsRegenOtpEnable(true);
  });
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [errorCounts, setErrorCounts] = useState(0);
  const [isRegenOtpEnable, setIsRegenOtpEnable] = useState(false);

  const getEncryptPhone = useCallback(phone => {
    const sliceOne = phone.slice(0, 4);
    const sliceTwo = phone.slice(7, 10);
    return sliceOne + ' *** ' + sliceTwo;
  }, []);

  const genOtp = useCallback(async () => {
    await Actions.genOtp(phone);
  }, [phone]);

  const autoSubmit = async target => {
    try {
      Actions.setLoading(true);
      const {value} = target;
      const response = await Actions.validateOtp({phone: phone, otp: value});
      const {success} = response;
      const errorMsg = success ? null : '驗證碼不正確';
      setError(errorMsg);
      if (errorMsg) {
        setErrorCounts(errorCounts + 1);
        return;
      }
      target.blur();

      const result = await updateCustomer({
        gender: currentUser.gender,
        birthdate: currentUser.birthdate,
        email: currentUser.email,
        name: currentUser.name,
        phone: phone,
      });

      await Actions.syncPos();

      if (result.success) {
        onConfirm();
      } else {
        alert(result.message[0] ?? '');
      }
    } catch (e) {
    } finally {
      Actions.setLoading(false);
    }
  };

  const onValueChange = e => {
    const {value} = e.target;
    setOtp(value);
    if (value.length === 6) {
      autoSubmit(e.target);
    }
  };

  useEffect(() => {
    genOtp();
  }, [genOtp]);

  const handleRegenIdentifyCodeClick = () => {
    genOtp();
    setIsRegenOtpEnable(false);
    setSeconds(60);
    setErrorCounts(0);
  };

  return (
    <ModalContentWrapper mobile={mobile}>
      <div style={{display: 'flex'}}>
        <div onClick={onBack}>
          <ChevronLeft />
        </div>
        <div style={{flex: 1}} />
        <div onClick={onClose}>
          <Cross style={{cursor: 'pointer'}} />
        </div>
      </div>

      <h2>請輸入簡訊驗證碼</h2>
      <p>驗證碼已發送至 {getEncryptPhone(phone)}</p>

      <TextInput
        label="6 位數字驗證碼"
        maxLength={6}
        type="numeric"
        value={otp}
        error={error}
        onChange={onValueChange}
        style={{marginBottom: 24}}
      />

      <FlexRowCenter style={{marginTop: '20px'}}>
        {isRegenOtpEnable ? (
          <div
            onClick={handleRegenIdentifyCodeClick}
            style={{
              textDecoration: 'underline',
              color:
                errorCounts < maximumErrorCounts ? Color.mainDark : '#e22828',
            }}>
            請點此重新發送驗證碼
          </div>
        ) : (
          <div
            style={{
              textDecoration: 'underline',
              color:
                errorCounts < maximumErrorCounts ? Color.mainDark : '#e22828',
            }}>
            {errorCounts < maximumErrorCounts
              ? `重新發送驗證碼 (${seconds}s)`
              : `錯誤次數過多，請稍後再重新發送驗證碼 (${seconds}s)`}
          </div>
        )}
      </FlexRowCenter>
    </ModalContentWrapper>
  );
}

export function SuccessModalContent(props) {
  const {onConfirm, mobile} = props;

  return (
    <ModalContentWrapper mobile={mobile}>
      <FlexColCenter>
        <img src={PhoneUpdate} alt="update phone icon" />
      </FlexColCenter>

      <h2>手機號碼已更新</h2>
      <p>之後請使用新手機登入</p>
      <RectButton
        text="確定"
        skin={BUTTON_SKIN.PRIMARY}
        onClick={onConfirm}
        style={{width: '100%'}}
      />
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div`
  padding: 20px;
  background-color: white;
  position: relative;
  width: ${props => (props.mobile ? 'unset' : '420px')};

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
  }

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #ccc;
    margin-bottom: 32px;
  }
`;
