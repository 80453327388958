import styled from 'styled-components';
import {
  Color,
  FlexCol,
  FlexRow,
  FlexRowCenter,
} from '../../../../Components/Widget';
import {Info} from '../../../../Components/SvgIcon';
import {ReturnItem} from './ReturnItem';
import * as L from '../../../../Utils/Lang';
import React from 'react';
import {Tooltip} from 'antd';

export const ReturnItems = props => {
  const {
    header,
    returnList,
    counterOperatorFuncs,
    onCheckReturnItem,
    onReturnItemValueChange,
    onReturnItemValidate,
    errors,
    isEnableEdit = true,
    style = {},
  } = props;

  return (
    <Wrapper style={style} className="items-of-brand">
      <FlexRow className="header">
        <div className="brand-name">{header.brand_name}</div>
        <FlexRow className="assign-store">
          <strong>指定服務門市</strong>
          <Tooltip
            placement="bottom"
            title="若您需要維修、保養、線上諮詢...等協助，將由此門市替您服務。">
            <Info />
          </Tooltip>
          <small> : {L.d(header.store_name)}</small>
        </FlexRow>
      </FlexRow>
      {isEnableEdit
        ? returnList.map((item, index) => (
            <ReturnItem
              isEnableEdit={isEnableEdit}
              item={item}
              key={index}
              onCheckReturnItem={onCheckReturnItem}
              onReturnItemValueChange={onReturnItemValueChange}
              onReturnItemValidate={onReturnItemValidate}
              counterOperatorFuncs={counterOperatorFuncs}
              error={errors.find(error => error.id === item.id)}
            />
          ))
        : returnList.map((item, index) => (
            <ReturnItem
              isEnableEdit={isEnableEdit}
              item={item}
              key={index}
              onCheckReturnItem={onCheckReturnItem}
              onReturnItemValueChange={onReturnItemValueChange}
              onReturnItemValidate={onReturnItemValidate}
              counterOperatorFuncs={counterOperatorFuncs}
            />
          ))}
    </Wrapper>
  );
};

const Wrapper = styled(FlexCol)`
  border: 1px solid ${Color.mainDark_10};
  & > .header {
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 20px;
    background-color: ${Color.mainDark_03};
    & > .brand-name {
      font-size: 20px;
    }
    & > .assign-store {
      align-items: center;
      & > div {
        margin: 0 3px;
      }
      & > strong {
        font-size: 14px;
        font-weight: 500;
      }
      & > small {
        font-size: 14px;
        font-weight: 300;
        color: ${Color.mainDark_70};
      }
    }
  }
`;
