import React, {Fragment} from 'react';
import styled from 'styled-components';
import OrderShipping from './OrderShipping';
import {
  Color,
  FlexCol,
  FlexRow,
  UnderlineButton,
} from '../../../Components/Widget';
import {getFormatPrice} from '../../../Utils/ProductUtil';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import * as L from '../../../Utils/Lang';
import {
  CANCEL_REASON,
  ORDER_STATUS,
  ORDER_STATUS_DISPLAY,
} from '../../../Domain/Order';
import {NavActions} from '../../../Contexts/AppContext';
import {brandsDisplayArr} from '../../../Domain/Brand';
import EmptyImage from '../../../images/emptyimage.png';

function OrderItem(props) {
  const {mobile, selectedTab, style = {}, order} = props;
  return (
    <Wrapper mobile={mobile} style={style}>
      <header>
        <span style={{flex: 1}}>訂單編號 {order.order_no}</span>

        <FlexCol>
          {order.is_offline_order ? (
            <div
              style={{
                fontWeight: 'bold',
              }}>
              門市消費
            </div>
          ) : (
            <Fragment>
              <FlexRow>
                <div
                  style={{
                    color:
                      order.status === ORDER_STATUS.unpaid
                        ? Color.red
                        : Color.gold,
                    fontWeight: 'bold',
                  }}>
                  {L.d(ORDER_STATUS_DISPLAY[order.status])}
                </div>
                {order.status === ORDER_STATUS.unpaid && (
                  <FlexRow>
                    <div style={{color: Color.red}}>，</div>
                    <UnderlineButton
                      onClick={() => {
                        NavActions.navigate(
                          `/profile?active=orders&orderId=${order.id}&action=re_checkout`,
                        );
                      }}
                      style={{
                        color: Color.red,
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}>
                      重新付款
                    </UnderlineButton>
                  </FlexRow>
                )}
              </FlexRow>
              {order.status === ORDER_STATUS.unpaid && (
                <div style={{color: Color.mainDark_70}}>
                  {L.d(order.payment_failed_reason)}
                </div>
              )}
              {order.status === ORDER_STATUS.canceled && (
                <div style={{color: Color.mainDark_70}}>
                  {L.d(CANCEL_REASON[order.cancel_reason])}
                </div>
              )}
            </Fragment>
          )}
        </FlexCol>
      </header>

      <div className="brands">
        {order.sub_orders.map((subOrder, idx) => (
          <Brand order={order} mobile={mobile} subOrder={subOrder} key={idx} />
        ))}
      </div>

      <FlexRow
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: mobile ? '10px 16px' : '10px 32px',
        }}>
        <FlexRow>
          <h4>總金額</h4>
          <h2 style={{marginLeft: '8px', fontWeight: 'bold'}}>
            NT {getFormatPrice(order.price)}
          </h2>
        </FlexRow>
        <RectButton
          text="查看訂單細節"
          skin={BUTTON_SKIN.DEFAULT}
          onClick={() => {
            NavActions.navigate(`/profile?active=orders&orderId=${order.id}`);
          }}
        />
      </FlexRow>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid ${Color.mainDark_10};
  background-color: white;

  & > header {
    background-color: rgba(20, 20, 20, 0.03);
    padding: ${props => (props.mobile ? '8px 16px' : '15px 32px')};
    display: flex;
    align-items: center;
  }

  & > .brands {
    padding: ${props =>
      props.mobile ? '0 20px 20px 16px' : '0 20px 20px 32px'};
  }
`;

function Brand(props) {
  const {mobile, subOrder, order} = props;

  return (
    <BrandWrapper mobile={mobile}>
      {!order.is_offline_order && (
        <div className="brand-name">
          {brandsDisplayArr.find(b => b.key === subOrder.brand_name).display}
        </div>
      )}
      <summary>
        <div className="image-grid">
          {subOrder.items.map((item, idx) => {
            return item.variant.images.length > 0 ? (
              <img
                src={item.variant.images[0]}
                key={idx}
                alt="how the product looks"
              />
            ) : (
              <img
                src={EmptyImage}
                style={{background: '#ccc'}}
                key={idx}
                alt=""
              />
            );
          })}
        </div>

        <div className="sep" />

        {!order.is_offline_order && (
          <div className="shipping-info">
            <OrderShipping tooltip order={order} subOrder={subOrder} />
          </div>
        )}
      </summary>
    </BrandWrapper>
  );
}

const BrandWrapper = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${Color.mainDark_10};

  & > .brand-name {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  & > summary {
    display: flex;
    flex-direction: ${props => (props.mobile ? 'column-reverse' : 'row')};

    & > .image-grid {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      & > img {
        width: 72px;
        height: 90px;
        margin: 0 10px 10px 0;
        object-fit: cover;
        background-color: #ccc;
      }
    }

    & > .sep {
      flex-basis: 10px;
    }

    & > .shipping-info {
      flex: 1;
      margin: 15px 0;
    }
  }
`;

export default OrderItem;
