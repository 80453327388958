import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Block, Color, UnderlineButton} from '../../../../Components/Widget';
import {useDimension} from '../../../../Hooks/AppHooks';
import Stepper from '../Stepper';
import {ReturnItems} from './ReturnItemList';
import {AddressItem} from './AddressItem';
import {ReturnInvoice} from './ReturnInvoice';
import {InvoiceHandle} from './InvoiceHandle';
import {Actions} from '../../../../Contexts/AppContext';
import {returnReason, returnReasonDisPlay} from './Return';
import * as L from '../../../../Utils/Lang';
import {RETURN_STATUS, RETURN_STATUS_DISPLAY} from '../../../../Domain/Return';
import {SHIPPING_TYPE} from '../../../../Domain/Order';

const ReturnDetail = props => {
  const {returnOrderId} = props;
  const {desktop} = useDimension();
  const [returnOrderDetail, setReturnOrderDetail] = useState(null);

  const fetchReturnOrderDetail = useCallback(async () => {
    try {
      setReturnOrderDetail(await Actions.returnOrderDetail(returnOrderId));
    } catch (e) {
      console.error(e);
      setReturnOrderDetail(null);
    }
  }, [returnOrderId]);

  useEffect(() => {
    fetchReturnOrderDetail();
  }, [fetchReturnOrderDetail]);

  const returnList = useMemo(() => {
    if (!returnOrderDetail) {
      return null;
    }
    return returnOrderDetail.order_items.map(item => ({
      ...item,
      returnReason: Object.values(returnReason).includes(item.return_reason)
        ? L.d(returnReasonDisPlay[item.return_reason])
        : `${L.d(returnReasonDisPlay[returnReason.ELSE])} : ${
            item.return_reason
          }`,
    }));
  }, [returnOrderDetail]);

  const address = useMemo(() => {
    if (!returnOrderDetail) {
      return null;
    }
    return {
      receiverName: returnOrderDetail.receiver_name,
      receiverPhone: returnOrderDetail.receiver_phone,
      city: returnOrderDetail.city,
      district: returnOrderDetail.district,
      detail: returnOrderDetail.address,
      shippingType: SHIPPING_TYPE.home,
      primary: true,
    };
  }, [returnOrderDetail]);

  const isFailed = useMemo(() => {
    if (!returnOrderDetail) {
      return false;
    }
    return returnOrderDetail.status === RETURN_STATUS.return_failed;
  }, [returnOrderDetail]);

  const steps = useMemo(() => {
    return !isFailed
      ? [RETURN_STATUS.applied, RETURN_STATUS.processing, RETURN_STATUS.done]
      : [
          RETURN_STATUS.applied,
          RETURN_STATUS.processing,
          RETURN_STATUS.return_failed,
        ];
  }, [isFailed]);

  const current = useMemo(() => {
    if (!returnOrderDetail) {
      return -1;
    }
    return steps.indexOf(returnOrderDetail.status);
  }, [returnOrderDetail, steps]);

  const returnDetail = useMemo(() => {
    if (!returnOrderDetail) {
      return null;
    }
    return {
      returnAmount: returnOrderDetail['return_applied_amount'],
      returnAppliedCashAmount: returnOrderDetail['return_applied_cash_amount'],
      returnAppliedRebateAmount:
        returnOrderDetail['return_applied_rebate_amount'],
      realAmount: returnOrderDetail['return_actual_amount'],
      returnRebateAmount: returnOrderDetail['return_actual_rebate_amount'],
      returnCashAmount: returnOrderDetail['return_actual_cash_amount'],
    };
  }, [returnOrderDetail]);

  if (!returnOrderDetail) {
    return null;
  }

  return (
    <Wrapper desktop={desktop}>
      <section className="return-info">
        {returnOrderDetail.status === RETURN_STATUS.applied && (
          <Block style={{marginBottom: 40}} warning>
            辦理退貨，請還原包裝/紙箱，切勿併箱，避免因爭而無法順利退款。
            宅配公司會在{'\n'}申請後 2~3
            個工作天內與您電話聯絡，並至取件地址跟您收取 退貨包裹。
          </Block>
        )}
        <h1>1. 退貨資訊</h1>
        <div className="return-number">
          退貨單號 #{returnOrderDetail?.number}
        </div>
        <Stepper
          steps={steps}
          current={current}
          status={RETURN_STATUS_DISPLAY}
          history={returnOrderDetail.status_history}
          isFailed={isFailed}
          isWithContact={false}
          tooltip
        />

        {returnOrderDetail.status === RETURN_STATUS.return_failed && (
          <Block style={{marginTop: 24, marginBottom: 64}} error>
            <div>退貨失敗：{returnOrderDetail.failed_reason}</div>
            <div style={{color: Color.mainDark_70, fontSize: 14}}>
              將由指定服務門市人員聯絡您返還商品。如需協助，請聯絡{' '}
              <UnderlineButton
                onClick={() => {
                  window.open('tel:0800336677', '_self');
                }}>
                客服專線
              </UnderlineButton>
              。
            </div>
          </Block>
        )}

        {returnOrderDetail.partial_failed && (
          <Block style={{margin: '24px 0'}} error>
            <div>部分商品退貨失敗！</div>
          </Block>
        )}
      </section>
      <section className="return-items">
        <h1>2. 退貨項目</h1>
        <ReturnItems
          header={{
            store_name: returnOrderDetail.store_name,
            brand_name: returnOrderDetail.brand_name,
          }}
          returnList={returnList}
          isEnableEdit={false}
        />
      </section>
      <section className="return-address">
        <h1>3. 取件地址</h1>
        <AddressItem
          item={address}
          isEnableEdit={false}
          editButtonWording="變更取件地址"
        />
      </section>
      <section className="return-invoice">
        <h1>4. 退款明細</h1>
        <ReturnInvoice
          returnOrderDetail={returnOrderDetail}
          returnDetail={returnDetail}
          isDetailPage={true}
          status={returnOrderDetail.status}
        />
      </section>
      <section className="invoice-handle">
        <h1>5. 發票處理</h1>
        <InvoiceHandle
          returnInvoiceStatus={returnOrderDetail.invoice_status}
          returnInvoiceStatusHistory={returnOrderDetail.invoice_status_history}
        />
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${props =>
    !props.desktop ? Color.mainDark_10 : Color.mainWhite};
  min-width: ${({desktop}) => (desktop ? '888px' : 'unset')};

  & > section {
    padding: ${props => (!props.desktop ? '32px 20px' : '0 70px')};
    margin-bottom: 64px;
    background-color: ${Color.mainWhite};
    & h1 {
      margin-bottom: 20px;
    }
  }
  & > section.return-info {
    & > .return-number {
      margin-bottom: 20px;
    }
  }
`;

export default ReturnDetail;
