import React, {useMemo} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import CircleProgress from '../../../Components/CircleProgress';
import {Tooltip} from 'antd';

import {brandsDisplayArr} from '../../../Domain/Brand';
import {
  MEMBERSHIP_CONFIG,
  MEMBERSHIP_LEVEL,
  MEMBERSHIP_LEVEL_DISPLAY,
} from '../../../Domain/Member';
import {getFormatPrice} from '../../../Utils/ProductUtil';
import MemberLevelTable from './MemberLevelTable';
import {dateFormat} from 'rev-cms-core/src/common/DateUtil';
import {NavActions} from '../../../Contexts/AppContext';

function MemberLevelContent(props) {
  const {
    findMemberLevelIndex,
    findSelectedLevelIndex,
    selectedLevel,
    orderHistories,
    user,
    isLogin,
    desktop,
  } = props;

  const historiesWithWillExpired = useMemo(() => {
    if (!orderHistories) {
      return [];
    }
    return orderHistories.histories.map(h => {
      const orderDate = moment(h.date);
      const expiredDate = moment(new Date()).add(-1, 'year');

      return {
        ...h,
        readyExpired: orderDate.diff(expiredDate, 'days') <= 60,
        expiredDate: orderDate.add(1, 'year'),
      };
    });
  }, [orderHistories]);

  const historiesForRenewSection = useMemo(() => {
    if (!orderHistories || !user) {
      return [];
    }
    return orderHistories.histories.filter(h => {
      const orderDate = moment(h.date);
      const memberStartDate = moment(user.vip_start_date);

      return memberStartDate.isBefore(orderDate);
    });
  }, [orderHistories, user]);

  const grandTotalWillExpired = historiesWithWillExpired.reduce((acc, cur) => {
    if (cur.readyExpired) {
      acc += cur.price;
    }
    return acc;
  }, 0);

  const grandTotalHistories = historiesWithWillExpired.reduce((acc, cur) => {
    acc += cur.price;
    return acc;
  }, 0);

  const grandTotalHistoriesForRenewSection = historiesForRenewSection.reduce(
    (acc, cur) => {
      acc += cur.price;
      return acc;
    },
    0,
  );

  if (findMemberLevelIndex === findSelectedLevelIndex) {
    if (selectedLevel === 'M') {
      return <MemberLevelTable isLogin={isLogin} desktop={desktop} />;
    }

    return (
      <Wrapper>
        <div className="container none-padding-top">
          <div className="title">
            {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}續會累計
          </div>
          <div className="rectangle">
            <div className="name">
              會期結束前累計消費滿
              <span className="need-highlight-text">
                NT
                {getFormatPrice(
                  MEMBERSHIP_CONFIG[selectedLevel].grandTotalRenewThreshold,
                )}
              </span>
              ，即可續會一年
              <div className="need-gray-text">
                目前會期：{moment(user.vip_start_date).format('YYYY/MM/DD')} ~
                {moment(user.vip_end_date).format('YYYY/MM/DD')}
              </div>
            </div>
            <div className="gray-block">
              目前累計
              <span className="need-highlight-text">
                NT {getFormatPrice(grandTotalHistoriesForRenewSection)}
              </span>
              <div className="divider" />
              {MEMBERSHIP_CONFIG[selectedLevel].grandTotalRenewThreshold -
                grandTotalHistoriesForRenewSection <=
              0 ? (
                <>已成功續會</>
              ) : (
                <>
                  {moment(user.vip_end_date).format('YYYY/MM/DD')} 前再累計{' '}
                  <span className="need-highlight-text">
                    NT{' '}
                    {getFormatPrice(
                      MEMBERSHIP_CONFIG[selectedLevel]
                        .grandTotalRenewThreshold -
                        grandTotalHistoriesForRenewSection,
                    )}
                  </span>
                </>
              )}
            </div>

            {historiesForRenewSection.length > 0 && (
              <div className="records-container">
                <div className="row-container need-border-bottom need-margin-bottom">
                  <div className="cell header">日期</div>
                  <div className="cell header">當日消費總額</div>
                </div>
                {historiesForRenewSection.map((o, i) => (
                  <div className="row-container" key={i}>
                    <div className="cell">
                      {moment(o.date).format('YYYY/MM/DD')}
                    </div>
                    <div className="cell">NT {getFormatPrice(o.price)}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="container">
          <div className="title">
            {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}權益
          </div>
          <div className="field">
            <div className="name">專屬生日禮</div>
            <div className="value">
              生日禮金
              <span className="need-highlight-text">
                NT
                {getFormatPrice(
                  MEMBERSHIP_CONFIG[selectedLevel].birthGiftPoints,
                )}
              </span>
            </div>
          </div>
          <div className="field">
            <div className="name">消費累計回饋</div>
            <div className="value">
              每筆訂單
              <span className="need-highlight-text">
                {MEMBERSHIP_CONFIG[selectedLevel].rebatePointsRatio}%
              </span>
              購物金
            </div>
          </div>
          <div className="field">
            <div className="name">續卡獎勵</div>
            <div className="value">
              隔年成功續卡送
              <span className="need-highlight-text">
                NT{getFormatPrice(MEMBERSHIP_CONFIG[selectedLevel].renewPrice)}
              </span>
            </div>
          </div>
          <div className="field none-margin-bottom">
            <div className="name">品牌折扣</div>
            {brandsDisplayArr.map((brand, index) => {
              if (MEMBERSHIP_CONFIG[selectedLevel].discount[brand.key] !== 0) {
                return (
                  <div className="value" key={`brand-${index}`}>
                    {brand.display} 享
                    <span className="need-highlight-text">
                      {MEMBERSHIP_CONFIG[selectedLevel].discount[brand.key]}%
                      off
                    </span>
                    優惠
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>

        <div className="container none-border-bottom none-padding-bottom">
          <div className="title">詳細說明</div>
          <div className="field">
            <div className="name">累計續卡規則</div>
            <div className="value">
              ・會員效期內，消費累計達到 NT
              {getFormatPrice(
                MEMBERSHIP_CONFIG[selectedLevel].grandTotalRenewThreshold,
              )}
              ，會員資格將於到期日當天自動延續 1 年。
            </div>
            <div className="value">
              ・消費金額累計將於結帳日當天生效。若取消交易或退款，該筆消費則會從累計消費金額中扣除。
            </div>
            <div className="value">・累積消費為消費金額折扣後之累積</div>
          </div>
          <div className="field">
            <div className="name">累計有效期限</div>
            <div className="value">
              續卡累計有效期為一年，將在會期結束後統計是否符合續卡門檻。
            </div>
          </div>
          <div className="field">
            <div className="name">購物金</div>
            <div className="value">
              ・目前可用的生日禮金與會員回饋請至
              <span
                className="link"
                onClick={() => {
                  NavActions.navigate('/profile?active=credits');
                }}>
                購物金
              </span>
              查看，如果沒有生效的生日禮金將不顯示。
            </div>
            <div className="value">
              ・如於生日當月升等，可以獲得差額生日券 (升等次日生效)。
            </div>
            <div className="value">
              ・可累計消費購物金，並於下一筆消費開始可使用折抵，當次消費恕不折扣。
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (findMemberLevelIndex < findSelectedLevelIndex) {
    return (
      <Wrapper>
        <div className="container none-padding-top">
          <div className="title">
            {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}升等資訊
          </div>
          {selectedLevel === 'G' || selectedLevel === 'D' ? (
            <>
              <div className={`rectangle need-margin-bottom`}>
                方法一： 單筆消費滿
                <span className="need-highlight-text">
                  NT
                  {getFormatPrice(
                    MEMBERSHIP_CONFIG[selectedLevel].onceConsumptionPrice,
                  )}
                </span>
              </div>

              {/*<Tooltip placement="bottom" title={<div>已累計1000</div>}>*/}
              {/*  <div style={{display: 'inline-block'}}>*/}
              {/*    <CircleProgress*/}
              {/*      style={{margin: 10}}*/}
              {/*      size={150}*/}
              {/*      percentage={*/}
              {/*        (grandTotalHistories /*/}
              {/*          MEMBERSHIP_CONFIG[selectedLevel]*/}
              {/*            .grandTotalLevelUpThreshold) **/}
              {/*        100*/}
              {/*      }>*/}
              {/*      <div*/}
              {/*        style={{*/}
              {/*          textAlign: 'center',*/}
              {/*          width: '100%',*/}
              {/*          height: '100%',*/}
              {/*          display: 'flex',*/}
              {/*          alignItems: 'center',*/}
              {/*          justifyContent: 'center',*/}
              {/*          flexDirection: 'column',*/}
              {/*          padding: 15,*/}
              {/*        }}>*/}
              {/*        {grandTotalHistories /*/}
              {/*          MEMBERSHIP_CONFIG[selectedLevel]*/}
              {/*            .grandTotalLevelUpThreshold >=*/}
              {/*        1 ? (*/}
              {/*          <>*/}
              {/*            <div>已成功續會</div>*/}
              {/*          </>*/}
              {/*        ) : (*/}
              {/*          <>*/}
              {/*            <div>*/}
              {/*              {moment(new Date()).format('YYYY/MM/DD')} 前再累計*/}
              {/*            </div>*/}
              {/*            <div className="need-highlight-text">*/}
              {/*              NT{' '}*/}
              {/*              {getFormatPrice(*/}
              {/*                MEMBERSHIP_CONFIG[selectedLevel]*/}
              {/*                  .grandTotalLevelUpThreshold -*/}
              {/*                  grandTotalHistories,*/}
              {/*              )}*/}
              {/*            </div>*/}
              {/*          </>*/}
              {/*        )}*/}
              {/*      </div>*/}
              {/*    </CircleProgress>*/}
              {/*  </div>*/}
              {/*</Tooltip>*/}

              <div className="rectangle">
                方法二：累計消費滿 NT{' '}
                {getFormatPrice(
                  MEMBERSHIP_CONFIG[selectedLevel].grandTotalLevelUpThreshold,
                )}
                <div className="need-gray-text">
                  （只計算每日往前一年內的消費）
                </div>
                <div className="gray-block">
                  目前累計
                  <span className="need-highlight-text">
                    NT {getFormatPrice(grandTotalHistories)}
                  </span>{' '}
                  {grandTotalWillExpired > 0 && (
                    <span>
                      (NT {getFormatPrice(grandTotalWillExpired)} 累計即將失效)
                    </span>
                  )}
                  <div className="divider" />
                  {MEMBERSHIP_CONFIG[selectedLevel].grandTotalLevelUpThreshold -
                    grandTotalHistories <=
                  0 ? (
                    <>已成功升等</>
                  ) : (
                    <>
                      {moment(new Date()).format('YYYY/MM/DD')} 前再累計{' '}
                      <span className="need-highlight-text">
                        NT{' '}
                        {getFormatPrice(
                          MEMBERSHIP_CONFIG[selectedLevel]
                            .grandTotalLevelUpThreshold - grandTotalHistories,
                        )}
                      </span>
                    </>
                  )}
                </div>
                {historiesWithWillExpired.length > 0 && (
                  <div className="records-container">
                    <div className="row-container need-border-bottom need-margin-bottom">
                      <div
                        className={`cell header ${
                          desktop ? '' : 'need-small-cell'
                        }`}>
                        日期
                      </div>
                      <div className="cell header">當日消費總額</div>
                    </div>
                    {historiesWithWillExpired.map((o, idx) => (
                      <div key={idx} className="row-container">
                        <div
                          className={`cell ${
                            o.readyExpired ? 'need-red-text' : ''
                          } ${desktop ? '' : 'need-small-cell'}`}>
                          {moment(o.date).format('YYYY/MM/DD')}
                        </div>
                        <div
                          className={`cell ${
                            o.readyExpired ? 'need-red-text' : ''
                          }`}>
                          NT{getFormatPrice(o.price)}
                        </div>
                        {o.readyExpired && (
                          <div className="cell need-red-text">
                            （在 {moment(o.expiredDate).format('YYYY/MM/DD')}{' '}
                            將不再計入升等累計）
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="rectangle">
              單筆消費滿
              <span className="need-highlight-text">
                NT
                {getFormatPrice(
                  MEMBERSHIP_CONFIG[selectedLevel].onceConsumptionPrice,
                )}
              </span>
              即可升等{MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}
            </div>
          )}
        </div>

        <div className="container">
          <div className="title">
            {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}權益
          </div>
          <div className="field">
            <div className="name">專屬生日禮</div>
            <div className="value">
              生日禮金
              <span className="need-highlight-text">
                NT
                {getFormatPrice(
                  MEMBERSHIP_CONFIG[selectedLevel].birthGiftPoints,
                )}
              </span>
            </div>
          </div>
          <div className="field">
            <div className="name">消費累計回饋</div>
            <div className="value">
              每筆訂單
              <span className="need-highlight-text">
                {MEMBERSHIP_CONFIG[selectedLevel].rebatePointsRatio}%
              </span>
              購物金
            </div>
          </div>
          {(selectedLevel === 'G' || selectedLevel === 'D') && (
            <div className="field">
              <div className="name">升等獎勵（限單筆消費滿額）</div>
              <div className="value">
                升等就送
                <span className="need-highlight-text">
                  NT
                  {getFormatPrice(
                    MEMBERSHIP_CONFIG[selectedLevel].levelUpPrice,
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="field">
            <div className="name">續卡獎勵</div>
            <div className="value">
              隔年成功續卡送
              <span className="need-highlight-text">
                NT{getFormatPrice(MEMBERSHIP_CONFIG[selectedLevel].renewPrice)}
              </span>
            </div>
          </div>
          <div className="field none-margin-bottom">
            <div className="name">品牌折扣</div>
            {brandsDisplayArr.map((brand, index) => {
              if (MEMBERSHIP_CONFIG[selectedLevel].discount[brand.key] !== 0) {
                return (
                  <div className="value" key={`brand-${index}`}>
                    {brand.display} 享
                    <span className="need-highlight-text">
                      {MEMBERSHIP_CONFIG[selectedLevel].discount[brand.key]}%
                      off
                    </span>
                    優惠
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>

        <div className="container none-border-bottom none-padding-bottom">
          <div className="title">詳細說明</div>
          <div className="field">
            <div className="name">升等方式</div>
            <div className="value">
              單筆消費滿 NT
              {getFormatPrice(
                MEMBERSHIP_CONFIG[selectedLevel].onceConsumptionPrice,
              )}
              ，即可升級至
              {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}會員
            </div>
          </div>
          {(selectedLevel === 'G' || selectedLevel === 'D') && (
            <>
              <div className="field">
                <div className="name">升等累計規則</div>
                <div className="value">
                  ・每日 24 點，往前一年計算消費累計滿 NT
                  {getFormatPrice(
                    MEMBERSHIP_CONFIG[selectedLevel].grandTotalLevelUpThreshold,
                  )}
                  。
                </div>
                <div className="value">
                  ・消費金額累計將於結帳日當天生效。若取消交易或退款，該筆消費則會從累計消費金額中扣除。
                </div>
              </div>
              <div className="field">
                <div className="name">累計有效期限</div>
                <div className="value">
                  每日 24 點結算，往前一年內的消費計入將記入升等累計中。
                </div>
              </div>
            </>
          )}
          <div className="field none-border-bottom">
            <div className="name">購物金</div>
            <div className="value">
              ・目前可用的生日禮金與會員回饋請至
              <span
                className="link"
                onClick={() => {
                  NavActions.navigate('/profile?active=credits');
                }}>
                購物金
              </span>
              查看，如果沒有生效的生日禮金將不顯示。
            </div>
            <div className="value">
              ・如於生日當月升等，可以獲得差額生日券 (升等次日生效)。
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (findMemberLevelIndex > findSelectedLevelIndex) {
    if (selectedLevel === 'M') {
      return (
        <Wrapper>
          <div className="gray-block">
            已達成 {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}會員 門檻
          </div>
          <MemberLevelTable isLogin={isLogin} desktop={desktop} />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div className="gray-block">
          已達成 {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}會員 門檻
        </div>
        <div className="container">
          <div className="title">
            {MEMBERSHIP_LEVEL_DISPLAY[selectedLevel].zh}權益
          </div>
          <div className="field">
            <div className="name">專屬生日禮</div>
            <div className="value">
              生日禮金
              <span className="need-highlight-text">
                NT
                {getFormatPrice(
                  MEMBERSHIP_CONFIG[selectedLevel].birthGiftPoints,
                )}
              </span>
            </div>
          </div>
          <div className="field">
            <div className="name">消費累計回饋</div>
            <div className="value">
              每筆訂單
              <span className="need-highlight-text">
                {MEMBERSHIP_CONFIG[selectedLevel].rebatePointsRatio}%
              </span>
              購物金
            </div>
          </div>
          <div className="field">
            <div className="name">續卡獎勵</div>
            <div className="value">
              隔年成功續卡送
              <span className="need-highlight-text">
                NT{getFormatPrice(MEMBERSHIP_CONFIG[selectedLevel].renewPrice)}
              </span>
            </div>
          </div>
          <div className="field none-margin-bottom">
            <div className="name">品牌折扣</div>
            {brandsDisplayArr.map((brand, index) => {
              if (MEMBERSHIP_CONFIG[selectedLevel].discount[brand.key] !== 0) {
                return (
                  <div className="value" key={`brand-${index}`}>
                    {brand.display} 享
                    <span className="need-highlight-text">
                      {MEMBERSHIP_CONFIG[selectedLevel].discount[brand.key]}%
                      off
                    </span>
                    優惠
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>

        <div className="container none-border-bottom none-padding-bottom">
          <div className="title">詳細說明</div>
          <div className="field">
            <div className="name">累計續卡規則</div>
            <div className="value">
              ・會員效期內，消費累計達到 NT
              {getFormatPrice(
                MEMBERSHIP_CONFIG[selectedLevel].grandTotalRenewThreshold,
              )}
              ，會員資格將於到期日當天自動延續 1 年。
            </div>
            <div className="value">
              ・消費金額累計將於結帳日當天生效。若取消交易或退款，該筆消費則會從累計消費金額中扣除。
            </div>
            <div className="value">・累積消費為消費金額折扣後之累積</div>
          </div>
          <div className="field">
            <div className="name">累計有效期限</div>
            <div className="value">
              續卡累計有效期為一年，將在會期結束後統計是否符合續卡門檻。
            </div>
          </div>
          <div className="field">
            <div className="name">購物金</div>
            <div className="value">
              ・目前可用的生日禮金與會員回饋請至
              <span
                className="link"
                onClick={() => {
                  NavActions.navigate('/profile?active=credits');
                }}>
                購物金
              </span>
              查看，如果沒有生效的生日禮金將不顯示。
            </div>
            <div className="value">
              ・如於生日當月升等，可以獲得差額生日券 (升等次日生效)。
            </div>
            <div className="value">
              ・可累計消費購物金，並於下一筆消費開始可使用折抵，當次消費恕不折扣。
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  return null;
}

const Wrapper = styled.div`
  & > .container {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    & > .title {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.4;
    }

    & > .rectangle {
      font-size: 14px;
      font-weight: 500;
      padding: 12px 20px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);

      & > div.name {
        margin-bottom: 24px;
      }

      & > div.gray-block {
        margin-bottom: 24px;
        max-width: 600px;
        width: 100%;
        background-color: rgba(20, 20, 20, 0.03);
        padding: 14px 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        font-size: 12px;
        line-height: 1.67;
        color: rgba(20, 20, 20, 0.7);

        .need-highlight-text {
          padding: 0px 3px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          color: #141414;
        }

        .divider {
          margin: 0px 10px;
          width: 1px;
          height: 12px;
          background-color: rgba(20, 20, 20, 0.3);
        }
      }

      & > .records-container {
        max-width: 600px;
        width: 100%;

        & > .row-container {
          display: flex;
        }

        & .header {
          padding-bottom: 4px;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.67;
          color: rgba(20, 20, 20, 0.7);
        }

        & .cell {
          margin-bottom: 4px;
          min-width: 160px;

          font-size: 12px;
          line-height: 1.67;
          color: #141414;
        }

        .need-red-text {
          color: #e22828;
        }

        .need-small-cell {
          max-width: 80px;
          min-width: auto;
          width: 100%;
        }

        .need-border-bottom {
          border-bottom: 2px solid rgba(20, 20, 20, 0.1);
        }
        .need-margin-bottom {
          margin-bottom: 8px;
        }
      }
    }

    & > .field {
      margin-bottom: 24px;

      & > div.name {
        margin-bottom: 2px;
        font-weight: 500;
      }

      & > div.value {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 1.57;
        color: rgba(20, 20, 20, 0.7);
      }

      .link {
        cursor: pointer;
        text-decoration: underline;
        padding: 0px 3px;

        :hover {
          color: rgba(20, 20, 20, 0.7);
        }
      }
    }

    .need-highlight-text {
      padding: 0px 3px;
      color: #cca75c;
    }

    .need-gray-text {
      color: rgba(20, 20, 20, 0.7);
    }

    .none-margin-bottom {
      margin-bottom: 0px;
    }

    .need-margin-bottom {
      margin-bottom: 16px;
    }
  }

  .none-padding-top {
    padding-top: 0px;
  }

  .none-border-bottom {
    border-bottom: 0px;
  }

  .none-padding-bottom {
    padding-bottom: 0px;
  }

  .none-margin-bottom {
    margin-bottom: 0px;
  }

  & > div.gray-block {
    margin-bottom: 24px;
    width: 100%;
    padding: 12px;
    background-color: rgba(20, 20, 20, 0.03);

    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default MemberLevelContent;
