import React, {Fragment, useContext, useMemo} from 'react';
import styled from 'styled-components';
import OrderShipping from './OrderShipping';
import {Actions, Context, NavActions} from '../../../Contexts/AppContext';
import {d2s} from '../../../Utils/DateUtil';
import {ChevronLeft, Info} from '../../../Components/SvgIcon';
import Check from '../../../images/illustration-check.svg';
import OrderCancel from '../../../images/illustration-order-cancel.svg';
import Time from '../../../images/illustration-time.svg';
import {
  Color,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  UnderlineButton,
} from '../../../Components/Widget';
import * as L from '../../../Utils/Lang';
import {getFormatPrice} from '../../../Utils/ProductUtil';
import {
  CANCEL_REASON,
  CREDIT_CARD_TYPE_PIC,
  CVS_TYPE_DATA,
  INVOICE_SUBTYPE,
  INVOICE_SUBTYPE_DISPLAY,
  INVOICE_TYPE_DISPLAY,
  ORDER_STATUS,
  ORDER_STATUS_DISPLAY,
  SHIPPING_TYPE,
  SHIPPING_TYPE_DISPLAY,
  SUBORDER_STATUS,
} from '../../../Domain/Order';
import RectButton, {
  BUTTON_SIZE,
  BUTTON_SKIN,
} from '../../../Components/RectButton';
import Confirm from '../../../Components/Modal/Confirm';
import Alert from '../../../Components/Modal/Alert';
import {MEMBERSHIP_LEVEL_DISPLAY} from '../../../Domain/Member';
import {ordersActions} from './MyOrders';
import useOrderManager from '../../../Hooks/useOrderManager';
import SFIcon from '../../../images/icon-logo-s-fexpress@3x.png';
import {Tooltip} from 'antd';
import {brandsDisplayArr} from '../../../Domain/Brand';
import EmptyImage from '../../../images/emptyimage.png';

function OrderDetail(props) {
  const {mobile, orderId, fetchOrders, selectedTab} = props;
  const {order} = useOrderManager({orderId});

  const openCancelOrderSuccess = () => {
    Actions.setGlobalModalContent(
      <Alert
        onClick={async () => {
          await fetchOrders({
            prevOrders: [],
            status: selectedTab,
            offset: 0,
          });
          Actions.setGlobalModalContent(null);
          NavActions.navigate('/profile/?active=orders');
        }}
        cancelLabel="我知道了"
        icon={<img src={Check} alt="" />}
        title="您的訂單已取消"
        subTitle={
          <FlexCol>
            <small style={{margin: '4px 0'}}>
              預計返回金額：
              <span style={{color: '#141414', fontWeight: 'bold'}}>
                NT {getFormatPrice(order.calculations.total)}
              </span>
            </small>
            <small style={{margin: '4px 0'}}>
              返回購物金：
              <span style={{color: '#cca75c', fontWeight: 'bold'}}>
                NT {getFormatPrice(order.calculations.redeem_credit)}
              </span>
            </small>
          </FlexCol>
        }
      />,
    );
  };

  const onCancelOrderClick = id => async () => {
    Actions.setGlobalModalContent(
      <Confirm
        icon={<img src={OrderCancel} alt="" />}
        confirmLabel="確定"
        cancelLabel="返回"
        subTitle="提醒您！若有使用購物金會一併返還，若因本訂單贈送購物金或達成會員續晉卡，也會一併取消"
        title="確定要取消訂單嗎？"
        onClick={async () => {
          const isSuccess = await Actions.cancelOrder(id);
          if (!isSuccess) {
            Actions.setGlobalModalContent(
              <Alert
                icon={<img src={Time} alt="" />}
                title="取消訂單失敗"
                subTitle="請稍後再試"
                cancelLabel="確定"
              />,
            );
            return;
          }
          await Actions.fetchMe();
          openCancelOrderSuccess();
        }}
      />,
    );
  };

  const isShowCancelOrderButton = useMemo(() => {
    if (!order) {
      return false;
    }
    return (
      order.status === ORDER_STATUS.unpaid ||
      order.status === ORDER_STATUS.processing
    );
  }, [order]);

  if (!order) {
    return null;
  }

  if (mobile) {
    return (
      <MobileWrapper>
        <OrderSummary order={order} mobile />

        <div style={{height: 1, backgroundColor: '#ccc'}} />

        {!order.is_offline_order && <ContactSupport mobile />}

        <div style={{margin: '32px 0 20px 0', padding: '0 20px'}}>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>訂單資訊</div>
          {!order.is_offline_order && (
            <div style={{margin: '10px 0'}}>
              若您購買多個品牌的商品，您的包裹將依照品牌分批到貨
            </div>
          )}
        </div>

        {order.sub_orders.map((subOrder, idx) => (
          <SubOrder
            order={order}
            style={{marginTop: 20}}
            key={subOrder.brand_name}
            subOrder={subOrder}
            mobile
          />
        ))}

        <OrderPayment order={order} mobile />

        {!order.is_offline_order && (
          <FlexRowCenter style={{backgroundColor: Color.mainWhite}}>
            {isShowCancelOrderButton && (
              <UnderlineButton
                onClick={onCancelOrderClick(orderId)}
                style={{margin: '32px 0'}}>
                取消訂單
              </UnderlineButton>
            )}
          </FlexRowCenter>
        )}
      </MobileWrapper>
    );
  }

  return (
    <Wrapper>
      <div className="left">
        <FlexCol>
          <div
            className="btn"
            onClick={async () => {
              await fetchOrders({
                prevOrders: [],
                status: selectedTab,
                offset: 0,
              });
              return NavActions.navigate('/profile?active=orders');
            }}>
            <ChevronLeft />
            <div>訂單列表</div>
          </div>

          <OrderSummary order={order} style={{marginTop: 20}} />

          <div style={{margin: '32px 0 20px 0'}}>
            <div style={{fontSize: 24, fontWeight: 'bold'}}>訂單資訊</div>
            {!order.is_offline_order && (
              <div style={{margin: '10px 0'}}>
                若您購買多個品牌的商品，您的包裹將依照品牌分批到貨
              </div>
            )}
          </div>

          {order.sub_orders.map((subOrder, idx) => (
            <SubOrder
              order={order}
              style={{marginTop: 20}}
              key={subOrder.brand_name}
              subOrder={subOrder}
            />
          ))}

          {!order.is_offline_order && isShowCancelOrderButton && (
            <UnderlineButton
              onClick={onCancelOrderClick(orderId)}
              style={{margin: '32px 0'}}>
              取消訂單
            </UnderlineButton>
          )}
        </FlexCol>
      </div>

      <div className="right">
        <div style={{position: 'sticky'}}>
          <OrderPayment order={order} />
          {!order.is_offline_order && (
            <ContactSupport style={{marginTop: 32}} />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const MobileWrapper = styled.div`
  padding: 0;
  background-color: rgba(20, 20, 20, 0.04);
`;

const Wrapper = styled.div`
  padding: 40px 50px;
  display: flex;

  & .btn {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }

  & > .left {
    flex: 1;
  }

  & > .right {
    margin-left: 40px;
    flex-basis: 350px;
  }
`;

function OrderSummary(props) {
  const {order, mobile, style = {}} = props;
  const {order_no, created, note, config} = order;
  const {pick_up_address, receiver_phone, receiver} = config.shipping;
  const {type, address, city, district, name, cvs_store_type} = pick_up_address;

  return (
    <OrderSummaryWrapper style={style} mobile={mobile}>
      <header>
        {order.is_offline_order ? (
          <h2>門市消費</h2>
        ) : (
          <Fragment>
            {order.status !== ORDER_STATUS.unpaid &&
              order.status !== ORDER_STATUS.canceled && (
                <h2>{L.d(ORDER_STATUS_DISPLAY[order.status])}</h2>
              )}

            {order.status === ORDER_STATUS.unpaid && (
              <FlexRow style={{justifyContent: 'space-between'}}>
                <FlexRow style={{alignItems: 'baseline'}}>
                  <h2 style={{color: '#e22828'}}>
                    {L.d(ORDER_STATUS_DISPLAY[order.status])}
                  </h2>
                  <small
                    style={{
                      color: '#ccc',
                      marginLeft: '8px',
                      maxWidth: 400,
                      whiteSpace: 'pre-wrap',
                    }}>
                    {L.d(order.payment_failed_reason)}
                  </small>
                </FlexRow>
                <RectButton
                  skin={BUTTON_SKIN.PRIMARY}
                  text="重新付款"
                  size={BUTTON_SIZE.SMALL}
                  onClick={() => {
                    NavActions.navigate(
                      `/profile?active=orders&orderId=${order.id}&action=re_checkout`,
                    );
                  }}
                />
              </FlexRow>
            )}

            {order.status !== ORDER_STATUS.unpaid &&
              order.status === ORDER_STATUS.canceled && (
                <FlexRow style={{alignItems: 'baseline'}}>
                  <h2>{L.d(ORDER_STATUS_DISPLAY[order.status])}</h2>
                  <small style={{color: '#ccc', marginLeft: '8px'}}>
                    {L.d(CANCEL_REASON[order.cancel_reason])}
                  </small>
                </FlexRow>
              )}
          </Fragment>
        )}
      </header>

      <div className="sep" />

      <div className="row">
        <label>訂單編號</label>
        <div>{order_no}</div>
      </div>

      <div className="row">
        <label>購買日期</label>
        <div>{d2s(created)}</div>
      </div>

      {!order.is_offline_order && (
        <Fragment>
          <div className="row">
            <label>備註</label>
            <div>{note ? note : '無'}</div>
          </div>

          <div className="row">
            <label>運送方式</label>
            <div>{L.d(SHIPPING_TYPE_DISPLAY[pick_up_address.type])}</div>
          </div>

          <div className="row">
            <label>取件人</label>
            <div>
              {receiver} {receiver_phone}
            </div>
          </div>

          <div className="row">
            <label>
              {type === SHIPPING_TYPE.cvs && '取件門市'}
              {type === SHIPPING_TYPE.home && '取件地址'}
            </label>
            {type === SHIPPING_TYPE.cvs && (
              <FlexCol>
                <FlexRow>
                  <img
                    style={{width: '22px', height: '22px'}}
                    src={CVS_TYPE_DATA[cvs_store_type].iconPath}
                    alt="cvs icon"
                  />
                  {name}
                </FlexRow>
                <div>{address}</div>
              </FlexCol>
            )}
            {type === SHIPPING_TYPE.home && (
              <FlexRow>
                <img
                  style={{width: '22px', height: '22px', marginRight: 8}}
                  className="icon"
                  src={SFIcon}
                  alt="sf-icon"
                />
                <div>
                  {city} {district} {address}
                </div>
              </FlexRow>
            )}
          </div>
        </Fragment>
      )}
    </OrderSummaryWrapper>
  );
}

const OrderSummaryWrapper = styled.div`
  padding: 20px;
  background-color: white;
  border: ${props => (props.mobile ? 'none' : '1px solid #ccc')};
  white-space: nowrap;

  & > header h2 {
    margin-bottom: 0;
  }

  & > .sep {
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
  }

  & > .row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > label {
      color: rgba(20, 20, 20, 0.7);
      flex: 1;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

function SubOrder(props) {
  const {order, subOrder, mobile, style = {}} = props;

  return (
    <SubOrderWrapper style={style} mobile={mobile}>
      {!order.is_offline_order && (
        <header>
          <div className="brand-name" style={{fontSize: 20}}>
            {brandsDisplayArr.find(b => b.key === subOrder.brand_name).display}
          </div>
          <FlexRow className="assigned-store">
            <div style={{fontWeight: 500, fontSize: 14}}>指定服務門市</div>
            <Tooltip
              placement="bottom"
              title="若您需要維修、保養、線上諮詢...等協助，將由此門市替您服務。">
              <Info style={{margin: '0 4px'}} />
            </Tooltip>
            <div
              style={{fontWeight: 300, fontSize: 14, color: Color.mainDark_70}}>
              : {L.d(subOrder.store.title)}
            </div>
          </FlexRow>
        </header>
      )}

      <div className="content">
        {!order.is_offline_order && (
          <Fragment>
            <OrderShipping
              style={{marginBottom: '60px'}}
              order={order}
              subOrder={subOrder}
            />
            <div className="sep" />
          </Fragment>
        )}
        {subOrder.items.map((item, idx) => (
          <BrandProduct
            order={order}
            subOrder={subOrder}
            key={idx}
            item={item}
            style={{marginBottom: 20}}
          />
        ))}
        <div className="sep" />
        {!order.is_offline_order &&
          subOrder.status === SUBORDER_STATUS.done &&
          subOrder.returnable && (
            <UnderlineButton
              onClick={() => {
                NavActions.navigate(
                  `/profile?active=orders&subOrderId=${subOrder.id}&action=${ordersActions.RETURN}&orderId=${order.id}`,
                );
              }}
              style={{display: 'block', margin: '0 auto'}}>
              申請退貨
            </UnderlineButton>
          )}

        {order.is_offline_order && (
          <h2 style={{textAlign: 'right'}}>
            總金額 NT {getFormatPrice(order.price)}
          </h2>
        )}
      </div>
    </SubOrderWrapper>
  );
}

const SubOrderWrapper = styled.div`
  border: ${props =>
    props.mobile ? 'none' : '1px solid rgba(20, 20, 20, 0.3)'};
  background-color: white;

  & > header {
    background-color: rgba(20, 20, 20, 0.03);
    padding: ${props => (props.mobile ? '8px 20px' : '15px 20px')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .brand-name {
      font-size: 20px;
      font-family: 'Gotham-Light';
    }
  }

  & > .content {
    padding: 20px;

    & > .sep {
      height: 1px;
      background-color: #ccc;
      margin: 20px 0;
    }
  }
`;

function BrandProduct(props) {
  const {order, subOrder, item, style = {}} = props;
  const app = useContext(Context);
  const {quantity, variant, price} = item;
  const withProduct = item?.variant?.product;
  const isPromote = !!variant?.product?.promotion;
  const isReturn = item.requested_return;

  return (
    <div style={{position: 'relative'}}>
      <BrandProductWrapper style={style}>
        <div style={{position: 'relative'}}>
          {withProduct ? (
            variant.images.length > 0 && (
              <img
                className={`${isReturn ? 'opacity-30' : ''}`}
                src={variant.images[0]}
                alt=""
              />
            )
          ) : (
            <img
              src={EmptyImage}
              alt=""
              style={{
                width: 100,
                height: 150,
                objectFit: 'cover',
                background: '#ccc',
              }}
            />
          )}
          {isReturn && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              退貨
            </div>
          )}
        </div>

        <div className={`content ${isReturn ? 'opacity-30' : ''}`}>
          <div className="info">
            {isPromote && withProduct && (
              <div>{L.d(variant.product.promotion)}</div>
            )}
            <div>{withProduct ? L.d(variant.product.title) : variant.uid}</div>
          </div>

          <div className="info">
            <div>
              {withProduct
                ? `${L.d(variant.colorDisplay)} / ${L.d(variant.size)}`
                : ''}
            </div>
            <div>數量 : {quantity}</div>
          </div>

          <div className="price-tag">
            <div className="price">NT {getFormatPrice(price)}</div>
          </div>
        </div>
      </BrandProductWrapper>
      {isReturn && (
        <UnderlineButton
          onClick={() => {
            NavActions.navigate(
              `/profile?active=orders&returnOrderId=${subOrder.return_order_id}&action=${ordersActions.RETURN_DETAIL}&orderId=${order.id}`,
            );
          }}
          style={{
            display: 'block',
            margin: '0 auto',
            color: Color.red,
            position: 'absolute',
            top: 0,
            right: '10px',
          }}>
          查看退貨狀態
        </UnderlineButton>
      )}
    </div>
  );
}

const BrandProductWrapper = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.67;
  & .opacity-30 {
    filter: opacity(30%);
  }

  & > div > img {
    width: 100px;
    height: 150px;
    object-fit: contain;
  }

  & > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;

    & .info {
      flex: 1;
    }

    & .price-tag {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      & .deprecated {
        text-decoration: line-through;
        color: rgba(20, 20, 20, 0.3);
      }
    }
  }
`;

function OrderPayment(props) {
  const {order, mobile} = props;

  if (order.is_offline_order) {
    return null;
  }

  const INVOICE_NUMBER_MAP = {
    [INVOICE_SUBTYPE.ecpay]: '中獎後以電子信箱通知',
    [INVOICE_SUBTYPE.mobile]: order.config.invoice.invoice_carrier,
    [INVOICE_SUBTYPE.b2b]: order.config.invoice.invoice_tax,
  };

  return (
    <OrderPaymentWrapper mobile={mobile}>
      <h2 style={{marginBottom: 20}}>支付明細</h2>

      <div className="row" style={{marginBottom: 12}}>
        <label>小計</label>
        <div className="price">
          NT {getFormatPrice(order.calculations.subtotal)}
        </div>
      </div>

      <div className="row" style={{marginBottom: 12}}>
        <label>運費</label>
        <div className="price">
          NT {getFormatPrice(order.calculations.shipping_cost)}
        </div>
      </div>

      {order.calculations.extra_discounts.amount !== 0 &&
        order.calculations.extra_discounts.amount && (
          <div className="row" style={{marginBottom: 12}}>
            <label>{L.d(order.calculations.extra_discounts.title)}</label>
            <div className="price" style={{color: 'red'}}>
              - NT {getFormatPrice(order.calculations.extra_discounts.amount)}
            </div>
          </div>
        )}

      {order.calculations.redeem_credit !== 0 &&
        order.calculations.redeem_credit && (
          <div className="row">
            <label>購物金折抵</label>
            <div className="price" style={{color: 'red'}}>
              - NT {getFormatPrice(order.calculations.redeem_credit)}
            </div>
          </div>
        )}

      <div className="sep" />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        <div style={{marginRight: 5, fontSize: 14, fontWeight: 500}}>
          總金額
        </div>
        <div style={{fontSize: 20, fontWeight: 'bold'}}>
          NT {getFormatPrice(order.calculations.total)}
        </div>
      </div>
      {order.calculations.reward !== 0 && order.calculations.reward && (
        <div className="giveback">
          <div className="row">
            <label>
              {L.d(
                MEMBERSHIP_LEVEL_DISPLAY[order.calculations.membership_level],
              )}
              消費回饋 {order.calculations.membership_reward_percent * 100}%
            </label>
            <div className="price" style={{color: Color.gold}}>
              NT {getFormatPrice(order.calculations.reward)}
            </div>
          </div>

          <div className="sep" />

          <div style={{textAlign: 'center', fontWeight: 300, fontSize: 12}}>
            訂單完成七天後匯入會員帳號
          </div>
        </div>
      )}

      <div className="sep" />

      <div className="row">
        <label>付款方式</label>
        {CREDIT_CARD_TYPE_PIC[order.config.payment.credit_card_type]} *
        <div style={{fontWeight: 'bold'}}>
          {order.config.payment.last_4_digits}
        </div>
      </div>
      {order.config.payment.installment !== 0 && (
        <div style={{textAlign: 'right'}}>
          {order.config.payment.installment}期零利率
        </div>
      )}

      <div className="row" style={{marginTop: 12}}>
        <label>發票類型</label>
        <div style={{fontWeight: 'bold'}}>
          {L.d(INVOICE_TYPE_DISPLAY[order.config.invoice.type])}
        </div>
      </div>

      <div className="row" style={{marginTop: 12}}>
        <label>開立方式</label>
        <div style={{fontWeight: 'bold'}}>
          {L.d(INVOICE_SUBTYPE_DISPLAY[order.config.invoice.subtype])}
        </div>
      </div>
      <div style={{textAlign: 'right'}}>
        {INVOICE_NUMBER_MAP[order.config.invoice.subtype]}
      </div>
    </OrderPaymentWrapper>
  );
}

const OrderPaymentWrapper = styled.div`
  padding: 20px;
  background-color: rgba(20, 20, 20, 0.03);

  & .price {
    font-weight: bold;
  }

  & .sep {
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
  }

  & .row {
    display: flex;
    align-items: center;

    & > label {
      flex: 1;
      color: rgba(20, 20, 20, 0.7);
    }
  }

  & > .giveback {
    margin: 20px 0;
    background-color: white;
    border-top: 2px solid black;
    padding: 14px 20px;
  }
`;

function ContactSupport(props) {
  const {mobile, style = {}} = props;

  return (
    <ContactSupportWrapper style={style} mobile={mobile}>
      {/*// FIXME: @guychienll future feature comment first*/}
      {/*<RectButton*/}
      {/*  text="聯絡線上客服"*/}
      {/*  skin={BUTTON_SKIN.DEFAULT}*/}
      {/*  style={{width: '100%', marginBottom: 8}}*/}
      {/*  onClick={() => {*/}
      {/*    window.open('tel:0800336677', '_self');*/}
      {/*  }}*/}
      {/*/>*/}
      <div style={{marginTop: 8}}>
        您可以在星期一至星期五，9:00 至 18:00 聯絡線上客服，或撥打客服專線
        <UnderlineButton
          onClick={() => {
            window.open('tel:0800336677', '_self');
          }}
          style={{color: Color.mainDark}}>
          0800336677
        </UnderlineButton>
        。
      </div>

      <div style={{marginTop: 32, color: 'black', fontWeight: 'bold'}}>
        免費退換貨
      </div>
      <div style={{marginTop: 8}}>享有 免費運送 與 7 天內 免費退貨。</div>
    </ContactSupportWrapper>
  );
}

const ContactSupportWrapper = styled.div`
  padding: 20px;
  background-color: white;
  border: ${props => (props.mobile ? 'none' : '1px solid #ccc')};
  position: relative;

  & > button.primary {
    border: 1px solid black;
    background-color: white;
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  & > p {
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default OrderDetail;
