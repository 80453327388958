import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import OrderTabs from './OrderTabs';
import OrderItem from './OrderItem';
import OrderDetail from './OrderDetail';
import {Actions, NavActions} from '../../../Contexts/AppContext';
import {ORDER_STATUS} from '../../../Domain/Order';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import ReCheckout from './ReCheckout';
import Return from './Return/Return';
import ReturnDetail from './Return/ReturnDetail';
import CustomNav from '../../../Components/CustomNav';
import {ChevronLeft} from '../../../Components/SvgIcon';
import {useDimension} from '../../../Hooks/AppHooks';

export const ordersActions = {
  RE_CHECKOUT: 're_checkout',
  RETURN: 'return',
  RETURN_DETAIL: 'return_detail',
};

function MyOrders(props) {
  const {orderId, action, subOrderId, returnOrderId} = props;
  const {desktop} = useDimension();
  const tabs = Object.values(ORDER_STATUS);
  const [selectedTab, setSelectedTab] = useState('');
  const [orders, setOrders] = useState([]);

  const [totalCountsByStatus, setTotalCountsByStatus] = useState({
    [ORDER_STATUS.processing]: 0,
    [ORDER_STATUS.canceled]: 0,
    [ORDER_STATUS.done]: 0,
    [ORDER_STATUS.waiting]: 0,
    [ORDER_STATUS.unpaid]: 0,
    [ORDER_STATUS.returned]: 0,
  });
  const PAGE_UNIT = 30;
  const DEFAULT_LIMIT = 2000;
  const [offset, setOffset] = useState(0);
  const [showIndex, setShowIndex] = useState(PAGE_UNIT);

  const totalCount = useMemo(() => {
    return Object.values(totalCountsByStatus).reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);
  }, [totalCountsByStatus]);

  const fetchOrders = useCallback(async ({prevOrders, status, offset}) => {
    Actions.setLoading(true);
    try {
      const resp = await Actions.fetchOrders({
        status: status,
        offset: offset,
        limit: DEFAULT_LIMIT,
      });

      setOrders([...prevOrders, ...resp.orders]);
      setTotalCountsByStatus(value => ({
        ...value,
        ...resp.order_status_count,
      }));
      setOffset(offset);
    } catch (ex) {
      console.warn(ex);
    }
    Actions.setLoading(false);
  }, []);

  useEffect(() => {
    setOffset(0);
    setShowIndex(PAGE_UNIT);
    fetchOrders({prevOrders: [], status: selectedTab, offset: 0});
  }, [fetchOrders, selectedTab]);

  const fetchNextPageOrders = async () => {
    setShowIndex(prev => prev + PAGE_UNIT);
  };

  if (!orders) {
    return null;
  }

  if (orderId && !action) {
    return (
      <Fragment>
        {!desktop && (
          <CustomNav
            navType="solid"
            left={
              <ChevronLeft
                onClick={() => {
                  NavActions.navigate(`/profile?active=orders`);
                }}
              />
            }
            middle={<div>訂單詳情</div>}
          />
        )}
        <OrderDetail
          selectedTab={selectedTab}
          fetchOrders={fetchOrders}
          orderId={orderId}
          mobile={!desktop}
        />
      </Fragment>
    );
  }

  if (orderId && action === ordersActions.RE_CHECKOUT) {
    return (
      <Fragment>
        {!desktop && (
          <CustomNav
            navType="solid"
            left={
              <ChevronLeft
                onClick={() => {
                  NavActions.navigate(
                    `/profile?active=orders&orderId=${orderId}`,
                  );
                }}
              />
            }
            middle={<div>重新付款</div>}
          />
        )}
        <ReCheckout orderId={orderId} />
      </Fragment>
    );
  }

  if (action === ordersActions.RETURN) {
    return (
      <Fragment>
        {!desktop && (
          <CustomNav
            navType="solid"
            left={
              <ChevronLeft
                onClick={() => {
                  NavActions.navigate(
                    `/profile?active=orders&orderId=${orderId}`,
                  );
                }}
              />
            }
            middle={<div>申請退貨</div>}
          />
        )}
        <Return orderId={orderId} subOrderId={subOrderId} />
      </Fragment>
    );
  }

  if (action === ordersActions.RETURN_DETAIL) {
    return (
      <Fragment>
        {!desktop && (
          <CustomNav
            navType="solid"
            left={
              <ChevronLeft
                onClick={() => {
                  NavActions.navigate(
                    `/profile?active=orders&orderId=${orderId}`,
                  );
                }}
              />
            }
            middle={<div>退貨狀態</div>}
          />
        )}
        <ReturnDetail returnOrderId={returnOrderId} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Wrapper mobile={!desktop}>
        {desktop && <h2>購買記錄</h2>}
        <OrderTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          totalCounts={totalCountsByStatus}
          mobile={!desktop}
        />

        <div className="order-list">
          {selectedTab !== '' &&
            orders
              .filter(order => order.status === selectedTab)
              .filter((_, index) => index + 1 <= showIndex)
              .map((order, idx) => (
                <OrderItem
                  selectedTab={selectedTab}
                  key={idx}
                  order={order}
                  mobile={!desktop}
                  style={{marginBottom: !desktop ? 20 : 32}}
                />
              ))}
          {selectedTab === '' &&
            orders
              .filter((_, index) => index + 1 <= showIndex)
              .map((order, idx) => (
                <OrderItem
                  selectedTab={selectedTab}
                  key={idx}
                  order={order}
                  mobile={!desktop}
                  style={{marginBottom: !desktop ? 20 : 32}}
                />
              ))}
        </div>
        {selectedTab !== '' &&
          orders.filter(order => order.status === selectedTab).length >=
            showIndex && (
            <RectButton
              onClick={fetchNextPageOrders}
              style={{margin: '0 auto'}}
              text="查看更多"
              skin={BUTTON_SKIN.PRIMARY}
            />
          )}

        {selectedTab === '' && orders.length >= showIndex && (
          <RectButton
            onClick={fetchNextPageOrders}
            style={{margin: '0 auto'}}
            text="查看更多"
            skin={BUTTON_SKIN.PRIMARY}
          />
        )}
      </Wrapper>
    </Fragment>
  );
}

const Wrapper = styled.div`
  padding: ${props => (props.mobile ? 0 : '40px')};
  background-color: ${props =>
    props.mobile ? 'rgba(20, 20, 20, 0.06)' : 'white'};

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.27;
    margin-bottom: 12px;
  }

  & > .order-list {
    padding: ${props => (props.mobile ? '20px 0' : '32px 0')};
  }
`;

export default MyOrders;
