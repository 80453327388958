import React, {useContext} from 'react';
import styled from 'styled-components';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import {UnderlineButton} from '../../../Components/Widget';
import {Context} from '../../../Contexts/AppContext';
import * as ModalContent from '../ModalContent.favStore';
import * as L from '../../../Utils/Lang';
import {brandsDisplayArr} from '../../../Domain/Brand.js';

function FavStoreItem(props) {
  const {item, mobile, modalRef, fetchFavStores} = props;
  const app = useContext(Context);
  const {allStoreData} = app;
  const selected = !!item.storeId;

  const onSelectStore = (storeId = '') => {
    modalRef.current.open(
      <ModalContent.SelectStoreForm
        storeId={storeId}
        brand={item.brand.toLocaleLowerCase()}
        allStoreData={allStoreData}
        modalRef={modalRef}
        mobile={mobile}
        fetchFavStores={fetchFavStores}
      />,
    );
  };

  if (mobile && !selected) {
    return (
      <ItemWrapper>
        {mobile && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0px 20px',
              padding: '20px 0px',
              borderBottom: '1px solid #eee',
            }}>
            <div style={{marginRight: 10}}>
              {brandsDisplayArr.find(b => b.key === item.brand).display}
            </div>
            <div style={{flex: 1}} />
            <div style={{color: 'rgba(20, 20, 20, 0.3)'}}>尚無指定服務門市</div>
          </div>
        )}

        <RectButton
          skin={BUTTON_SKIN.PRIMARY}
          text={'選擇門市'}
          style={{margin: 20, width: 'calc(100% - 40px)'}}
          onClick={() => {
            onSelectStore('');
          }}
        />
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper>
      {mobile && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '0px 20px',
            padding: '20px 0px',
            borderBottom: '1px solid #eee',
          }}>
          <div style={{marginRight: 10}}>
            {brandsDisplayArr.find(b => b.key === item.brand).display}
          </div>
          <div style={{flex: 1}} />
          {selected ? (
            <UnderlineButton
              onClick={() => {
                onSelectStore(item.storeId);
              }}>
              更換門市
            </UnderlineButton>
          ) : (
            <RectButton
              onClick={() => {
                onSelectStore(item.storeId);
              }}
              skin={BUTTON_SKIN.PRIMARY}
              text={'選擇門市'}
            />
          )}
        </div>
      )}

      <div className="row">
        {!mobile && (
          <div
            style={{
              width: 150,
              marginRight: 20,
              borderRight: '1px solid #eee',
              flexShrink: 0,
              alignSelf: 'stretch',
              display: 'flex',
              alignItems: 'center',
            }}>
            <div>
              {brandsDisplayArr.find(b => b.key === item.brand).display}
            </div>
          </div>
        )}

        <div className="info">
          {selected ? (
            <>
              <div className="name">{L.d(item.storeName)}</div>
              <div className="addr">{L.d(item.storeAddr)}</div>
            </>
          ) : (
            <div style={{color: 'rgba(20, 20, 20, 0.3)'}}>尚無指定服務門市</div>
          )}
        </div>

        <div style={{flex: 1}} />
        {!mobile &&
          (selected ? (
            <UnderlineButton
              onClick={() => {
                onSelectStore(item.storeId);
              }}>
              更換門市
            </UnderlineButton>
          ) : (
            <RectButton
              onClick={() => {
                onSelectStore('');
              }}
              skin={BUTTON_SKIN.PRIMARY}
              text={'選擇門市'}
            />
          ))}
      </div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  border: solid 1px rgba(20, 20, 20, 0.1);
  border-radius: 2px;
  background-color: white;
  color: #141414;
  margin-bottom: 16px;

  & > .row {
    padding: 20px;
    display: flex;
    align-items: center;

    & > .info {
      & > .name {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-right: 4px;
      }

      & > .addr {
        font-size: 16px;
        line-height: 1.5;
        color: rgba(20, 20, 20, 0.7);
      }
    }
  }
`;

export default FavStoreItem;
