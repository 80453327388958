import React, {Fragment} from 'react';
import styled from 'styled-components';
import {FlexCol, FlexRow} from '../../Components/Widget';
import {getFormatPrice} from '../../Utils/ProductUtil';

function CreditHistoryTable(props) {
  const {items, isMobile} = props;

  return (
    <HistoryTableWrapper>
      {!isMobile && (
        <div className="header">
          <div className="date">日期</div>
          <div className="label">項目</div>
          <div className="credit">購物金</div>
        </div>
      )}

      {!isMobile &&
        items.map((item, idx) => (
          <div className="row" key={idx}>
            <div className="date">{item.date}</div>
            <div className="label">{item.detail}</div>
            <div
              className="credit"
              style={{
                color: item.point >= 0 ? '#cca75c' : ' rgba(20, 20, 20, 0.5)',
              }}>
              NT {getFormatPrice(item.point)}
            </div>
          </div>
        ))}

      {isMobile &&
        items.map((item, idx) => (
          <Fragment key={idx}>
            <FlexRow style={{justifyContent: 'space-between'}}>
              <FlexCol>
                <div>{item.detail}</div>
                <div style={{color: 'rgba(20,20,20,0.7)'}}>{item.date}</div>
              </FlexCol>
              <div
                style={{
                  color: item.point >= 0 ? '#cca75c' : ' rgba(20, 20, 20, 0.5)',
                  fontWeight: 'bold',
                }}>
                NT {getFormatPrice(item.point)}
              </div>
            </FlexRow>
            <div
              style={{
                width: '100%',
                height: '1px',
                background: 'rgba(20,20,20,0.3)',
                margin: '12px 0',
              }}
            />
          </Fragment>
        ))}

      {items.length <= 0 && (
        <div className="empty">
          <div>沒有任何記錄</div>
        </div>
      )}
    </HistoryTableWrapper>
  );
}

const HistoryTableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  font-size: 14px;

  & > .header {
    display: flex;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    & > .date {
      flex-shrink: 0;
      width: 160px;
    }

    & > .label {
      flex-shrink: 0;
      width: 240px;
    }

    & > .credit {
      flex-shrink: 0;
      width: 240px;
    }
  }

  & > .row {
    display: flex;
    margin-bottom: 8px;

    & > .date {
      flex-shrink: 0;
      width: 160px;
    }

    & > .label {
      flex-shrink: 0;
      width: 240px;
    }

    & > .credit {
      flex-shrink: 0;
      width: 240px;
    }
  }

  & .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 64px 0;
  }
`;

export default CreditHistoryTable;
