import React, {useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import Select from './Select';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import Modal from './Modal';
import * as ModalContent from './ModalContent.updatePhone';
import {
  getEmailValidator,
  getRequiredValidator,
  validateForm,
} from '../../Utils/validateUtil';
import {Actions, Context} from '../../Contexts/AppContext';
import Alert from '../../Components/Modal/Alert';
import {FlexCol} from '../../Components/Widget';
import Check from '../../images/illustration-check.svg';
import Time from '../../images/illustration-time.svg';
import {useDimension} from '../../Hooks/AppHooks';

function PersonalInfo(props) {
  const {desktop} = useDimension();
  const app = useContext(Context);
  const currentUser = app.currentUser;
  const modalRef = React.useRef();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = React.useState({
    phone: '',
    name: '',
    birthday: '',
    email: '',
    gender: '',
  });

  const [hasEdit, setHasEdit] = React.useState(false);

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (!currentUser) {
      return;
    }
    setValues(vals => ({
      ...vals,
      name: currentUser.name,
      phone: currentUser.phone,
      email: currentUser.email,
      birthday: currentUser.birthdate,
      gender: currentUser.sex || '',
    }));
  }, [currentUser]);

  const getValue = field => values[field];
  const getError = field => errors[field];

  const onValueChange = field => e => {
    !hasEdit && setHasEdit(true);
    setValues({...values, [field]: e.target.value});
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const isAllPass = validateForm({values, errors, setErrors, validators});
      if (!isAllPass) {
        return;
      }

      const _post = {
        birthdate: currentUser.birthday,
        email: values.email,
        sex: values.gender,
        name: values.name,
        phone: values.phone,
      };

      const result = await Actions.updateCustomer(_post);

      await Actions.syncPos();

      if (!result.success) {
        throw new Error('update personal info failed');
        return;
      }

      Actions.setGlobalModalContent(
        <Alert
          onClick={async () => {
            await Actions.fetchMe();
            Actions.setGlobalModalContent(null);
          }}
          cancelLabel="確定"
          icon={<img src={Check} alt="" />}
          title="個人資料更新成功"
        />,
      );
    } catch (e) {
      Actions.setGlobalModalContent(
        <Alert
          onClick={() => {
            Actions.setGlobalModalContent(null);
            window.location.reload();
          }}
          cancelLabel="確定"
          icon={<img src={Time} alt="" />}
          title="個人資料更新失敗"
          subTitle="請稍後再試"
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  const validators = useMemo(
    () => ({
      name: getRequiredValidator(),
      email: getEmailValidator(),
    }),
    [],
  );

  const onValidate = field => e => {
    setErrors({
      ...errors,
      [field]: validators[field].passRequest(values[field]),
    });
  };

  function openAskPasswordModal() {
    modalRef.current.open(
      <ModalContent.AskPasswordModalContent
        mobile={!desktop}
        onClose={() => modalRef.current.close()}
        onConfirm={openUpdatePhoneModal}
      />,
    );
  }

  function openUpdatePhoneModal() {
    modalRef.current.open(
      <ModalContent.UpdatePhoneModalContent
        mobile={!desktop}
        onClose={() => modalRef.current.close()}
        onBack={openAskPasswordModal}
        onConfirm={openOtpModal}
      />,
    );
  }

  function openOtpModal(phone) {
    modalRef.current.open(
      <ModalContent.OtpModalContent
        mobile={!desktop}
        onClose={() => modalRef.current.close()}
        phone={phone}
        onBack={openUpdatePhoneModal}
        onConfirm={openSuccessModal}
      />,
    );
  }

  function openSuccessModal() {
    modalRef.current.open(
      <ModalContent.SuccessModalContent
        mobile={!desktop}
        onConfirm={() => {
          modalRef.current.close();
          window.location.reload();
        }}
      />,
    );
  }

  const _formattedPhone = React.useMemo(() => {
    return values.phone.replace(/^([0-9]{4})([0-9]{3})([0-9]{3})$/, '$1 $2 $3');
  }, [values.phone]);

  return (
    <>
      <Wrapper>
        <h2>個人資料</h2>
        <p style={{marginBottom: 32}}>管理您的資料，以確保您的會員權益</p>

        <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
          <TextInput
            label="手機號碼"
            value={_formattedPhone}
            style={{marginRight: 8, width: 120}}
            disabled
          />
          <div
            onClick={openAskPasswordModal}
            style={{
              textDecoration: 'underline',
              color: '#cca75c',
              cursor: 'pointer',
              transform: 'translateY(2px)',
            }}>
            變更
          </div>
        </div>

        <div style={{maxWidth: desktop ? 600 : 'unset'}}>
          <TextInput
            label="姓名"
            value={getValue('name')}
            onChange={onValueChange('name')}
            onBlur={onValidate('name')}
            error={getError('name')}
            style={{marginBottom: 24}}
          />

          <FlexCol style={{marginBottom: 37}}>
            <div
              style={{
                color: 'rgba(20, 20, 20, 0.3)',
                fontSize: '12px',
                marginBottom: '8px',
              }}>
              生日
            </div>
            <div>{getValue('birthday')}</div>
          </FlexCol>

          <TextInput
            label="電子信箱"
            value={getValue('email')}
            onChange={onValueChange('email')}
            onBlur={onValidate('email')}
            error={getError('email')}
            style={{marginBottom: 24}}
          />

          <Select
            label="性別"
            options={[
              {value: 'M', display: '男'},
              {value: 'F', display: '女'},
              {value: 'N', display: '不提供'},
            ]}
            value={getValue('gender')}
            onChange={onValueChange('gender')}
            style={{marginBottom: 24}}
          />
        </div>

        <RectButton
          isLoading={loading}
          isDisabled={!hasEdit}
          style={!desktop ? {width: '100%'} : {width: 200}}
          onClick={onSubmit}
          text="儲存變更"
          skin={BUTTON_SKIN.PRIMARY}
        />
      </Wrapper>

      <Modal
        mobile={!desktop}
        getInstance={inst => {
          modalRef.current = inst;
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  padding: 40px;

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.27;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default PersonalInfo;
