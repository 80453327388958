import React, {Fragment, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Box, Pad, Provider, Sep, Txt} from './BoxUI';
import CreditHistoryTable from './CreditHistoryTable';
import CreditRules from './CreditRules';
import {getFormatPrice} from '../../Utils/ProductUtil';
import RectButton, {
  BUTTON_SIZE,
  BUTTON_SKIN,
} from '../../Components/RectButton';
import {useDimension} from '../../Hooks/AppHooks';
import {
  Actions,
  Breakpoints as breakpoints,
  Context,
  NavActions,
} from '../../Contexts/AppContext';
import {summaryOfPoints} from '../../Utils/appLogic';
import {CREDIT_TYPE} from '../../Domain/Checkout';
import {dateDiffNow} from '../../Utils/DateUtil';
import {Color} from '../../Components/Widget';

const UiState = {
  CREDITS: 0,
  RULES: 1,
};

function MyCredits() {
  const app = useContext(Context);
  const {rebate_points = [], birth_gift_points = []} = app.currentUser || {};
  const [uiState, setUiState] = React.useState(UiState.CREDITS);
  const {desktop} = useDimension();
  const [pointHistory, setPointHistory] = useState([]);
  const totalCreditList = [...rebate_points, ...birth_gift_points];
  const totalCredits = summaryOfPoints(totalCreditList);
  const birthGiftTotalCredits = summaryOfPoints(birth_gift_points);
  const rebateTotalCredits = summaryOfPoints(rebate_points);

  useEffect(() => {
    const fetchPointHistory = async () => {
      Actions.setLoading(true);
      setPointHistory((await Actions.fetchPointHistory()) || []);
      Actions.setLoading(false);
    };
    fetchPointHistory();
  }, []);

  return (
    <Provider>
      {uiState === UiState.RULES && (
        <CreditRules
          isMobile={!desktop}
          goBack={() => setUiState(UiState.CREDITS)}
        />
      )}

      {uiState === UiState.CREDITS && (
        <Wrapper>
          <Box row>
            <Txt.XXL style={{fontWeight: 'bold'}}>購物金</Txt.XXL>
            <Pad.M row />
            <Txt.M
              onClick={() => setUiState(UiState.RULES)}
              style={{
                color: 'rgba(20, 20, 20, 0.7)',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}>
              規則說明
            </Txt.M>
          </Box>

          <Pad.XXL />

          <Box.XL style={{backgroundColor: 'rgba(20, 20, 20, 0.02)'}}>
            <Txt.XL>NT {getFormatPrice(totalCredits)}</Txt.XL>
            <Sep.L
              style={{height: 1, backgroundColor: 'rgba(20, 20, 20, 0.1)'}}
            />
            {totalCredits > 0 && (
              <Fragment>
                {birthGiftTotalCredits !== 0 &&
                  birth_gift_points.map((item, idx) => (
                    <Fragment key={idx}>
                      <CreditItem
                        item={{...item, type: CREDIT_TYPE.birthGift}}
                      />
                      <Pad.M />
                    </Fragment>
                  ))}
                {rebateTotalCredits !== 0 &&
                  rebate_points.map((item, idx) => (
                    <Fragment key={idx}>
                      <CreditItem item={{...item, type: CREDIT_TYPE.rebate}} />
                      <Pad.M />
                    </Fragment>
                  ))}
                <Txt.S style={{color: 'rgba(20, 20, 20, 0.5)'}}>
                  使用「生日禮金」折抵，金額需為 NT$ 500
                  的倍數；使用「會員回饋」折抵，金額需為 NT$ 1,000 的倍數
                </Txt.S>
              </Fragment>
            )}
            {totalCredits <= 0 && (
              <Fragment>
                <Txt.M>開始購物，獲取會員回饋</Txt.M>
                <Pad.M />
                <RectButton
                  skin={BUTTON_SKIN.PRIMARY}
                  size={BUTTON_SIZE.LARGE}
                  onClick={() => {
                    NavActions.navigate('/home');
                  }}
                  text="前往購物"
                />
              </Fragment>
            )}
          </Box.XL>

          <Sep.XL>
            <Txt.L>歷史紀錄</Txt.L>
          </Sep.XL>

          <CreditHistoryTable isMobile={!desktop} items={pointHistory} />
        </Wrapper>
      )}
    </Provider>
  );
}

const Wrapper = styled(Box.XXL)``;

function CreditItem(props) {
  const {item, style = {}} = props;
  const {type, point, expiration_date} = item;
  const isReadyExpired = dateDiffNow(expiration_date, 'days') <= 30;

  return (
    <CreditItemWrapper style={style}>
      <Box.S style={{border: `1px solid ${Color.gold}`}}>
        <Txt.S style={{color: '#cca75c', whiteSpace: 'nowrap'}}>
          {type === CREDIT_TYPE.rebate ? '會員回饋' : '生日禮金'}
        </Txt.S>
      </Box.S>
      <Pad.M row />
      <Txt.S style={{whiteSpace: 'nowrap'}}>NT {getFormatPrice(point)}</Txt.S>
      <Sep.M
        row
        style={{
          width: 1,
          height: 12,
          backgroundColor: 'rgba(20, 20, 20, 0.1)',
        }}
      />
      <Txt.S style={{color: isReadyExpired ? Color.red : Color.mainDark}}>
        使用期限：{expiration_date}
      </Txt.S>
    </CreditItemWrapper>
  );
}

const CreditItemWrapper = styled(Box).attrs({row: true})`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default MyCredits;
