import {useCallback, useEffect, useState} from 'react';
import {Actions} from '../Contexts/AppContext';

const useOrderManager = ({orderId}) => {
  const [order, setOrder] = useState(null);
  const fetchOrder = useCallback(async () => {
    Actions.setLoading(true);
    try {
      setOrder(await Actions.fetchOrder(orderId));
    } catch (ex) {
      console.warn('fetchOrder fail', ex);
    }
    Actions.setLoading(false);
  }, [orderId]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return {
    order,
  };
};

export default useOrderManager;
