import React from 'react';
import styled from 'styled-components';
import {Color} from '../../../Components/Widget';
import {Breakpoints} from '../../../Contexts/AppContext';

export const ServiceItem = ({imgUrl = '', subtitle = '', title = ''}) => (
  <Wrapper>
    <img src={imgUrl} alt="service-img" />
    <div className="content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  & > img {
    width: 480px;
    aspect-ratio: 480 / 270;
  }
  & > .content {
    width: 100%;
    max-width: calc(656px);
    min-width: calc(543px);
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 24px 32px;
    right: 0;
    bottom: 0;
    background-color: ${Color.mainWhite};
    & > .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    & > .subtitle {
      font-size: 14px;
      color: ${Color.mainDark_50};
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding-bottom: 88px;
  }
  @media screen and (max-width: ${Breakpoints.mobile}px) {
    flex-direction: column;
    padding: 0;
    margin-bottom: 32px;
    & > img {
      width: 100%;
      margin-bottom: 12px;
    }
    & > .content {
      padding: 0;
      position: relative;
      min-width: unset;
    }
  }
`;
