import React, {useRef} from 'react';
import styled from 'styled-components';
import AddrItem from './AddrItem';
import {Color, FlexCol, FlexRow} from '../../../Components/Widget';
import Overlay from '../Overlay';
import {
  openConfirmRemoveModal,
  openConfirmSetPrimaryAddressModal,
  useAddressManager,
} from '../../../Hooks/useAddressManager';
import {AddCvsStoreButton} from '../../../Components/Address/AddCvsStoreButton';
import {AddAddressButton} from '../../../Components/Address/AddAddressButton';
import {useDimension} from '../../../Hooks/AppHooks';

function AddrManagement(props) {
  const {desktop} = useDimension();
  const overlayRef = useRef();
  const {addrs, fetchAddress} = useAddressManager({});

  return (
    <Wrapper>
      {desktop && <h2>常用地址</h2>}
      <div style={{marginTop: 32, maxWidth: 900}}>
        {addrs.map((addr, idx) => (
          <AddrItem
            key={idx}
            item={addr}
            fetchAddress={fetchAddress}
            mobile={!desktop}
            overlayRef={overlayRef}
          />
        ))}
      </div>

      {!desktop ? (
        <>
          {addrs.length <= 0 && (
            <div
              style={{
                color: Color.mainDark_70,
                margin: '16px 0',
                textAlign: 'center',
              }}>
              尚無常用地址
              <br />
              立即新增讓結帳更快速！
            </div>
          )}
          <AddAddressButton
            style={{
              marginRight: 0,
              marginBottom: 16,
              width: '100%',
            }}
            fetchAddress={fetchAddress}
          />
          <AddCvsStoreButton
            fetchAddress={fetchAddress}
            style={{marginBottom: 16, width: '100%'}}
          />
        </>
      ) : (
        <FlexCol>
          {addrs.length <= 0 && (
            <div
              style={{
                color: Color.mainDark_70,
                margin: '16px 0',
              }}>
              尚無常用地址，立即新增讓結帳更快速！
            </div>
          )}
          <FlexRow>
            <AddAddressButton fetchAddress={fetchAddress} />
            <AddCvsStoreButton fetchAddress={fetchAddress} />
          </FlexRow>
        </FlexCol>
      )}

      <Overlay
        mobile={!desktop}
        getInstance={inst => {
          overlayRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px;

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.27;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default AddrManagement;
