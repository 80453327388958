import {
  Color,
  FlexColCenter,
  FlexRowCenter,
} from '../../../../Components/Widget';
import React from 'react';
import styled from 'styled-components';
import {
  RETURN_INVOICE_STATUS,
  RETURN_INVOICE_STATUS_DISPLAY,
} from '../../../../Domain/Return';
import * as L from '../../../../Utils/Lang';
import {getDateString} from '../../../../Utils/DateUtil';

export const InvoiceHandle = props => {
  const {returnInvoiceStatus, returnInvoiceStatusHistory} = props;
  return (
    <InvoiceHandleWrapper>
      <FlexColCenter className="done">
        <strong>
          {L.d(RETURN_INVOICE_STATUS_DISPLAY[returnInvoiceStatus])}
        </strong>
        <small>
          {returnInvoiceStatus === RETURN_INVOICE_STATUS.issued &&
            getDateString(
              returnInvoiceStatusHistory[`${RETURN_INVOICE_STATUS.issued}_at`],
            )}
          {returnInvoiceStatus === RETURN_INVOICE_STATUS.allowance &&
            getDateString(
              returnInvoiceStatusHistory[
                `${RETURN_INVOICE_STATUS.allowance}_at`
              ],
            )}
          {returnInvoiceStatus === RETURN_INVOICE_STATUS.allowance_failed &&
            getDateString(
              returnInvoiceStatusHistory[`${RETURN_INVOICE_STATUS.issued}_at`],
            )}
        </small>
      </FlexColCenter>
    </InvoiceHandleWrapper>
  );
};

const InvoiceHandleWrapper = styled(FlexRowCenter)`
  padding: 20px 0;
  border-top: 1px solid ${Color.mainDark_10};
  border-bottom: 1px solid ${Color.mainDark_10};
  & > .done {
    & > strong {
      font-size: 14px;
      font-weight: 500;
      color: ${Color.mainDark};
    }
    & > small {
      font-size: 14px;
      font-weight: 300;
      color: ${Color.mainDark_70};
    }
  }
`;
