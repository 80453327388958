import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {
  Actions,
  Breakpoints,
  Context,
  NavActions,
} from '../../../Contexts/AppContext';
import {MENU} from '../MemberCenterMenu';
import {ChevronLeft} from '../../../Components/SvgIcon';
import MaintainRecordItem from './MaintainRecordItem';
import CustomNav from '../../../Components/CustomNav';
import {useDimension} from '../../../Hooks/AppHooks';
import {Color, UnderlineButton} from '../../../Components/Widget';
import {getFormatPrice} from '../../../Utils/ProductUtil';
import qs from 'query-string';
import * as L from '../../../Utils/Lang';
import {brandIdToBrandName} from '../../../Domain/Brand';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import {MAINTAIN_STATUS} from '../../../Domain/Maintain';
import Confirm from '../../../Components/Modal/Confirm';
import OrderFill from '../../../images/illustration-order-fill.svg';
import {getRequiredValidator, validateForm} from '../../../Utils/validateUtil';
import TextInput from '../../../Components/TextInput';

function MaintainConcernForm(props) {
  const maxLength = 200;
  const {record} = props;
  const [values, setValues] = useState({
    disagreementNote: '',
  });
  const [errors, setErrors] = useState({
    disagreementNote: null,
  });
  const validators = useMemo(
    () => ({
      disagreementNote: getRequiredValidator(),
    }),
    [],
  );

  const onChange = e => {
    const {value} = e.target;
    setValues(prev => ({
      ...prev,
      disagreementNote: value,
    }));

    let nextError = {
      disagreementNote: null,
    };

    if (value.length >= maxLength) {
      nextError.disagreementNote = `${value.length}/${maxLength} 超出字數限制`;
    }

    setErrors(prev => ({
      ...prev,
      ...nextError,
    }));
  };

  const onSubmit = async () => {
    try {
      Actions.setLoading(true);

      const isAllPass = validateForm({values, errors, setErrors, validators});
      if (!isAllPass) {
        return;
      }
      const payload = {
        id: record.id,
        agreement: 'disagree',
      };
      const response = await Actions.putMaintainOrderQuotation(payload);
      console.log('debug', response);
      Actions.setGlobalModalContent(null);
    } catch (e) {
      alert(e.message);
    } finally {
      Actions.setLoading(false);
    }
  };

  return (
    <Confirm
      onClick={onSubmit}
      title="對報價有疑慮嗎？"
      subTitle="請寫下您的想法，客服人員將會與您聯繫"
      confirmLabel="確定送出"
      cancelLabel="取消"
      icon={<img src={OrderFill} alt="order fill" />}
      children={
        <TextInput
          maxLength={maxLength}
          styleCss={{width: '100%', margin: '10px 0'}}
          label={`關於報價的想法 ( 限 ${maxLength} 字 ）`}
          name="disagreementNote"
          value={values.disagreementNote}
          type="text"
          onChange={onChange}
          error={errors.disagreementNote}
        />
      }
    />
  );
}

// {
//   "id": 1,
//     "status": "waiting",
//     "quotation": null,
//     "customer_agree": false,
//     "brand_id": 1,
//     "customer_id": 10,
//     "store_id": 19,
//     "receiver_name": "Guy",
//     "receiver_phone": "0912341234",
//     "customer_disagree_reason": "",
//     "number": "MT21110118254610",
//     "status_history": {
//   "waiting": "2021-11-01T18:25:46.392+08:00",
//       "canceled": null,
//       "estimating": null,
//       "refused": null,
//       "estimated": null,
//       "maintaining": null,
//       "retrieved": null,
//       "done": null
// }
// }

const MaintainDetail = props => {
  const queryParams = qs.parse(props.location.search);
  const {id = ''} = queryParams;
  const app = useContext(Context);
  const {mobile, tablet} = useDimension();
  const [record, setRecord] = useState(null);

  useEffect(() => {
    const _fetch = async () => {
      try {
        Actions.setLoading(true);
        const resp = await Actions.getMaintainOrder(id);
        console.log('debug', resp);
        setRecord(resp);
      } catch (e) {
        console.log('debug', e);
      } finally {
        Actions.setLoading(false);
      }
    };
    _fetch();
  }, [id]);

  if (!record) {
    return null;
  }

  const onAgree = () => {
    Actions.setGlobalModalContent(
      <Confirm
        onClick={async () => {
          try {
            Actions.setLoading(true);
            const payload = {
              id: record.id,
              agreement: 'agree',
            };
            const response = await Actions.putMaintainOrderQuotation(payload);
            console.log('debug', response);
            Actions.setGlobalModalContent(null);
          } catch (e) {
            alert(e.message);
          } finally {
            Actions.setLoading(false);
          }
        }}
        title="確定同意此報價嗎？"
        subTitle="同意後即開始維修/保養您的商品，待商品送回門市，TUN 會以簡訊及電子信箱通知您前來領取"
        confirmLabel="確定"
        cancelLabel="取消"
        icon={<img src={OrderFill} alt="order fill" />}
      />,
    );
  };

  const onReject = () => {
    Actions.setGlobalModalContent(<MaintainConcernForm record={record} />);
  };

  const onCancel = () => {
    Actions.setGlobalModalContent(
      <Confirm
        onClick={async () => {
          try {
            Actions.setLoading(true);
            const payload = {
              id: record.id,
            };
            const response = await Actions.cancelMaintainOrder(payload);
            console.log('debug', response);
            Actions.setGlobalModalContent(null);
          } catch (e) {
            alert(e.message);
          } finally {
            Actions.setLoading(false);
          }
        }}
        title="確定要取消申請維修服務嗎？"
        confirmLabel="確定"
        cancelLabel="取消"
        icon={<img src={OrderFill} alt="order fill" />}
      />,
    );
  };

  const getOrderedSections = _status => {
    let orders = [1, 2, 3];
    if (
      _status === MAINTAIN_STATUS.maintaining ||
      _status === MAINTAIN_STATUS.retrieved ||
      _status === MAINTAIN_STATUS.done
    ) {
      orders = [3, 1, 2];
    }
    const sections = [
      {
        content: (
          <section className="price-detail">
            <div className="title">{orders[0]}. 價格明細</div>
            <div className="content">
              <div className="item">
                <div className="label">Lorem.</div>
                <div className="price">{getFormatPrice(1000)}</div>
              </div>
              <div className="separate" />
              <div className="item">
                <div className="label" style={{fontWeight: 500}}>
                  總金額
                </div>
                <div
                  className="price"
                  style={{fontWeight: 'bold', fontSize: 20}}>
                  {getFormatPrice(1000)}
                </div>
              </div>
              {record.status === MAINTAIN_STATUS.estimated && (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <RectButton
                    style={{width: '48%'}}
                    text="拒絕此報價"
                    onClick={onReject}
                    skin={BUTTON_SKIN.DEFAULT}
                  />
                  <RectButton
                    style={{width: '48%'}}
                    text="同意此報價"
                    onClick={onAgree}
                    skin={BUTTON_SKIN.PRIMARY}
                  />
                </div>
              )}
            </div>
            {record.status === MAINTAIN_STATUS.refused && (
              <div
                style={{
                  margin: '10px 0',
                  padding: 15,
                  backgroundColor: Color.lightRed,
                  color: Color.red,
                  textAlign: 'center',
                }}>
                您已對此報價提出回覆，客服人員會盡快與您聯繫，感謝您的耐心等候
              </div>
            )}
          </section>
        ),
      },
      {
        content: (
          <section className="apply-status">
            <div className="title">{orders[1]}. 申請狀態</div>
            <MaintainRecordItem record={record} enableDetailAction={false} />
          </section>
        ),
      },
      {
        content: (
          <section className="apply-info">
            <div className="title">{orders[2]}. 申請資訊</div>
            <div className="content">
              <div className="item">
                <div className="label">服務項目.</div>
                <div className="description">Lorem ipsum dolor.</div>
              </div>
              <div className="item">
                <div className="label">詳細說明.</div>
                <div className="description">Lorem ipsum dolor.</div>
              </div>
              <div className="item">
                <div className="label">使用習慣.</div>
                <div className="description">Lorem ipsum dolor.</div>
              </div>
              <div className="item">
                <div className="label">指定服務門市.</div>
                <div className="description">
                  {L.d(
                    app.allStoreData?.[
                      brandIdToBrandName[record.brand_id]
                    ]?.find(s => s.id === record.store_id)?.name,
                  )}
                </div>
              </div>
            </div>
          </section>
        ),
      },
    ];

    return _status === MAINTAIN_STATUS.maintaining ||
      _status === MAINTAIN_STATUS.retrieved ||
      _status === MAINTAIN_STATUS.done
      ? [sections[1], sections[2], sections[0]]
      : [sections[0], sections[1], sections[2]];
  };

  return (
    <Fragment>
      {(mobile || tablet) && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate(`/profile?active=${MENU.maintain}`);
              }}
            />
          }
          middle={<div>維修訂單明細</div>}
        />
      )}
      <Wrapper>
        <header style={{marginBottom: 10}}>
          <div
            className="back"
            onClick={() => {
              NavActions.navigate(`/profile?active=${MENU.maintain}`);
            }}>
            <ChevronLeft />
            <div>返回</div>
          </div>
        </header>

        {getOrderedSections(record.status)[0].content}
        <div className="separate" />
        {getOrderedSections(record.status)[1].content}

        <div className="separate" />
        {getOrderedSections(record.status)[2].content}

        <>
          <div className="separate" />
          <section
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 0,
            }}>
            <UnderlineButton onClick={onCancel}>取消訂單</UnderlineButton>
          </section>
        </>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 970px;
  & > header {
    & > .back {
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 300;
    }
  }
  & > section {
    margin-bottom: 32px;
    & > .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  & > section.apply-status {
    margin-top: 24px;
  }
  & > section.apply-info {
    flex: 1;
    & > .content {
      flex: 1;
      border: 1px solid ${Color.mainDark_10};
      padding: 24px 32px;
      & > .item {
        display: flex;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 8px;
        & > .label {
          min-width: 120px;
          color: ${Color.mainDark_70};
        }
        & > .description {
          flex: 1;
        }
        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  & > section.price-detail {
    max-width: 518px;
    & > .content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: ${Color.mainDark_03};
      & > .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > .label {
          font-size: 14px;
          font-weight: 300;
          color: ${Color.mainDark_70};
        }
        & > .price {
          font-size: 14px;
          font-weight: 500;
        }
      }
      & > .separate {
        height: 1px;
        background-color: ${Color.mainDark_10};
        margin: 20px 0;
      }
    }
  }

  & > .separate {
    height: 12px;
    background-color: ${Color.mainDark_03};
    display: none;
  }
  @media screen and (max-width: ${Breakpoints.tablet}px) {
    & > header {
      display: none;
    }
    & > section {
      padding: 32px 126px;
      & > .title {
      }
    }
    & > section.apply-status {
    }
    & > section.apply-info {
    }

    & > section.price-detail {
      max-width: unset;
      & > .content {
        background-color: unset;
        padding: unset;
        & > .item {
          & > .label {
          }
          & > .price {
          }
        }
        & > .separate {
        }
      }
    }
    & > .separate {
      display: block;
    }
  }
  @media screen and (max-width: ${Breakpoints.mobile}px) {
    & > section {
      padding: 20px;
      & > .title {
      }
    }
    & > section.apply-status {
    }
    & > section.apply-info {
    }
  }
`;

export default MaintainDetail;
