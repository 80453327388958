import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {
  MEMBERSHIP_LEVEL,
  MEMBERSHIP_LEVEL_DISPLAY,
} from '../../../Domain/Member';
import {useDimension} from '../../../Hooks/AppHooks';
import MemberBasic from '../../../images/icon-member-basic.png';
import MemberSliver from '../../../images/icon-member-silver.png';
import MemberGold from '../../../images/icon-member-gold.png';
import MemberDiamonds from '../../../images/icon-member-diamond.png';
import MemberLevelTable from './MemberLevelTable';
import MemberLevelContent from './MemberLevelContent';
import {Actions, Context} from '../../../Contexts/AppContext';

const MemberLevelData = [
  {
    key: MEMBERSHIP_LEVEL['M'],
    display: MEMBERSHIP_LEVEL_DISPLAY[MEMBERSHIP_LEVEL['M']].zh,
    icon: MemberBasic,
  },
  {
    key: MEMBERSHIP_LEVEL['F'],
    display: MEMBERSHIP_LEVEL_DISPLAY[MEMBERSHIP_LEVEL['F']].zh,
    icon: MemberSliver,
  },
  {
    key: MEMBERSHIP_LEVEL['G'],
    display: MEMBERSHIP_LEVEL_DISPLAY[MEMBERSHIP_LEVEL['G']].zh,
    icon: MemberGold,
  },
  {
    key: MEMBERSHIP_LEVEL['D'],
    display: MEMBERSHIP_LEVEL_DISPLAY[MEMBERSHIP_LEVEL['D']].zh,
    icon: MemberDiamonds,
  },
];

const MemberLevel = props => {
  const {isLogin} = props;
  const {desktop} = useDimension();
  const app = useContext(Context);
  const user = app.currentUser;
  const [orderHistories, setOrderHistories] = useState(null);

  const [memberLevel, setMemberLevel] = React.useState(MEMBERSHIP_LEVEL.M);
  const [selectedLevel, setSelectedLevel] = React.useState(MEMBERSHIP_LEVEL.M);

  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchOrderHistories = async () => {
      const resp = await Actions.fetchOrderHistories();
      setOrderHistories(resp);
    };
    fetchOrderHistories().then(() => 0);
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setMemberLevel(user.vip_level);
    setSelectedLevel(user.vip_level);
  }, [user]);

  let findMemberLevelIndex = MemberLevelData.findIndex(
    level => level.key === memberLevel,
  );
  let findSelectedLevelIndex = MemberLevelData.findIndex(
    level => level.key === selectedLevel,
  );

  if (!isLogin) {
    return <MemberLevelTable isLogin={isLogin} desktop={desktop} />;
  }

  return (
    <Wrapper memberLevel={findMemberLevelIndex} desktop={desktop}>
      {desktop && (
        <div className="title-container">
          <h3>會員等級 / 權益</h3>
        </div>
      )}

      <div className={`hint-container ${desktop ? '' : 'need-padding-top'}`}>
        <div className="members">
          {MemberLevelData.map((level, index) => {
            let hitMemberLevel = level.key === memberLevel;
            let hitSelectedLevel = level.key === selectedLevel;
            return (
              <div className="item" key={`level-${index}`}>
                <div
                  className={`need-small-text need-${level.key}-color`}
                  style={{
                    minHeight: 20,
                    marginBottom: 2,
                    minWidth: 40,
                    textAlign: 'center',
                  }}>
                  {hitMemberLevel && '目前等級'}
                </div>
                <div
                  className={`${hitSelectedLevel && 'need-big-text'} need-${
                    level.key
                  }-color`}
                  style={{
                    lineHeight: '24px',
                    minWidth: 48,
                    textAlign: 'center',
                  }}>
                  {index > findMemberLevelIndex ? (
                    <Tooltip title="查看升等資訊">{level.display}</Tooltip>
                  ) : (
                    level.display
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="card-container">
        <div className="members">
          {MemberLevelData.map((level, index) => {
            let hitSelectedLevel = level.key === selectedLevel;
            return (
              <div
                key={`level-${index}`}
                className={`item ${hitSelectedLevel && 'need-box-shadow'}`}
                onClick={() => setSelectedLevel(level.key)}>
                <img
                  src={level.icon}
                  alt="member"
                  style={{width: 32, height: 32}}
                />
              </div>
            );
          })}
        </div>
        <div className="line">
          <div className="progress-line" />
        </div>
      </div>

      <div className="arrows-container">
        {MemberLevelData.map((level, index) => (
          <div className="triangle-container" key={`level-${index}`}>
            <div
              className={`triangle ${selectedLevel !== level.key && 'hidden'}`}
            />
          </div>
        ))}
      </div>

      <div className="content-container">
        <MemberLevelContent
          user={user}
          orderHistories={orderHistories}
          isLogin={isLogin}
          desktop={desktop}
          findMemberLevelIndex={findMemberLevelIndex}
          findSelectedLevelIndex={findSelectedLevelIndex}
          selectedLevel={selectedLevel}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 32px;

  & > .title-container {
    margin-bottom: 40px;

    & > h3 {
      margin: 0px 0px 32px 0px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
    }
  }

  & > .hint-container {
    padding: 0px 20px 0px 20px;
    position: relative;

    & > .members {
      width: 100%;
      position: relative;
      z-index: 8;

      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .item {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
        color: rgba(20, 20, 20, 0.5);
        text-align: center;
      }

      .need-big-text {
        font-size: 20px;
      }

      .need-small-text {
        font-size: 12px;
      }

      .need-F-color {
        color: #9da6b3;
      }

      .need-G-color {
        color: #cca75c;
      }

      .need-D-color {
        color: #141414;
      }
    }
  }

  & > .card-container {
    padding: 6px 28px 14px 28px;
    position: relative;

    & > .members {
      width: 100%;
      position: relative;
      z-index: 8;

      display: flex;
      justify-content: space-between;

      & > .item {
        background-color: #ffffff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;

        :hover {
          box-shadow: 0 0 12px 0 rgba(20, 20, 20, 0.2);
        }
      }

      .need-box-shadow {
        box-shadow: 0 0 12px 0 rgba(20, 20, 20, 0.2);
      }
    }

    & > .line {
      background-color: rgba(20, 20, 20, 0.1);
      width: calc(100% - 56px);
      height: 4px;
      position: absolute;
      z-index: 1;
      transform: translateY(-450%);

      & > .progress-line {
        width: ${props => props.memberLevel * 33.3}%;
        height: 4px;
        background-color: black;
      }
    }
  }

  & > .arrows-container {
    padding: 0px 28px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    width: 100%;

    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    & > .triangle-container {
      padding: 0px 6px 0px 6px;
      width: 32px;
      height: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      position: relative;
      z-index: 10;

      & > .triangle {
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0px 15px 15px 15px;
        border-color: transparent transparent rgba(20, 20, 20, 0.1) transparent;
      }

      & > .triangle:after {
        width: 0px;
        height: 0px;
        content: '';
        border-style: solid;
        border-width: 0px 14px 15px 14px;
        border-color: transparent transparent white transparent;

        position: absolute;
        bottom: -1px;
        transform: translateX(-50%);
      }

      & > .hidden {
        visibility: hidden;
      }
    }
  }

  & > .content-container {
    border-left: ${props =>
      props.desktop ? '1px solid rgba(20, 20, 20, 0.1)' : '0px'};
    border-right: ${props =>
      props.desktop ? '1px solid rgba(20, 20, 20, 0.1)' : '0px'};
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    padding: ${props => (props.desktop ? '32px' : '20px')};
    margin-bottom: 24px;
  }

  .need-padding-top {
    padding-top: 20px;
  }
`;

export default MemberLevel;
