import React, {Fragment} from 'react';
import styled from 'styled-components';

import {brandsObj} from '../../../Domain/Brand';
import {MEMBERSHIP_CONFIG} from '../../../Domain/Member';
import {getFormatPrice} from '../../../Utils/ProductUtil';
import Diamond from '../../../../static/images/TUN_vipcard_鑽卡.jpg';
import Gold from '../../../../static/images/TUN_vipcard_金卡.jpg';
import Sliver from '../../../../static/images/TUN_vipcard_銀卡.jpg';
import * as SvgIcon from '../../../Components/SvgIcon';
import {NavActions} from '../../../Contexts/AppContext';

function MemberLevelTable(props) {
  const {isLogin, desktop} = props;
  const [expand, setExpand] = React.useState(null);

  if (!desktop) {
    return (
      <SmallDeviceWrapper isLogin={isLogin}>
        {!isLogin && (
          <div className="title-container">
            <div>立即登入/註冊</div>
            <div>查看您目前的會員等級與升等資訊！</div>
            <button
              onClick={() => {
                NavActions.navigate('/login');
              }}>
              登入/註冊
            </button>
          </div>
        )}

        {MemberLevelDataByRWD.map((element, index) => {
          if (isLogin) {
            return (
              <Fragment key={`rwd-element-${index}`}>
                <div className="card-container">
                  <div className="header-cell">
                    <img alt="card" src={element.card} />
                    <div
                      className={`card-black-text-base need-${element.key}-color need-text-bold`}>
                      {element.label}
                    </div>
                    <div className="card-black-text-base">單筆消費滿</div>
                    <div className="card-black-text-highlight">
                      {element.consume}
                    </div>
                  </div>
                  <button
                    className="button-container"
                    onClick={() =>
                      expand === index ? setExpand(null) : setExpand(index)
                    }>
                    看{element.name}
                  </button>
                  <div className="content-cell">
                    {expand === index && (
                      <div className={`content-container`}>
                        {element.items.map((item, idx) => (
                          <div className="item" key={`rwd-element-item-${idx}`}>
                            <div className="label">{item.title}</div>
                            <div className="row-container">
                              <span className="text">{item.label_before}</span>
                              <span className="text-gold">{item.text}</span>
                              <span clasName="text">{item.label_after}</span>
                            </div>
                            {item.children?.map((child, i) => (
                              <div
                                className="child"
                                key={`red-element-item-children-${i}`}>
                                <span className="text">{child.brand} 享</span>
                                <span className="text-gold">{child.text}</span>
                                <span className="text">優惠</span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            );
          }
          return (
            <Fragment key={`rwd-element-${index}`}>
              <div className="gray-container"></div>
              <div className="card-container">
                <div className="header-cell">
                  <img alt="card" src={element.card} />
                  <div
                    className={`card-black-text-base need-${element.key}-color need-text-bold`}>
                    {element.label}
                  </div>
                  <div className="card-black-text-base">單筆消費滿</div>
                  <div className="card-black-text-highlight">
                    {element.consume}
                  </div>
                </div>
                <div className="content-cell">
                  <div
                    className={`title-container ${
                      expand === index ? '' : 'need-border-bottom'
                    }`}
                    onClick={() =>
                      expand === index ? setExpand(null) : setExpand(index)
                    }>
                    <div>{element.name}</div>
                    <div>
                      {expand === index ? (
                        <SvgIcon.Minus size={24} />
                      ) : (
                        <SvgIcon.Plus size={24} />
                      )}
                    </div>
                  </div>
                  {expand === index && (
                    <div
                      className={`content-container ${
                        expand === index ? 'need-border-bottom' : ''
                      }`}>
                      {element.items.map((item, index) => (
                        <div className="item" key={`rwd-element-item-${index}`}>
                          <div className="label">{item.title}</div>
                          <div className="row-container">
                            <span className="text">{item.label_before}</span>
                            <span className="text-gold">{item.text}</span>
                            <span clasName="text">{item.label_after}</span>
                          </div>
                          {item.children?.map((child, idx) => (
                            <div
                              className="child"
                              key={`rwd-element-rwd-item-${idx}`}>
                              <span className="text">{child.brand} 享</span>
                              <span className="text-gold">{child.text}</span>
                              <span className="text">優惠</span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="gray-container"></div>
            </Fragment>
          );
        })}
      </SmallDeviceWrapper>
    );
  }

  return (
    <Wrapper desktop={desktop}>
      {!isLogin && (
        <div className="title-container">
          <h3>會員等級 / 權益</h3>
          <p>立即登入/註冊，查看您目前的會員等級與升等資訊！</p>
          <button
            onClick={() => {
              NavActions.navigate('/login');
            }}>
            登入/註冊
          </button>
        </div>
      )}

      <div className="content-container">
        {MemberLevelData.map((array, index) => {
          if (index === 0) {
            return (
              <div className="row-container" key={`array-${index}`}>
                {array.map((element, idx) => {
                  if (idx === 0) {
                    return (
                      <div className="title-cell" key={`element-${idx}`}>
                        {element.title}
                      </div>
                    );
                  }
                  return (
                    <div className="item-cell" key={`element-${idx}`}>
                      <img alt="card" src={element.card} />
                      <div
                        className={`card-text-base need-${element.key}-color`}>
                        {element.name}
                      </div>
                      <div className="card-black-text-base">單筆消費滿</div>
                      <div className="card-black-text-highlight">
                        {element.consume}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }

          return (
            <div className="row-container" key={`array-${index}`}>
              {array.map((element, idx) => {
                if (idx === 0) {
                  return (
                    <div className="title-cell" key={`element-${idx}`}>
                      {element.title}
                    </div>
                  );
                }
                return (
                  <div className="item-cell" key={`element-${idx}`}>
                    <div className="gray-text-base">{element.label}</div>
                    <div className="black-text-base">{element.text}</div>
                    {element.label2 && (
                      <div style={{marginTop: 16}}>
                        <div className="gray-text-base">{element.label2}</div>
                        <div className="black-text-base">{element.text2}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const SmallDeviceWrapper = styled.div`
  background-color: rgba(20, 20, 20, 0.04);

  & > .title-container {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
    }

    & > button {
      margin-top: 16px;
      margin-bottom: 16px;
      width: 100%;
      border: 0px;
      padding: 14px;
      background-color: #000000;
      color: #ffffff;
      cursor: pointer;
    }
  }

  & > .gray-container {
    width: 100%;
    height: 12px;
    background-color: rgba(20, 20, 20, 0.04);
  }

  & > .card-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    ${props =>
      props.isLogin
        ? 'border-bottom: 1px solid rgba(20, 20, 20, 0.1); padding: 32px 0px;'
        : ''};

    & > .header-cell {
      margin-bottom: 32px;
      & > img {
        margin-bottom: 20px;
        width: 120px;
        height: 190px;
      }

      .card-black-text-base {
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.57;
        color: #141414;
        text-align: center;
      }

      .card-black-text-highlight {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        color: #141414;
        text-align: center;
      }

      .need-sliver-color {
        color: #9da6b3;
      }
      .need-gold-color {
        color: #cca75c;
      }
      .need-diamond-color {
        color: #141414;
      }

      .need-text-bold {
        font-weight: 500;
      }
    }

    & > .button-container {
      width: 200px;
      padding: 11px;
      border: 1px solid #141414;
      cursor: pointer;
      background: #ffffff;

      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
    }

    & > .content-cell {
      width: 100%;
      ${props => (props.isLogin ? 'padding: 20px;' : '')};

      & > .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-bottom: 13px;

        font-size: 14px;
        font-weight: 300;
        line-height: 1.57;
      }

      & > .content-container {
        & > .item {
          margin: 10px 0px 14px 0px;
          & > .label {
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.57;
          }

          & > .row-container {
            display: flex;
          }

          & > .child {
            margin: 2px 0px 0px 0px;
          }

          .text {
            font-size: 14px;
            line-height: 1.57;
            color: rgba(20, 20, 20, 0.7);
          }

          .text-gold {
            padding: 0px 5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.57;
            color: #cca75c;
          }
        }
      }

      .need-border-bottom {
        border-bottom: 1px solid rgba(20, 20, 20, 0.1);
      }
    }
  }
`;

const Wrapper = styled.div`
  & > .title-container {
    margin-bottom: 32px;
    padding: ${props => (props.desktop ? '0px' : '35px')};
    & > h3 {
      margin: 0px 0px 32px 0px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
    }

    & > p {
      margin: 0px 0px 12px 0px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
    }

    & > button {
      min-width: 160px;
      border: 0px;
      padding: 14px;
      background-color: #000000;
      color: #ffffff;
      cursor: pointer;
    }
  }

  & > .content-container {
    & > .row-container {
      display: flex;
      border-top: 1px solid rgba(20, 20, 20, 0.1);
      border-left: 1px solid rgba(20, 20, 20, 0.1);
      border-right: 1px solid rgba(20, 20, 20, 0.1);

      :last-child {
        border-bottom: 1px solid rgba(20, 20, 20, 0.1);
      }
    }

    & .title-cell {
      flex: 1;
      min-width: 160px;
      width: 100%;
      background-color: rgba(20, 20, 20, 0.03);
      padding: 32px 27px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      white-space: pre;
      color: rgba(20, 20, 20, 0.5);
    }

    & .item-cell {
      flex: 1;
      min-width: 160px;
      width: 100%;
      padding: 32px 27px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: rgba(20, 20, 20, 0.5);

      & > img {
        margin-bottom: 20px;
        width: 96px;
        height: 152px;
      }
    }

    .card-text-base {
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }

    .need-sliver-color {
      color: #9da6b3;
    }
    .need-gold-color {
      color: #cca75c;
    }
    .need-diamond-color {
      color: #141414;
    }

    .card-black-text-base {
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      color: #141414;
    }

    .card-black-text-highlight {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4;
      color: #141414;
    }

    .gray-text-base {
      margin-bottom: 2px;
      font-size: 14px;
      color: rgba(20, 20, 20, 0.5);
    }

    .black-text-base {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
  }
  margin-bottom: 64px;
`;

const MemberLevelData = [
  [
    {title: ''},
    {
      key: 'sliver',
      card: Sliver,
      name: '升等銀卡',
      consume: `NT${getFormatPrice(
        MEMBERSHIP_CONFIG['F'].onceConsumptionPrice,
      )}`,
    },
    {
      key: 'gold',
      card: Gold,
      name: '升等金卡',
      consume: `NT${getFormatPrice(
        MEMBERSHIP_CONFIG['G'].onceConsumptionPrice,
      )}`,
    },
    {
      key: 'diamond',
      card: Diamond,
      name: '升等鑽卡',
      consume: `NT${getFormatPrice(
        MEMBERSHIP_CONFIG['D'].onceConsumptionPrice,
      )}`,
    },
  ],
  [
    {title: '專屬生日禮'},
    {
      label: '生日禮金',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['F'].birthGiftPoints)}`,
    },
    {
      label: '生日禮金',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['G'].birthGiftPoints)}`,
    },
    {
      label: '生日禮金',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['D'].birthGiftPoints)}`,
    },
  ],
  [
    {title: '消費累計回饋金'},
    {label: '消費回饋', text: `${MEMBERSHIP_CONFIG['F'].rebatePointsRatio}%`},
    {label: '消費回饋', text: `${MEMBERSHIP_CONFIG['G'].rebatePointsRatio}%`},
    {label: '消費回饋', text: `${MEMBERSHIP_CONFIG['D'].rebatePointsRatio}%`},
  ],
  [
    {title: '升等獎勵\n*限單筆消費滿額獲得'},
    {label: '-', text: ''},
    {
      label: '升等禮金',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['F'].levelUpPrice)}`,
    },
    {
      label: '一般/銀卡會員升等禮金',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['M'].levelUpPrice)}`,
      label2: '金卡會員升等禮金',
      text2: `NT${getFormatPrice(MEMBERSHIP_CONFIG['D'].levelUpPrice)}`,
    },
  ],
  [
    {title: '續卡獎勵'},
    {
      label: '續卡獎勵',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['F'].renewPrice)}`,
    },
    {
      label: '續卡獎勵',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['G'].renewPrice)}`,
    },
    {
      label: '續卡獎勵',
      text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['D'].renewPrice)}`,
    },
  ],
  [
    {title: 'Les Néréides 折扣'},
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['F'].discount[brandsObj.lesNereides]}% off`,
    },
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['G'].discount[brandsObj.lesNereides]}% off`,
    },
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['D'].discount[brandsObj.lesNereides]}% off`,
    },
  ],
  [
    {title: 'Self-Portrait 折扣'},
    {label: '-', text: ''},
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['G'].discount[brandsObj.selfPortrait]}% off`,
    },
    {
      label: '',
      text: `${MEMBERSHIP_CONFIG['D'].discount[brandsObj.selfPortrait]}% off`,
    },
  ],
  [
    {title: 'Kenzo 折扣'},
    {label: '-', text: ''},
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['G'].discount[brandsObj.kenzo]}% off`,
    },
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['D'].discount[brandsObj.kenzo]}% off`,
    },
  ],
  [
    {title: 'Isabel Marant 折扣'},
    {label: '-', text: ''},
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['G'].discount[brandsObj.isabelMarant]}% off`,
    },
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['D'].discount[brandsObj.isabelMarant]}% off`,
    },
  ],
  [
    {title: 'agete 折扣'},
    {label: '-', text: ''},
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['G'].discount[brandsObj.agete]}% off`,
    },
    {
      label: ' ',
      text: `${MEMBERSHIP_CONFIG['D'].discount[brandsObj.agete]}% off`,
    },
  ],
];

const MemberLevelDataByRWD = [
  {
    key: 'sliver',
    card: Sliver,
    name: '銀卡優惠',
    label: '升等銀卡',
    consume: `NT${getFormatPrice(MEMBERSHIP_CONFIG['F'].onceConsumptionPrice)}`,
    items: [
      {
        title: '專屬生日禮',
        label_before: '生日禮金',
        text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['F'].birthGiftPoints)}`,
        label_after: '',
      },
      {
        title: '消費累計回饋金',
        label_before: '每筆訂單回饋總額',
        text: `${MEMBERSHIP_CONFIG['D'].rebatePointsRatio}%`,
        label_after: '購物金',
      },
      {
        title: '續卡獎勵',
        label_before: '隔年成功續卡送',
        text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['F'].renewPrice)}`,
        label_after: '',
      },
      {
        title: '品牌折扣',
        label_before: '',
        text: '',
        label_after: '',
        children: [
          {
            brand: 'Les Néréides',
            text: `${
              MEMBERSHIP_CONFIG['F'].discount[brandsObj.lesNereides]
            }% off`,
          },
          {
            brand: 'Self-Portrait',
            text: `${
              MEMBERSHIP_CONFIG['F'].discount[brandsObj.selfPortrait]
            }% off`,
          },
          {
            brand: 'Kenzo',
            text: `${MEMBERSHIP_CONFIG['F'].discount[brandsObj.kenzo]}% off`,
          },
          {
            brand: 'Isabel Marant',
            text: `${
              MEMBERSHIP_CONFIG['F'].discount[brandsObj.isabelMarant]
            }% off`,
          },
        ],
      },
    ],
  },
  {
    key: 'gold',
    card: Gold,
    name: '金卡優惠',
    label: '升等金卡',
    consume: `NT${getFormatPrice(MEMBERSHIP_CONFIG['G'].onceConsumptionPrice)}`,
    items: [
      {
        title: '專屬生日禮',
        label_before: '生日禮金',
        text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['G'].birthGiftPoints)}`,
        label_after: '',
      },
      {
        title: '消費累計回饋金',
        label_before: '每筆訂單回饋總額',
        text: `${MEMBERSHIP_CONFIG['G'].rebatePointsRatio}%`,
        label_after: '購物金',
      },
      {
        title: '續卡獎勵',
        label_before: '隔年成功續卡送',
        text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['G'].renewPrice)}`,
        label_after: '',
      },
      {
        title: '品牌折扣',
        label_before: '',
        text: '',
        label_after: '',
        children: [
          {
            brand: 'Les Néréides',
            text: `${
              MEMBERSHIP_CONFIG['G'].discount[brandsObj.lesNereides]
            }% off`,
          },
          {
            brand: 'Self-Portrait',
            text: `${
              MEMBERSHIP_CONFIG['G'].discount[brandsObj.selfPortrait]
            }% off`,
          },
          {
            brand: 'Kenzo',
            text: `${MEMBERSHIP_CONFIG['G'].discount[brandsObj.kenzo]}% off`,
          },
          {
            brand: 'Isabel Marant',
            text: `${
              MEMBERSHIP_CONFIG['G'].discount[brandsObj.isabelMarant]
            }% off`,
          },
        ],
      },
    ],
  },
  {
    key: 'diamond',
    card: Diamond,
    name: '鑽卡優惠',
    label: '升等鑽卡',
    consume: `NT${getFormatPrice(MEMBERSHIP_CONFIG['D'].onceConsumptionPrice)}`,
    items: [
      {
        title: '專屬生日禮',
        label_before: '生日禮金',
        text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['D'].birthGiftPoints)}`,
        label_after: '',
      },
      {
        title: '消費累計回饋金',
        label_before: '每筆訂單回饋總額',
        text: `${MEMBERSHIP_CONFIG['D'].rebatePointsRatio}%`,
        label_after: '購物金',
      },
      {
        title: '續卡獎勵',
        label_before: '隔年成功續卡送',
        text: `NT${getFormatPrice(MEMBERSHIP_CONFIG['D'].renewPrice)}`,
        label_after: '',
      },
      {
        title: '品牌折扣',
        label_before: '',
        text: '',
        label_after: '',
        children: [
          {
            brand: 'Les Néréides',
            text: `${
              MEMBERSHIP_CONFIG['D'].discount[brandsObj.lesNereides]
            }% off`,
          },
          {
            brand: 'Self-Portrait',
            text: `${
              MEMBERSHIP_CONFIG['D'].discount[brandsObj.selfPortrait]
            }% off`,
          },
          {
            brand: 'Kenzo',
            text: `${MEMBERSHIP_CONFIG['D'].discount[brandsObj.kenzo]}% off`,
          },
          {
            brand: 'Isabel Marant',
            text: `${
              MEMBERSHIP_CONFIG['D'].discount[brandsObj.isabelMarant]
            }% off`,
          },
        ],
      },
    ],
  },
];

export default MemberLevelTable;
