import styled from 'styled-components';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import {
  Actions,
  Breakpoints,
  Context,
  NavActions,
} from '../../../Contexts/AppContext';
import {Color, UnderlineButton} from '../../../Components/Widget';
import {StepItem} from './StepItem';
import {ServiceItem} from './ServiceItem';
import OrderFill from '../../../images/illustration-order-fill.svg';
import Box from '../../../images/illustration-box.svg';
import MoneyCheck from '../../../images/illustration-money-check.svg';
import Bag from '../../../images/illustration-bag.svg';
import {useDimension} from '../../../Hooks/AppHooks';
import MaintainRecordItem from './MaintainRecordItem';
import {MENU} from '../MemberCenterMenu';
import MaintainPic1 from '../../../../static/images/maintain-1.jpg';
import MaintainPic2 from '../../../../static/images/maintain-2.jpg';

const MaintainService = () => {
  const app = useContext(Context);
  const {mobile, tablet} = useDimension();
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const _fetch = async () => {
      try {
        Actions.setLoading(true);
        let payload = {
          limit: 100,
          offset: 0,
        };
        const resp = await Actions.getMaintainOrders(payload);
        setRecords(resp);
      } catch (e) {
      } finally {
        Actions.setLoading(false);
      }
    };
    _fetch();
  }, []);

  return (
    <Fragment>
      <Wrapper>
        <header>
          <div className="title">維修服務</div>
          {!!app.currentUser && (
            <UnderlineButton
              onClick={() => {
                NavActions.navigate(`/profile?active=${MENU.maintainHistory}`);
              }}>
              歷史紀錄
            </UnderlineButton>
          )}
        </header>
        {!app.currentUser && (
          <section className="login-not-yet">
            <div className="label">立即登入，追蹤您的維修服務訂單</div>
            <RectButton
              text="登入/註冊"
              skin={BUTTON_SKIN.PRIMARY}
              onClick={() => {
                NavActions.navigate('/login');
              }}
            />
          </section>
        )}
        {records.length <= 0 && (
          <section className="empty">
            <div>您沒有正在維修的商品！</div>
          </section>
        )}
        {!(records.length <= 0) && (
          <section className="maintain-records">
            {records.map((r, index) => (
              <MaintainRecordItem key={index} record={r} />
            ))}
          </section>
        )}
        <section className="service-flow">
          <div className="title">服務流程</div>
          <div className="label">全程線上追蹤・即時通知進度</div>
          <div className="flow">
            <StepItem
              icon={<img src={OrderFill} alt="order fill" />}
              title="線上申請"
              subtitle="可減少現場等待時間">
              <RectButton
                onClick={() => {
                  NavActions.navigate(`/profile?active=${MENU.maintainApply}`);
                }}
                text="申請維修"
                skin={BUTTON_SKIN.PRIMARY}
              />
            </StepItem>
            <div className="separate" />
            <StepItem
              icon={<img src={Box} alt="order fill" />}
              title="親自送件"
              subtitle="請攜帶商品至指定的門市辦理維修服務申請"
            />
            <StepItem
              icon={<img src={MoneyCheck} alt="order fill" />}
              title="確認價格"
              subtitle="當您同意原廠評估的報價後即可開始維修"
              arrow
            />
            <StepItem
              icon={<img src={Bag} alt="order fill" />}
              title="取件付款"
              subtitle="完工送回指定門市後，就能付款取件囉"
              arrow
            />
          </div>
        </section>
        <section className="service-browse">
          <div className="title">身為 TUN 會員，可享有以下維修服務：</div>
          <ServiceItem
            imgUrl={MaintainPic1}
            title="飾品清潔保養"
            subtitle="透過實體門市或線上購買的飾品，不論期限，均可在 TUN 的 Les Nereides, agete 各門市，享有免費清潔和保養的服務。"
          />
          <ServiceItem
            imgUrl={MaintainPic2}
            title="服裝修改"
            subtitle="透過實體門市或線上購買的服裝，在購買日起一個月內，均可在 TUN 的 Kenzo, Isabel Marant, Self-Portrait 各門市，享有服裝修改的服務。"
          />
        </section>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  flex: 1;
  background-color: ${Color.mainWhite};
  flex-direction: column;
  align-items: stretch;
  max-width: 970px;
  & > header {
    display: flex;
    align-items: center;
    & > .title {
      font-weight: bold;
      font-size: 30px;
      color: ${Color.mainDark};
    }
    & > button {
      margin-left: 16px;
    }
  }
  & > section {
    border-bottom: 1px solid ${Color.mainDark_10};
    &:last-child {
      border-bottom: none;
    }
    & > .title {
      font-size: 16px;
      font-weight: 300;
      color: ${Color.mainDark};
    }
    & > .label {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
  & > section.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      margin: 64px 0;
    }
  }
  & > section.maintain-records {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
  }
  & > section.login-not-yet {
    flex: 1;
    padding: 32px 0 52px 0;
    & > .label {
      color: ${Color.mainDark_70};
      margin-bottom: 24px;
    }
    & > button {
      width: 160px;
    }
  }
  & > section.service-flow {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    margin-bottom: 32px;
    & > .label {
      color: ${Color.gold};
    }
    & > .flow {
      display: flex;
      flex-direction: row;
      align-self: stretch;
      background-color: ${Color.mainDark_03};
      & > .separate {
        align-self: stretch;
        width: 1px;
        background-color: ${Color.mainDark_10};
        margin: 32px 0;
      }
    }
  }
  & > section.service-browse {
    display: flex;
    flex-direction: column;
    & > .title {
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 0 35px;
    & > header {
      display: none;
    }
    & > section.service-flow {
      & > .flow {
        flex-direction: column;
        align-self: stretch;
        & > .separate {
          width: unset;
          height: 1px;
          align-self: stretch;
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.mobile}px) {
    padding: 0 20px;
    & > section.login-not-yet {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > .label {
      }
      & > button {
        width: unset;
        align-self: stretch;
      }
    }
  }
`;

export default MaintainService;
