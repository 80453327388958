import styled from 'styled-components';
import {
  CheckBox,
  Color,
  FlexCol,
  FlexColCenter,
  FlexRow,
  UnderlineButton,
} from '../../../../Components/Widget';
import FixedRatioImage from '../../../../Components/FixedRatioImage';
import {getFormatPrice} from '../../../../Utils/ProductUtil';
import Select from '../../../../Components/Select';
import TextInput from '../../../../Components/TextInput';
import React, {Fragment} from 'react';
import {returnReason, returnReasonOptions} from './Return';
import {Counter} from './Counter';
import {useDimension} from '../../../../Hooks/AppHooks';
import * as L from '../../../../Utils/Lang';
import {APPROVE_STATUS} from '../../../../Domain/Return';

export const ReturnItem = props => {
  const {
    item,
    onCheckReturnItem,
    onReturnItemValueChange,
    counterOperatorFuncs,
    onReturnItemValidate,
    isEnableEdit,
    error,
  } = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;

  return (
    <Wrapper className="return-item-with-note">
      <FlexRow className="return-item">
        <FlexCol className="right">
          <FlexRow style={{alignItems: 'stretch'}}>
            {isEnableEdit && (
              <FlexColCenter style={{marginRight: 20}}>
                <CheckBox
                  isOn={item.isOn}
                  onClick={() => {
                    onCheckReturnItem(item);
                  }}
                />
              </FlexColCenter>
            )}
            <FixedRatioImage
              ratio={140 / 112}
              width={112}
              mode="cover"
              image={item.variant.images[0]}
            />
            <FlexRow className="return-item-block">
              <FlexCol className="item-info">
                <div className="item-name">
                  {L.d(item.variant.product.title)}
                </div>
                <div className="item-variant">
                  {L.d(item.variant.colorDisplay)} / {L.d(item.variant.size)}
                </div>
                <div className="item-variant">
                  可申請退貨數量：{item?.quantity}
                </div>
                {!!item?.returned_quantity && (
                  <div className="item-variant">
                    實際退貨數量：{item?.returned_quantity}
                  </div>
                )}
                <div style={{flex: 1}} className="filler" />
                {smallDevice && isEnableEdit && (
                  <Counter
                    style={{justifyContent: 'flex-start', marginBottom: 11}}
                    counterOperatorFuncs={counterOperatorFuncs}
                    item={item}
                  />
                )}
                <div
                  style={{
                    textAlign: smallDevice ? 'right' : 'left',
                  }}
                  className="item-price-tag">
                  {!!item?.returned_quantity
                    ? `NT ${getFormatPrice(
                        item.price * item?.returned_quantity,
                      )}`
                    : `NT ${getFormatPrice(item.price * item?.quantity)}`}
                </div>
              </FlexCol>

              {!smallDevice && !isEnableEdit && (
                <FlexCol style={{flex: 1}}>
                  <div style={{marginBottom: 20}}>
                    <small style={{color: Color.mainDark_70, fontSize: 12}}>
                      退貨原因
                    </small>
                    <div style={{fontSize: 14}}>{item.returnReason}</div>
                  </div>
                  {item.disapprove_reason && (
                    <div>
                      <small style={{color: Color.red, fontSize: 12}}>
                        退貨失敗
                      </small>
                      <div style={{fontSize: 14, color: Color.red}}>
                        {item.disapprove_reason}
                      </div>
                      <div style={{fontSize: 12}}>
                        將由指定服務門市人員聯絡您返還商品。如需協助，請聯絡
                        <UnderlineButton
                          onClick={() => {
                            window.open('tel:0800336677', '_self');
                          }}
                          style={{fontSize: 12}}>
                          客服專線
                        </UnderlineButton>
                        。
                      </div>
                    </div>
                  )}
                </FlexCol>
              )}

              {isEnableEdit && <div style={{flex: 1}} className="filler" />}
              {!smallDevice && isEnableEdit && (
                <Fragment>
                  <Counter
                    counterOperatorFuncs={counterOperatorFuncs}
                    item={item}
                  />
                  <FlexColCenter className="item-edit-area">
                    <Select
                      disabled={!item.isOn}
                      style={{width: '100%'}}
                      label="退貨原因"
                      options={returnReasonOptions}
                      value={item.returnReason}
                      onChange={onReturnItemValueChange('returnReason')(
                        item.id,
                      )}
                      onBlur={() => {
                        onReturnItemValidate(item.id)('returnReason');
                      }}
                      error={error.returnReason}
                    />
                  </FlexColCenter>
                </Fragment>
              )}
            </FlexRow>
          </FlexRow>
          {smallDevice && isEnableEdit && (
            <FlexColCenter style={{marginTop: 12}} className="item-edit-area">
              <Select
                disabled={!item.isOn}
                style={{width: '100%'}}
                label="退貨原因"
                options={returnReasonOptions}
                value={item.returnReason}
                onChange={onReturnItemValueChange('returnReason')(item.id)}
                onBlur={() => {
                  onReturnItemValidate(item.id)('returnReason');
                }}
                error={error.returnReason}
              />
            </FlexColCenter>
          )}
          {smallDevice && !isEnableEdit && (
            <FlexCol style={{flex: 1}}>
              <div style={{marginBottom: 20}}>
                <small style={{color: Color.mainDark_70, fontSize: 12}}>
                  退貨原因
                </small>
                <div style={{fontSize: 14}}>{item.returnReason}</div>
              </div>
              {item.approve === APPROVE_STATUS.failure && (
                <div>
                  <small style={{color: Color.red, fontSize: 12}}>
                    退貨失敗
                  </small>
                  <div style={{fontSize: 14, color: Color.red}}>
                    {item.disapprove_reason}
                  </div>
                  <div style={{fontSize: 12}}>
                    將由指定服務門市人員聯絡您返還商品。如需協助，請聯絡
                    <UnderlineButton style={{fontSize: 12}}>
                      線上客服
                    </UnderlineButton>
                    。
                  </div>
                </div>
              )}
            </FlexCol>
          )}
          {item.returnReason === returnReason.ELSE && (
            <TextInput
              disabled={!item.isOn}
              label="備註"
              value={item.note}
              onChange={onReturnItemValueChange('note')(item.id)}
            />
          )}
        </FlexCol>
      </FlexRow>
    </Wrapper>
  );
};
const Wrapper = styled(FlexCol)`
  & > .return-item {
    background-color: ${Color.mainWhite};
    flex: 1;
    padding: 20px;
    align-items: stretch;
    & > .right {
      flex: 1;
      & > div {
        & > .return-item-block {
          align-items: stretch;
          padding-left: 12px;
          flex: 1;
          & > .item-info {
            flex: 1;
            & > .item-name {
              font-weight: 500;
              font-size: 12px;
              margin-bottom: 8px;
            }
            & > .item-variant {
              font-size: 12px;
              color: ${Color.mainDark_70};
            }
            & > .item-price-tag {
              font-weight: 500;
              font-size: 14px;
            }
          }
          & > .item-edit-area {
            width: 220px;
            margin-left: 40px;
          }
        }
      }
    }
  }
`;
