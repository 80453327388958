import React from 'react';
import styled from 'styled-components';
import {CVS_TYPE_DATA, SHIPPING_TYPE} from '../../../Domain/Order';
import SFIcon from '../../../images/icon-logo-s-fexpress@3x.png';
import {Actions} from '../../../Contexts/AppContext';
import * as ModalContent from './ModalContent.addr';
import {
  openConfirmSetPrimaryAddressModal,
  openConfirmRemoveModal,
} from '../../../Hooks/useAddressManager';

function AddrItem(props) {
  const {item, fetchAddress, mobile, overlayRef} = props;
  const anchorRef = React.useRef();
  const isStore = !!item.storeName;

  const openMobileOverlayMenu = () => {
    overlayRef.current.open({
      domElem: anchorRef.current,
      content: (
        <OverlayMenu>
          {!item.primary && (
            <button
              onClick={() => {
                openConfirmSetPrimaryAddressModal({item, fetchAddress});
                overlayRef.current.close();
              }}>
              {item.shippingType === SHIPPING_TYPE.cvs
                ? '設為預設取件門市'
                : '設為預設宅配地址'}
            </button>
          )}
          <button
            onClick={async () => {
              Actions.setGlobalModalContent(
                item.shippingType === SHIPPING_TYPE.cvs ? (
                  <ModalContent.AddCvsStoreForm
                    fetchAddress={fetchAddress}
                    addr={item}
                  />
                ) : (
                  <ModalContent.AddAddressForm
                    fetchAddress={fetchAddress}
                    addr={item}
                  />
                ),
              );
              overlayRef.current.close();
            }}>
            修改
          </button>
          <button
            onClick={async () => {
              openConfirmRemoveModal({id: item.id, fetchAddress});
              overlayRef.current.close();
            }}>
            刪除
          </button>
        </OverlayMenu>
      ),
    });
  };

  const receiverAddr = item.city + item.district + item.detail;

  return (
    <ItemWrapper>
      {mobile && item.primary && (
        <div className="primary-mobile">
          {item.shippingType === SHIPPING_TYPE.home ? '預設地址' : '預設門市'}
        </div>
      )}

      {mobile && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '0px 20px',
            padding: '20px 0px',
            borderBottom: '1px solid #eee',
          }}>
          <div style={{marginRight: 10}}>{item.receiverName}</div>
          <div style={{color: 'rgba(20, 20, 20, 0.7)'}}>
            {item.receiverPhone}
          </div>
          <div style={{flex: 1}} />
          <button
            ref={anchorRef}
            onClick={() => {
              openMobileOverlayMenu();
            }}>
            編輯
          </button>
        </div>
      )}

      <div className="row">
        {!mobile && (
          <div
            style={{
              width: 150,
              marginRight: 10,
              borderRight: '1px solid #eee',
              flexShrink: 0,
            }}>
            <div>{item.receiverName}</div>
            <div style={{color: 'rgba(20, 20, 20, 0.7)'}}>
              {item.receiverPhone}
            </div>
          </div>
        )}

        {item.shippingType === SHIPPING_TYPE.home && (
          <img src={SFIcon} alt="address item" />
        )}

        {item.shippingType === SHIPPING_TYPE.cvs && (
          <img src={CVS_TYPE_DATA[item.storeType].iconPath} alt="cvs icon" />
        )}

        <div className="info">
          {isStore && <div className="name">{item.storeName}</div>}
          <div className="addr">{isStore ? item.storeAddr : receiverAddr}</div>
        </div>

        <div style={{flex: 1}} />
        {!mobile && (
          <>
            {item.primary && (
              <div className="primary">
                {item.shippingType === SHIPPING_TYPE.home
                  ? '預設地址'
                  : '預設門市'}
              </div>
            )}

            <button
              ref={anchorRef}
              onClick={() => {
                openMobileOverlayMenu();
              }}>
              編輯
            </button>
            <div
              style={{
                height: 30,
                backgroundColor: 'rgba(20, 20, 20, 0.3)',
                width: 1,
              }}
            />
            <button
              onClick={() => {
                openConfirmRemoveModal({id: item.id, fetchAddress});
              }}>
              刪除
            </button>
          </>
        )}
      </div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  border: solid 1px rgba(20, 20, 20, 0.1);
  border-radius: 2px;
  background-color: white;
  color: #141414;
  margin-bottom: 16px;

  & > .primary-mobile {
    background-color: rgba(20, 20, 20, 0.06);
    padding: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
  }

  & > .row {
    padding: 20px;
    display: flex;
    align-items: center;

    & > img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      margin-right: 12px;
    }

    & > .info {
      & > .name {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-right: 4px;
      }

      & > .addr {
        font-size: 16px;
        line-height: 1.5;
        color: rgba(20, 20, 20, 0.7);
      }
    }

    & > .primary {
      margin-right: 40px;
      padding: 6px 8px;
      border-radius: 2px;
      background-color: rgba(20, 20, 20, 0.06);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
    }
  }

  & button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 6px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    letter-spacing: normal;
    color: #141414;
    text-decoration: underline;
  }

  & button:disabled {
    color: rgba(20, 20, 20, 0.3);
  }
`;

const OverlayMenu = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    white-space: nowrap;
    outline: none;
    border: none;
    background-color: transparent;
    margin-bottom: 15px;
  }

  & > button:last-child {
    margin-bottom: 0;
  }
`;

export default AddrItem;
