import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import {getPasswordValidator} from '../../Utils/validateUtil';
import {Actions} from '../../Contexts/AppContext';
import Alert from '../../Components/Modal/Alert';
import Lock from '../../images/illustration-password.svg';
import Time from '../../images/illustration-time.svg';
import {useDimension} from '../../Hooks/AppHooks';

function ChangePassword(props) {
  const {desktop} = useDimension();
  const [values, setValues] = React.useState({
    oldPass: '',
    newPass: '',
    confirmNewPass: '',
  });
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = useState(false);

  const getValue = field => values[field];
  const getError = field => errors[field];

  const onValueChange = field => e => {
    setValues({...values, [field]: e.target.value});
  };

  const validators = useMemo(
    () => ({
      oldPass: value => getPasswordValidator().passRequest(value),
      newPass: value => getPasswordValidator().passRequest(value),
      confirmNewPass: value => {
        if (value === values.newPass) {
          return null;
        } else {
          return '輸入密碼不相同';
        }
      },
    }),
    [values.newPass],
  );

  const onValidate = field => e => {
    const _errors = {...errors};
    _errors[field] = validators[field](e.target.value);
    setErrors(_errors);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const _errors = {...errors};
      Object.keys(validators).forEach(
        field => (_errors[field] = validators[field](values[field])),
      );
      setErrors(_errors);
      const isAllPass = Object.values(_errors).every(error => error === null);
      if (!isAllPass) {
        return;
      }

      const result = await Actions.updatePassword(values);

      if (!result.success) {
        throw new Error('update password failed');
      }

      Actions.setGlobalModalContent(
        <Alert
          onClick={() => {
            Actions.setGlobalModalContent(null);
            window.location.reload();
          }}
          cancelLabel="確定"
          icon={<img src={Lock} alt="" />}
          title="密碼已更新"
          subTitle="以後請使用新密碼登入"
        />,
      );
    } catch (e) {
      Actions.setGlobalModalContent(
        <Alert
          cancelLabel="確定"
          icon={<img src={Time} alt="" />}
          title="密碼更新失敗"
          subTitle="請稍後再試"
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <h2>變更密碼</h2>
      <p style={{marginBottom: 32}}>
        為了保護帳號的安全，請不要分享密碼給其他人
      </p>

      <div style={{flex: 1, maxWidth: 800}}>
        <TextInput
          label="原密碼"
          type="password"
          value={getValue('oldPass')}
          onChange={onValueChange('oldPass')}
          onBlur={onValidate('oldPass')}
          error={getError('oldPass')}
          style={{marginBottom: 24}}
        />

        <TextInput
          label="新密碼 (8 ~ 12 位英數字組合)"
          type="password"
          value={getValue('newPass')}
          onChange={onValueChange('newPass')}
          onBlur={onValidate('newPass')}
          error={getError('newPass')}
          style={{marginBottom: 24}}
        />

        <TextInput
          label="確認密碼"
          type="password"
          value={getValue('confirmNewPass')}
          onChange={onValueChange('confirmNewPass')}
          onBlur={onValidate('confirmNewPass')}
          error={getError('confirmNewPass')}
          style={{marginBottom: 24}}
        />
      </div>

      <RectButton
        isLoading={loading}
        style={!desktop ? {width: '100%'} : {width: 200}}
        onClick={onSubmit}
        text="儲存變更"
        skin={BUTTON_SKIN.PRIMARY}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px;

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.27;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default ChangePassword;
