import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {getFormatPrice} from '../../../Utils/ProductUtil';
import {
  Block,
  Color,
  FlexRow,
  UnderlineButton,
} from '../../../Components/Widget';
import CheckButton from '../../Checkout/CheckButton';
import {useDimension} from '../../../Hooks/AppHooks';
import {Actions, NavActions} from '../../../Contexts/AppContext';
import {CREDIT_CARD_TYPE_PIC} from '../../../Domain/Order';
import creditCardType from 'credit-card-type';
import {PAYMENT_TYPE} from '../../../Domain/Checkout';
import Select from '../../../Components/Select';
import {
  useAddCreditCard,
  useCreditCards,
} from '../../../Hooks/CreditCardsHooks';
import qs from 'query-string';
import {getRequiredValidator, validateForm} from '../../../Utils/validateUtil';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import Alert from '../../../Components/Modal/Alert';
import Check from '../../../images/illustration-check.svg';
import OrderWarning from '../../../images/illustration-order-warning.svg';
import Time from '../../../images/illustration-time.svg';
import {reCheckoutInfo} from '../../../Utils/DateUtil';
import useOrderManager from '../../../Hooks/useOrderManager';
import ModalContentInstallmentHint from '../../../Components/ModalContent.installmentHint';
import {PickerContent} from '../../Checkout/PickerContent';
import Modal from '../../Checkout/Modal';
import {PickerButton} from '../../Checkout/PickerButton';
import {SelectedCreditPicker} from '../../Checkout/SelectedCreditPicker';

const ReCheckout = props => {
  const {orderId} = props;
  const revisit = !!qs.parse(window.location.search).returned;
  const {desktop} = useDimension();
  const [isLoading, setIsLoading] = useState(false);
  const {order} = useOrderManager({orderId});
  const modalRef = useRef(null);

  const [values, setValues] = useState({
    installment: 0,
    selectedCreditCardId: '',
  });
  const [errors, setErrors] = useState({});
  const onValueChange = field => e => {
    setValues({...values, [field]: e.target.value});
  };
  const validators = useMemo(
    () => ({
      selectedCreditCardId: getRequiredValidator(),
    }),
    [],
  );

  const _reCheckoutInfo = useMemo(() => {
    return reCheckoutInfo(order?.created_at);
  }, [order]);

  const onReCheckout = async () => {
    const isAllPass = validateForm({values, errors, setErrors, validators});
    if (!isAllPass) {
      return;
    }

    if (!_reCheckoutInfo.isCanReCheckout) {
      Actions.setGlobalModalContent(
        <Alert
          icon={<img src={Time} alt="" />}
          title="已超出付款時限"
          subTitle="您的訂單由於超時未完成付款程序，已自動取消，請重新訂購"
          cancelLabel="返回購買紀錄"
          onClick={() => {
            NavActions.navigate(`/profile?active=orders&orderId=${orderId}`);
            Actions.setGlobalModalContent(null);
          }}
        />,
      );
      return;
    }

    setIsLoading(true);
    const isSuccess = await Actions.reCheckout({
      id: orderId,
      selectedCreditCardId: values.selectedCreditCardId,
      installment: parseInt(values.installment),
    });
    setIsLoading(false);

    if (!isSuccess) {
      Actions.setGlobalModalContent(
        <Alert
          icon={<img src={OrderWarning} alt="" />}
          title="交易失敗"
          subTitle="您的訂單付款失敗，請重新選擇付款方式。有任何問題請洽發卡銀行"
          cancelLabel="確定"
        />,
      );
      return;
    }

    Actions.setGlobalModalContent(
      <Alert
        icon={<img src={Check} alt="" />}
        title="重新付款成功"
        cancelLabel="確定"
        onClick={() => {
          NavActions.navigate(`/profile?active=orders&orderId=${orderId}`);
          Actions.setGlobalModalContent(null);
        }}
      />,
    );
  };

  const {myCreditCards} = useCreditCards();

  const {addCreditCard, addCreditCardForm} = useAddCreditCard({
    returnPath: `/profile?active=orders&orderId=${orderId}&revisit=true&action=re_checkout`,
    storeKey: 're-checkout-data',
    restoreValuesFunc: setValues,
  });

  const paymentType = useMemo(() => {
    return values.installment === 0 ? PAYMENT_TYPE.all : PAYMENT_TYPE.staged;
  }, [values.installment]);

  useEffect(() => {
    if (!order || revisit) {
      return;
    }

    const defaultCard = myCreditCards.find(card => card.is_primary);

    const _selectedCreditCardId = defaultCard
      ? defaultCard.id
      : order.credit_card_id;

    setValues(values => ({
      ...values,
      installment: order.installment,
      selectedCreditCardId: _selectedCreditCardId,
    }));
  }, [myCreditCards, order, revisit]);

  const installmentOptions = useMemo(() => {
    if (!order) {
      return [];
    }
    return [
      {
        value: 3,
        display: `NT ${getFormatPrice(
          Math.round(order.calculations.subtotal / 3),
        )} * 3 期零利率`,
      },
      {
        value: 6,
        display: `NT ${getFormatPrice(
          Math.round(order.calculations.subtotal / 6),
        )} * 6 期零利率`,
        disabled: order.calculations.subtotal < 10000,
      },
    ];
  }, [order]);

  const openInstallmentHintModal = () => {
    Actions.setGlobalModalContent(
      <ModalContentInstallmentHint
        mobile={!desktop}
        onConfirm={() => 0}
        onClose={() => Actions.setGlobalModalContent(null)}
      />,
    );
  };

  if (!order) {
    return null;
  }

  return (
    <Fragment>
      <Wrapper mobile={!desktop}>
        <Block warning style={{marginBottom: 40}}>
          請在有效時限內再次結帳，否則系統將直接取消此訂單，您需要再重新下單喔！
        </Block>
        <div className="title">1. 訂單資訊</div>

        {desktop && (
          <section className="order-info">
            <div className="order-num">訂單編號 {orderId}</div>
            <div className="order-info-detail">
              <span>總金額</span>
              <span className="price">
                NT {getFormatPrice(order.calculations.total)}
              </span>
              <span className="expired-date">
                有效付款時限 {_reCheckoutInfo?.expiredDate} 24:00
              </span>
            </div>
          </section>
        )}

        {!desktop && (
          <section className="order-info-mobile">
            <div className="order-num">訂單編號 {orderId}</div>
            <div className="separate" />
            <div className="order-info-detail">
              <div>
                <span>總金額</span>
                <span className="price">
                  NT {getFormatPrice(order.calculations.total)}
                </span>
              </div>
              <div>
                <span className="expired-date">
                  有效付款時限 {_reCheckoutInfo?.expiredDate} 24:00
                </span>
              </div>
            </div>
          </section>
        )}

        <div className="title">2. 付款方式</div>

        <section className="payment">
          <div className="installment-row">
            <CheckButton
              style={{flex: 1, margin: '8px 0'}}
              checked={paymentType === PAYMENT_TYPE.all}
              onClick={() => setValues({...values, installment: 0})}>
              全額支付
            </CheckButton>

            <div style={{width: 12}} />

            <CheckButton
              style={{flex: 1, margin: '8px 0'}}
              checked={paymentType === PAYMENT_TYPE.staged}
              onClick={() => {
                setValues({...values, installment: 3});
              }}
              disabled={order.calculations.subtotal < 5000}>
              分期零利率
            </CheckButton>
          </div>

          <p className="quote">
            滿 $5,000 可使用 0 利率分期付款，點擊了解
            <UnderlineButton
              style={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: `${12 * 1.5}px`,
              }}
              onClick={openInstallmentHintModal}>
              支援期數 / 銀行
            </UnderlineButton>
          </p>

          {paymentType === PAYMENT_TYPE.staged && (
            <Select
              label="分期付款期數"
              options={installmentOptions}
              error={errors.installment}
              value={values.installment}
              onChange={onValueChange('installment')}
              style={{
                width: desktop ? 'calc(50% - 8px)' : '100%',
                marginBottom: 24,
              }}
            />
          )}
          {!desktop && (
            <PickerButton
              error={errors.selectedCreditCardId}
              style={{
                marginBottom: !desktop ? 0 : 64,
              }}
              onClick={() => {
                modalRef.current.open(
                  <SelectedCreditPicker
                    modalRef={modalRef}
                    values={values}
                    setValues={setValues}
                    myCreditCards={myCreditCards}
                    addCreditCard={addCreditCard}
                    installment={values.installment}
                  />,
                );
              }}>
              {myCreditCards.find(c => c.id === values.selectedCreditCardId) ? (
                <Fragment>
                  {
                    CREDIT_CARD_TYPE_PIC[
                      creditCardType(
                        myCreditCards.find(
                          c => c.id === values.selectedCreditCardId,
                        ).first_six,
                      )[0].type
                    ]
                  }
                  *{' '}
                  {
                    myCreditCards.find(
                      c => c.id === values.selectedCreditCardId,
                    ).last_four
                  }
                </Fragment>
              ) : (
                <div className="empty">請選擇信用卡</div>
              )}
            </PickerButton>
          )}

          {desktop && (
            <div className="credit-cards">
              <p className="label">請選擇信用卡</p>

              {myCreditCards.map((card, index) => {
                return (
                  <CheckButton
                    key={index}
                    checked={card.id === values.selectedCreditCardId}
                    style={{margin: '8px 0', border: 'none', paddingLeft: 0}}
                    onClick={() =>
                      setValues({...values, selectedCreditCardId: card.id})
                    }>
                    <FlexRow>
                      {
                        CREDIT_CARD_TYPE_PIC[
                          creditCardType(card.first_six)[0].type
                        ]
                      }
                      * {card.last_four}
                    </FlexRow>
                  </CheckButton>
                );
              })}

              <AddCardButton
                mobile={!desktop}
                error={errors.selectedCreditCardId}
                onClick={() => {
                  addCreditCard(values);
                }}>
                + 新增信用卡
              </AddCardButton>
            </div>
          )}

          <RectButton
            text="結帳"
            skin={BUTTON_SKIN.PRIMARY}
            style={{margin: '64px auto', width: !desktop ? '100%' : '200px'}}
            onClick={onReCheckout}
            isLoading={isLoading}
          />
        </section>
        {addCreditCardForm}
      </Wrapper>
      <Modal
        mobile={!desktop}
        getInstance={inst => {
          modalRef.current = inst;
        }}
      />
    </Fragment>
  );
};

const AddCardButton = styled.button`
  background-color: transparent;
  outline: none;
  width: ${props => (props.mobile ? '100%' : 'auto')};
  border: 1px solid ${props => (props.mobile ? Color.gold : 'transparent')};
  padding: ${props => (props.mobile ? '14px 4px' : '4px')};
  color: ${props => (props.error ? Color.red : Color.gold)};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  min-width: ${({mobile}) => (mobile ? 'unset' : '888px')};

  & > .title {
    flex: 1;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
  }

  & > section.order-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid ${Color.mainDark_10};
    margin-bottom: 64px;
    & > .order-num {
      flex: 1;
      font-size: 14px;
      background-color: ${Color.mainDark_03};
      padding: 15px 32px;
    }
    & > .order-info-detail {
      flex: 1;
      background-color: ${Color.mainWhite};
      padding: 20px 32px;
      display: flex;
      align-items: center;
      & span {
        font-weight: 500;
        font-size: 14px;
      }
      & span.price {
        font-weight: bold;
        font-size: 20px;
        margin-left: 8px;
      }
      & span.expired-date {
        color: ${Color.red};
        margin-left: 12px;
      }
    }
  }

  & > section.order-info-mobile {
    display: flex;
    flex-direction: column;
    & > .order-num {
      font-size: 12px;
    }
    & > .separate {
      flex: 1;
      border-bottom: 1px solid ${Color.mainDark_10};
      margin-top: 12px;
      margin-bottom: 20px;
    }
    & > .order-info-detail {
      & div:first-child {
        & span:first-child {
          font-weight: 500;
          font-size: 14px;
        }
        & > .price {
          margin-left: 8px;
          font-weight: bold;
          font-size: 16px;
        }
      }
      & div:last-child {
        margin-top: 10px;
        margin-bottom: 32px;
        & > .expired-date {
          color: ${Color.red};
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  & > section.payment {
    & p {
      font-size: 12px;
    }
    & > p.quote {
      font-weight: 500;
      margin-bottom: 24px;
    }
    & p.label {
      font-weight: 300;
      color: ${Color.mainDark_70};
    }

    & > .installment-row {
      display: flex;
      flex-direction: ${props => (props.mobile ? 'column' : 'row')};
    }
  }
`;

export default ReCheckout;
