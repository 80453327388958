import React, {useState} from 'react';
import styled from 'styled-components';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';

export function RemoveCardModalContent(props) {
  const {onConfirm, onClose, mobile} = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ModalContentWrapper mobile={mobile}>
      <h2>確定要刪除此信用卡嗎？</h2>

      <div style={{display: 'flex', marginTop: 40}}>
        <RectButton
          text="取消"
          skin={BUTTON_SKIN.DEFAULT}
          onClick={onClose}
          style={{flex: 1}}
        />
        <div style={{width: 12}} />
        <RectButton
          isLoading={isLoading}
          text="確定"
          skin={BUTTON_SKIN.PRIMARY}
          onClick={async () => {
            try {
              setIsLoading(true);
              await onConfirm();
            } catch (e) {
              console.error(e);
            } finally {
              setIsLoading(false);
              onClose();
            }
          }}
          style={{flex: 1}}
        />
      </div>
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div`
  padding: 20px;
  background-color: white;
  position: relative;
  width: ${props => (props.mobile ? 'unset' : '420px')};

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
  }

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #ccc;
    margin-bottom: 32px;
  }
`;
