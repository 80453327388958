import React from 'react';
import styled from 'styled-components';
import {ORDER_STATUS_DISPLAY, ORDER_STATUS} from '../../../Domain/Order';
import * as L from '../../../Utils/Lang';
import {Color} from '../../../Components/Widget';

function OrderTabs(props) {
  const {mobile, tabs, selectedTab, setSelectedTab, totalCounts} = props;
  const totalCount = Object.values(totalCounts).reduce((acc, cur) => {
    return (acc += cur);
  }, 0);
  return (
    <Wrapper mobile={mobile}>
      <div className="tabs">
        <button
          onClick={() => setSelectedTab('')}
          className={`${selectedTab === '' ? 'selected' : ''}`}>
          全部({totalCount})
        </button>
        {tabs.map(tab => (
          <button
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`${selectedTab === tab ? 'selected' : ''}`}>
            {`${L.d(ORDER_STATUS_DISPLAY[tab])}(${totalCounts[tab]})`}
          </button>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;
  border-bottom: 1px solid ${Color.mainDark_10};
  padding: ${props => (props.mobile ? '0 20px' : '0')};
  font-size: 16px;
  line-height: 1.5;

  & > .tabs {
    max-width: 100%;
    overflow: auto;
    display: flex;

    & > button {
      cursor: pointer;
      background-color: white;
      border: none;
      border-bottom: 2px solid transparent;
      outline: none;
      padding: 12px 0px;
      margin-right: ${props => (props.mobile ? '28px' : '40px')};
      flex-shrink: 0;
      color: gray;
    }

    & > button.selected {
      border-bottom: 2px solid black;
      color: black;
      font-weight: bold;
    }
  }
`;
export default OrderTabs;
