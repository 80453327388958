import React, {Fragment, useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import CustomNav from '../../../Components/CustomNav';
import {ChevronLeft} from '../../../Components/SvgIcon';
import {
  Actions,
  Breakpoints,
  Context,
  NavActions,
} from '../../../Contexts/AppContext';
import {useDimension} from '../../../Hooks/AppHooks';
import TextInput from '../../../Components/TextInput';
import Select from '../../../Components/Select';
import CheckBox from './CheckBox';
import {Color, UnderlineButton} from '../../../Components/Widget';
import RectButton, {
  BUTTON_SIZE,
  BUTTON_SKIN,
} from '../../../Components/RectButton';
import {MENU} from '../MemberCenterMenu';
import {
  GET_CATEGORY_OPTIONS_BY_BRAND,
  SERVICE_OPTIONS_BY_CATEGORY,
} from '../../../Domain/Maintain';
import {
  brandIdToBrandName,
  brandNameToBrandId,
  brandsDisplayArr,
} from '../../../Domain/Brand';
import * as L from '../../../Utils/Lang';
import {
  getPhoneValidator,
  getRequiredValidator,
  validateForm,
} from '../../../Utils/validateUtil';
import useFavStores from '../../../Hooks/useFavStores';
import Confirm from '../../../Components/Modal/Confirm';

function SelectStoreModalContent(props) {
  const {brand, init, setValues} = props;
  const app = useContext(Context);
  const [store, setStore] = useState(init);
  return (
    <Confirm
      onClick={() => {
        setValues(prev => ({
          ...prev,
          store: store,
        }));
        Actions.setGlobalModalContent(null);
      }}
      cancelLabel="取消"
      confirmLabel="確定">
      <Select
        onChange={e => {
          const {value} = e.target;
          setStore(value);
        }}
        style={{width: '100%'}}
        options={
          app.allStoreData[brandIdToBrandName[brand]]?.map(store => ({
            display: L.d(store.name),
            value: store.id,
          })) || []
        }
        label="指定服務門市"
        name="store"
        value={store}
      />
    </Confirm>
  );
}

const MaintainApply = () => {
  const noteMaxLength = 200;
  const {favStores} = useFavStores();
  const app = useContext(Context);
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const inputStyles = useMemo(() => {
    if (smallDevice) {
      return {
        flex: 1,
        marginBottom: 8,
      };
    } else {
      return {
        width: '48%',
      };
    }
  }, [smallDevice]);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    brand: '',
    category: '',
    variantUid: '',
    note: '',
    serviceOptions: [],
    store: '',
  });
  const [errors, setErrors] = useState({
    name: null,
    phone: null,
    brand: null,
    category: null,
    variantUid: null,
    note: null,
    store: null,
    serviceOptions: null,
  });
  const validators = useMemo(
    () => ({
      name: getRequiredValidator(),
      phone: getPhoneValidator(),
      brand: getRequiredValidator(),
      category: getRequiredValidator(),
    }),
    [],
  );

  const onValueChange = field => e => {
    const {value} = e.target;
    if (field === 'brand') {
      const store = favStores.find(
        _store => _store.brand === brandIdToBrandName[value],
      );
      setValues(prevState => ({
        ...prevState,
        [field]: value,
        category: '',
        serviceOptions: [],
        store: store.storeId,
      }));

      return;
    }
    if (field === 'category') {
      setValues(prevState => ({
        ...prevState,
        [field]: value,
        serviceOptions: [],
      }));
      return;
    }
    if (field === 'serviceOptions') {
      let nextServiceOptions = [...values.serviceOptions];
      if (values.serviceOptions.includes(value)) {
        nextServiceOptions = nextServiceOptions.filter(opt => opt !== value);
      } else {
        nextServiceOptions.push(value);
      }
      setValues(prev => ({
        ...prev,
        serviceOptions: nextServiceOptions,
      }));
      return;
    }

    if (field === 'note') {
      let nextErrors = {...errors, note: null};
      if (value.length >= noteMaxLength) {
        nextErrors.note = `${value.length}/${noteMaxLength} 超出字數限制`;
      }
      setValues(prevState => ({...prevState, [field]: value}));
      setErrors(prev => ({
        ...prev,
        ...nextErrors,
      }));

      return;
    }
    setValues(prevState => ({...prevState, [field]: value}));
  };

  const CATEGORY_OPTIONS_BY_BRAND = useMemo(() => {
    return GET_CATEGORY_OPTIONS_BY_BRAND(values.brand);
  }, [values.brand]);

  const onSubmit = async () => {
    try {
      Actions.setLoading(true);
      const isAllPass = validateForm({values, errors, setErrors, validators});
      if (!isAllPass) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        return;
      }
      const payload = {
        receiver_name: values.name,
        receiver_phone: values.phone,
        brand_id: values.brand,
        item: values.category,
        product_no: values.variantUid,
        services: values.serviceOptions,
        store_id: values.store,
        note: values.note,
      };
      const response = await Actions.createMaintainOrder(payload);
      NavActions.navigate('/profile?active=maintain');
      console.log('debug', response);
    } catch (e) {
    } finally {
      Actions.setLoading(false);
    }
  };

  const onSelectStore = () => {
    try {
      Actions.setGlobalModalContent(
        <SelectStoreModalContent
          setValues={setValues}
          brand={values.brand}
          init={values.store}
        />,
      );
    } catch (e) {}
  };

  return (
    <Fragment>
      <CustomNav
        navType="solid"
        left={
          <div
            onClick={() => {
              NavActions.navigate(`/profile?active=${MENU.maintain}`);
            }}
            style={{
              display: 'flex',
              fontSize: 16,
              fontWeight: 300,
              cursor: 'pointer',
            }}>
            <ChevronLeft />
            {!smallDevice && <div style={{marginLeft: 4}}>返回</div>}
          </div>
        }
        middle={
          <div
            style={
              smallDevice
                ? {}
                : {
                    fontSize: 20,
                    fontWeight: 500,
                  }
            }>
            申請維修
          </div>
        }
      />
      <Wrapper>
        <section className="apply-info">
          <div className="title">1. 申請資訊</div>
          <div className="input-group">
            <TextInput
              style={inputStyles}
              label="申請人姓名"
              name="name"
              onChange={onValueChange('name')}
              value={values.name}
              error={errors.name}
            />
            <TextInput
              onChange={onValueChange('phone')}
              style={inputStyles}
              label="申請人手機"
              name="phone"
              value={values.phone}
              error={errors.phone}
              maxLength={10}
            />
          </div>
          <div className="input-group">
            <Select
              onChange={onValueChange('brand')}
              style={inputStyles}
              options={brandsDisplayArr.map(b => ({
                value: brandNameToBrandId[b.key],
                display: b.display,
                disabled: false,
              }))}
              label="品牌"
              name="brand"
              value={values.brand}
              error={errors.brand}
            />
            <Select
              disabled={!values.brand}
              onChange={onValueChange('category')}
              style={inputStyles}
              options={CATEGORY_OPTIONS_BY_BRAND}
              label="品項"
              name="category"
              value={values.category}
              error={errors.category}
            />
          </div>
          <div className="input-group">
            <TextInput
              onChange={onValueChange('variantUid')}
              style={inputStyles}
              label="商品品號（非必填）"
              name="variantUid"
              value={values.variantUid}
              error={errors.variantUid}
            />
          </div>
        </section>
        <section className="service-options">
          <div className="title">2. 服務項目</div>
          {!values.category && <CheckBox label="請先選擇品牌與品項" disabled />}
          {!!values.category && (
            <Fragment>
              <div className="hint gold">
                價格僅供參考，實際狀況依實品評估而定
              </div>
              {SERVICE_OPTIONS_BY_CATEGORY[values.category].map(
                ({value, display, disabled}, index) => {
                  return (
                    <CheckBox
                      id={value}
                      key={index}
                      value={value}
                      checked={values.serviceOptions.includes(value)}
                      onChange={onValueChange('serviceOptions')}
                      label={L.d(display)}
                      disabled={disabled}
                    />
                  );
                },
              )}
              <textarea
                rows={1}
                maxLength={noteMaxLength}
                placeholder={`請輸入備註（限 ${noteMaxLength} 字，非必填）`}
                name="note"
                onChange={onValueChange('note')}
                value={values.note}
              />
              {errors.note && (
                <div style={{color: Color.red}}>{errors.note}</div>
              )}
            </Fragment>
          )}
        </section>
        <section className="assign-store">
          <div className="title">3. 指定服務門市</div>
          {!!values.brand && (
            <div className="store-selector">
              <div className="content">
                <div className="name">
                  {L.d(
                    app.allStoreData[brandIdToBrandName[values.brand]].find(
                      s => s.id === parseInt(values.store),
                    ).name,
                  )}
                </div>
                <div className="address">
                  {L.d(
                    app.allStoreData[brandIdToBrandName[values.brand]].find(
                      s => s.id === parseInt(values.store),
                    ).address,
                  )}
                </div>
              </div>
              <UnderlineButton onClick={onSelectStore}>
                更換門市
              </UnderlineButton>
            </div>
          )}
          <div className="for-your-info">
            <div className="content">
              <div className="title">關於維修</div>
              <div className="list">
                ・若您的商品不是在 TUN
                的通路購買，維修時請攜帶銷售證明書至服務門市，部分商品因材質特殊與製作工法限制恕無法維修，請見諒。
              </div>
              <div className="list">
                ・自購買日起一年內，如商品於正常使用的狀況下發生不良問題，可享有免費維修服務。
              </div>
              <div className="list">
                ・寶石遺失或其他人為因素，則須視商品維修情況酌收維修費用，費用以實際情況再予以報價。
              </div>
            </div>
          </div>
        </section>
        <RectButton
          disabled={values.serviceOptions.length <= 0}
          text="送出申請"
          size={BUTTON_SIZE.LARGE}
          skin={BUTTON_SKIN.PRIMARY}
          onClick={onSubmit}
        />
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px 262px 64px 262px;
  & > button {
    align-self: center;
    width: 200px;
  }
  & .gold {
    color: ${Color.gold};
  }
  & > section {
    & > .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    & > .hint {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
  & > section.apply-info {
    & > .input-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;
      &:last-child {
        justify-content: flex-start;
      }
    }
  }

  & > section.service-options {
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;
    & > textarea {
      flex: 1;
      outline: none;
      padding: 11px 16px;
      border: 1px solid ${Color.mainDark_10};
      margin: 20px 0;
      ::placeholder {
        color: ${Color.mainDark_50};
      }
    }
  }
  & > section.assign-store {
    & > .store-selector {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      background-color: ${Color.mainDark_03};
      margin-bottom: 24px;
      & > .content {
        display: flex;
        flex-direction: column;
        & > .name {
          font-size: 14px;
          font-weight: 500;
        }
        & > .address {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    & > .for-your-info {
      margin-bottom: 64px;
      & > .content {
        & > .title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
        }
        & > .list {
          font-size: 14px;
          font-weight: 300;
          color: ${Color.mainDark_70};
        }
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.tablet}px) {
    flex: 1;
    padding: 40px 126px 64px 126px;
    & > button {
      flex: 1;
      width: unset;
    }
    & .gold {
    }
    & > section {
      & > .title {
      }
      & > .hint {
      }
    }
    & > section.apply-info {
      & > .input-group {
        flex-direction: column;
        &:last-child {
        }
      }
    }

    & > section.service-options {
      & > textarea {
        ::placeholder {
        }
      }
    }
    & > section.assign-store {
      & > .store-selector {
        & > .content {
          & > .name {
          }
          & > .address {
          }
        }
      }
      & > .for-your-info {
        & > .content {
          & > .title {
          }
          & > .list {
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.mobile}px) {
    flex: 1;
    padding: 20px;
    & > button {
      align-self: stretch;
      flex: 1;
      width: unset;
    }
    & .gold {
    }
    & > section {
      & > .title {
      }
      & > .hint {
      }
    }
    & > section.apply-info {
      & > .input-group {
        flex-direction: column;
        &:last-child {
        }
      }
    }

    & > section.service-options {
      & > textarea {
        ::placeholder {
        }
      }
    }
    & > section.assign-store {
      & > .store-selector {
        & > .content {
          & > .name {
          }
          & > .address {
            max-width: 180px;
          }
        }
      }
      & > .for-your-info {
        margin-bottom: 24px;
        & > .content {
          & > .title {
          }
          & > .list {
          }
        }
      }
    }
  }
`;

export default MaintainApply;
