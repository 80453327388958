import React, {Fragment, useMemo} from 'react';
import styled from 'styled-components';
import * as L from '../../../Utils/Lang';
import {Breakpoints} from '../../../Contexts/AppContext';
import {
  Color,
  FlexCol,
  FlexRow,
  UnderlineButton,
} from '../../../Components/Widget';
import {useDimension} from '../../../Hooks/AppHooks';
import {d2s, getDateString, s2d} from '../../../Utils/DateUtil';
import {Tooltip} from 'antd';

function Stepper(props) {
  const {
    steps,
    current,
    history,
    status,
    isFailed,
    isWithContact,
    tooltip,
    style = {},
  } = props;
  const total = steps.length - 1;

  const otherSteps = (
    <div className="step">
      {steps.map((step, idx) => {
        if (idx === 0 || idx === total) {
          return null;
        }
        const activeColor = current >= idx ? Color.mainDark : Color.mainDark_30;
        return (
          <div key={idx} className="label" style={{color: activeColor}}>
            <LabelSet
              steps={steps}
              history={history}
              status={status}
              currentStep={step}
              tooltip={tooltip}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <StepperWrapper style={style} total={total} current={current}>
      <div className="progress-bar">
        <div className="done" />
        <div className="not-done" />
      </div>

      <div className="label-bar">
        <div className="first-step">
          <div className="label">
            <LabelSet
              steps={steps}
              history={history}
              status={status}
              currentStep={steps[0]}
              tooltip={tooltip}
            />
          </div>
        </div>

        {otherSteps}

        <div
          className="last-step"
          style={{
            color: isFailed
              ? Color.red
              : current === total
              ? Color.mainDark
              : Color.mainDark_30,
          }}>
          <div className="label">
            <LabelSet
              steps={steps}
              history={history}
              status={status}
              currentStep={steps[total]}
              isFailed={isFailed}
              isWithContact={isWithContact}
              tooltip={tooltip}
            />
          </div>
        </div>
      </div>
    </StepperWrapper>
  );
}

const LabelSet = ({
  currentStep,
  status,
  history,
  isFailed = false,
  isWithContact = true,
  tooltip,
  steps,
}) => {
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const totalStepsCount = steps.length;

  const placement = useMemo(() => {
    if (steps.indexOf(currentStep) + 1 === totalStepsCount) {
      return 'bottomRight';
    } else if (steps.indexOf(currentStep) + 1 === 1) {
      return 'bottomLeft';
    } else {
      return 'bottom';
    }
  }, [currentStep, steps, totalStepsCount]);

  const datetimeStyleSheet = useMemo(() => {
    if (steps.indexOf(currentStep) + 1 === totalStepsCount) {
      return {
        whiteSpace: 'nowrap',
        textAlign: 'right',
      };
    } else if (steps.indexOf(currentStep) + 1 === 1) {
      return {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      };
    } else {
      return {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      };
    }
  }, [currentStep, steps, totalStepsCount]);

  return (
    <LabelSetWrapper>
      {!smallDevice && tooltip && (
        <Tooltip
          title={getDateString(history[currentStep])}
          placement={placement}>
          <div className="title" style={datetimeStyleSheet}>
            {L.d(status[currentStep])}
          </div>
        </Tooltip>
      )}
      {(smallDevice || !tooltip) && (
        <FlexCol style={datetimeStyleSheet}>
          <div className="title">{L.d(status[currentStep])}</div>
          <div>{d2s(history[currentStep])}</div>
          <div>{d2s(history[currentStep], 'HH:mm')}</div>
        </FlexCol>
      )}
      {isFailed && isWithContact && (
        <div style={{...datetimeStyleSheet, top: 40}}>
          <div
            style={{
              display: 'flex',
              flexDirection: mobile ? 'column' : 'row',
              alignItems: mobile ? 'unset' : 'center',
            }}>
            <div>請聯絡</div>
            <UnderlineButton
              style={{
                color: Color.red,
                fontSize: 12,
                lineHeight: 1.67,
                fontWeight: '300',
                marginLeft: 4,
                ...datetimeStyleSheet,
              }}>
              線上客服
            </UnderlineButton>
          </div>
          {!mobile && <div>以取得協助</div>}
        </div>
      )}
    </LabelSetWrapper>
  );
};

const LabelSetWrapper = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;

  & .title {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
  }
`;

const StepperWrapper = styled.div`
  position: relative;

  & > .progress-bar {
    display: flex;

    & > .done,
    .not-done {
      border-radius: 3px;
    }

    & > .done {
      flex: ${props => (props.current === 0 ? 0.2 : props.current)};
      max-width: ${props => (props.current === 0 ? '20px' : 'unset')};
      height: 3px;
      background-color: black;
    }

    & > .not-done {
      flex: ${props => props.total - props.current};
      height: 3px;
      background-color: rgba(20, 20, 20, 0.06);
    }
  }

  & > .label-bar {
    display: flex;
    position: relative;
    cursor: pointer;
    margin-top: 8px;

    & > .step {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
    }

    @media screen and (max-width: ${Breakpoints.mobile}px) {
      & .label {
        font-size: 12px;
      }
    }
  }
`;

export default Stepper;
