import styled from 'styled-components';
import {
  Color,
  FlexCol,
  FlexColCenter,
  FlexRow,
} from '../../../../Components/Widget';
import {getFormatPrice} from '../../../../Utils/ProductUtil';
import React, {Fragment} from 'react';
import {useDimension} from '../../../../Hooks/AppHooks';
import {RETURN_STATUS} from '../../../../Domain/Return';

export const ReturnInvoice = props => {
  const {returnDetail, status, isDetailPage, returnOrderDetail = null} = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;

  if (isDetailPage) {
    return (
      <Wrapper smallDevice={smallDevice} className="invoice-wrapper">
        {!smallDevice && <div style={{flex: 1}} />}
        {!returnDetail && (
          <FlexColCenter className="invoice-info" style={{margin: '64px 0'}}>
            something error , plz try again later.
          </FlexColCenter>
        )}
        {returnDetail && (
          <FlexCol className="invoice-info">
            <FlexRow className="field">
              <label
                style={{
                  fontWeight: 300,
                  color: Color.mainDark_70,
                }}>
                退貨申請總金額
              </label>
              <strong style={{fontWeight: 500}}>
                NT {getFormatPrice(returnDetail.returnAmount)}
              </strong>
            </FlexRow>
            {returnOrderDetail.payment_status === 'refunded' && (
              <FlexRow className="field">
                <label
                  style={{
                    fontWeight: 300,
                    color: Color.mainDark_70,
                  }}>
                  實際退貨金額
                </label>
                <strong style={{fontWeight: 500}}>
                  NT {getFormatPrice(returnDetail.realAmount)}
                </strong>
              </FlexRow>
            )}
            <div className="separate" />
            {(status === RETURN_STATUS.done ||
              status === RETURN_STATUS.processing ||
              status === RETURN_STATUS.applied) && (
              <Fragment>
                <FlexRow className="field">
                  <label style={{fontWeight: 500}}>
                    退款總金額
                    <span
                      style={{
                        marginLeft: 8,
                        fontWeight: 300,
                        color: Color.mainDark_70,
                      }}>
                      (信用卡刷退)
                    </span>
                  </label>
                  <strong style={{fontWeight: 'bold', fontSize: 20}}>
                    {status === RETURN_STATUS.applied &&
                      `NT ${getFormatPrice(
                        returnDetail.returnAppliedCashAmount,
                      )}`}
                    {status === RETURN_STATUS.processing &&
                      returnOrderDetail.payment_status !== 'refunded' &&
                      `NT ${getFormatPrice(
                        returnDetail.returnAppliedCashAmount,
                      )}`}
                    {returnOrderDetail.payment_status === 'refunded' &&
                      `NT ${getFormatPrice(returnDetail.returnCashAmount)}`}
                  </strong>
                </FlexRow>
                <FlexRow className="field">
                  <label
                    style={{
                      fontWeight: 300,
                      color: Color.mainDark_70,
                    }}>
                    購物金返還
                  </label>
                  <strong style={{fontWeight: 500, color: Color.gold}}>
                    {status === RETURN_STATUS.applied &&
                      `NT ${getFormatPrice(
                        returnDetail.returnAppliedRebateAmount,
                      )}`}
                    {status === RETURN_STATUS.processing &&
                      returnOrderDetail.payment_status !== 'refunded' &&
                      `NT ${getFormatPrice(
                        returnDetail.returnAppliedRebateAmount,
                      )}`}
                    {returnOrderDetail.payment_status === 'refunded' &&
                      `NT ${getFormatPrice(returnDetail.returnRebateAmount)}`}
                  </strong>
                </FlexRow>
                <FlexRow
                  className="field"
                  style={{
                    justifyContent: smallDevice ? 'center' : 'space-between',
                  }}>
                  <div
                    style={{fontSize: 12, color: Color.red, fontWeight: 500}}>
                    敬請注意 :
                    經折抵使用之回饋金，將在退貨退款完成後返回，且將有三天使用期限。
                  </div>
                </FlexRow>
              </Fragment>
            )}
            <FlexRow style={{justifyContent: 'flex-end'}} className="field">
              <h1>
                {status === RETURN_STATUS.return_failed &&
                  returnDetail.realAmount === 0 &&
                  '不退款'}
              </h1>
            </FlexRow>
          </FlexCol>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper smallDevice={smallDevice} className="invoice-wrapper">
      {!smallDevice && <div style={{flex: 1}} />}
      {!returnDetail && (
        <FlexColCenter className="invoice-info" style={{margin: '64px 0'}}>
          something error , plz try again later.
        </FlexColCenter>
      )}
      {returnDetail && (
        <FlexCol className="invoice-info">
          <FlexRow className="field">
            <label
              style={{
                fontWeight: 300,
                color: Color.mainDark_70,
              }}>
              退貨申請總金額
            </label>
            <strong style={{fontWeight: 500}}>
              NT {getFormatPrice(returnDetail.returnAmount)}
            </strong>
          </FlexRow>
          <div className="separate" />
          <FlexRow className="field">
            <label style={{fontWeight: 500}}>
              退款總金額
              <span
                style={{
                  marginLeft: 8,
                  fontWeight: 300,
                  color: Color.mainDark_70,
                }}>
                (信用卡刷退)
              </span>
            </label>
            <strong style={{fontWeight: 'bold', fontSize: 20}}>
              NT {getFormatPrice(returnDetail.returnCashAmount)}
            </strong>
          </FlexRow>
          <FlexRow className="field">
            <label
              style={{
                fontWeight: 300,
                color: Color.mainDark_70,
              }}>
              購物金返還
            </label>
            <strong style={{fontWeight: 500, color: Color.gold}}>
              NT {getFormatPrice(returnDetail.returnRebateAmount)}
            </strong>
          </FlexRow>

          <FlexRow
            className="field"
            style={{
              justifyContent: smallDevice ? 'center' : 'space-between',
            }}>
            <div style={{fontSize: 12, color: Color.red, fontWeight: 500}}>
              敬請注意 :
              經折抵使用之回饋金，將在退貨退款完成後返回，且將有三天使用期限。
            </div>
          </FlexRow>
        </FlexCol>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexRow)`
  flex: 1;
  border-top: ${props =>
    props.smallDevice ? 'none' : `1px solid ${Color.mainDark_10}`};
  border-bottom: 1px solid ${Color.mainDark_10};
  padding: ${props => (props.smallDevice ? '0 20px' : '0')};
  & > .invoice-info {
    flex: 1;
    & > .separate {
      width: 100%;
      height: 1px;
      background-color: ${Color.mainDark_10};
    }
    & > .field {
      padding: 10px 0;
      justify-content: space-between;
      font-size: ${props => (props.smallDevice ? '12px' : '14px')};
    }
  }
`;
