import React from 'react';
import styled from 'styled-components';
import {Color} from '../../../Components/Widget';

const CheckBox = props => {
  const {id, label = '', checked, onChange, disabled, ...inputProps} = props;
  return (
    <StyledCheckBox className="checkbox" checked={checked} disabled={disabled}>
      <input
        disabled={disabled}
        checked={checked}
        id={id}
        type="checkbox"
        onChange={onChange}
        {...inputProps}
      />
      <span />
      <label htmlFor={id}>{label}</label>
    </StyledCheckBox>
  );
};

const StyledCheckBox = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.mainDark_10};
  padding-bottom: 12px;
  margin-bottom: 12px;
  filter: ${({disabled}) => (disabled ? 'opacity(30%)' : 'opacity(100%)')};
  & > input {
    display: none;
    visibility: hidden;
  }
  & > span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid ${Color.mainDark_30};
    background-color: ${({checked}) =>
      checked ? Color.mainDark : Color.mainWhite};
  }
  & > label {
    margin-left: 16px;
    font-size: 16px;
    font-weight: 500;
  }
`;

export default CheckBox;
