import styled from 'styled-components';
import {Color, FlexRowCenter} from '../../../../Components/Widget';
import React from 'react';
import RectButton, {BUTTON_SKIN} from '../../../../Components/RectButton';
import * as SvgIcon from '../../../../Components/SvgIcon';

export const Counter = props => {
  const {counterOperatorFuncs, item, style} = props;
  return (
    <Wrapper style={style} className="item-counter">
      <RectButton
        skin={BUTTON_SKIN.DEFAULT}
        style={{width: '20px', height: '20px', padding: 0}}
        text={hovered => (
          <SvgIcon.Minus
            size={20}
            color={hovered ? 'white' : Color.mainDark_7}
          />
        )}
        isDisabled={!item.isOn}
        onClick={() => {
          counterOperatorFuncs['onMinus'](item.id);
        }}
      />
      <div className="quantity">{item.returnQuantity}</div>
      <RectButton
        skin={BUTTON_SKIN.DEFAULT}
        style={{width: '20px', height: '20px', padding: 0}}
        text={hovered => (
          <SvgIcon.Plus
            size={20}
            color={hovered ? 'white' : Color.mainDark_7}
          />
        )}
        isDisabled={!item.isOn}
        onClick={() => {
          counterOperatorFuncs['onPlus'](item.id);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled(FlexRowCenter)`
  & > .quantity {
    height: 22px;
    width: 32px;
    line-height: 22px;
    text-align: center;
  }
  & > .border-btn {
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    outline: none;
    color: ${Color.mainDark};
    border: 1px solid ${Color.mainDark};
    background-color: ${Color.mainWhite};
    :disabled {
      color: ${Color.mainDark_30};
      border: 1px solid ${Color.mainDark_30};
    }
  }
`;
