import React, {Fragment, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import MaintainRecordItem from './MaintainRecordItem';
import {ChevronLeft} from '../../../Components/SvgIcon';
import {MENU} from '../MemberCenterMenu';
import {
  Actions,
  Breakpoints,
  Context,
  NavActions,
} from '../../../Contexts/AppContext';
import CustomNav from '../../../Components/CustomNav';
import {useDimension} from '../../../Hooks/AppHooks';

const MaintainHistory = () => {
  const app = useContext(Context);
  const {mobile, tablet} = useDimension();
  const [records, setRecords] = useState([]);
  useEffect(() => {
    const _fetch = async () => {
      try {
        Actions.setLoading(true);
        let payload = {
          limit: 100,
          offset: 0,
        };
        const resp = await Actions.getMaintainOrders(payload);
        setRecords(resp);
      } catch (e) {
      } finally {
        Actions.setLoading(false);
      }
    };
    _fetch();
  }, []);
  return (
    <Fragment>
      {(mobile || tablet) && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate(`/profile?active=${MENU.maintain}`);
              }}
            />
          }
          middle={<div>歷史紀錄</div>}
        />
      )}
      <Wrapper>
        <header>
          <div
            className="back"
            onClick={() => {
              NavActions.navigate(`/profile?active=${MENU.maintain}`);
            }}>
            <ChevronLeft />
            <div>返回</div>
          </div>
          <div className="title">歷史紀錄</div>
        </header>
        {records.map((r, index) => (
          <MaintainRecordItem record={r} key={index} />
        ))}
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  & > header {
    & > .back {
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 300;
    }
    & > .title {
      font-size: 30px;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px 35px;
    & > header {
      display: none;
    }
  }
  @media screen and (max-width: ${Breakpoints.mobile}px) {
  }
`;

export default MaintainHistory;
