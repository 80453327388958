import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import FavStoreItem from './FavStoreItem';
import {Actions} from '../../../Contexts/AppContext';
import {Color} from '../../../Components/Widget';
import {brandsArr} from '../../../Domain/Brand';
import Modal from '../Modal';
import useFavStores from '../../../Hooks/useFavStores';
import {useDimension} from '../../../Hooks/AppHooks';

function MyFavStore(props) {
  const {desktop} = useDimension();
  const modalRef = useRef();
  const {favStores, fetchFavStores} = useFavStores();

  useEffect(() => {
    const fetchAllStores = async () => {
      const promises = brandsArr.map(brand => {
        return Actions.getBrandStores({brand: brand});
      });
      const _resp = await Promise.all(promises);
      const allBrandStores = _resp.reduce(
        (acc, cur, curIndex) => ({...acc, [brandsArr[curIndex]]: cur}),
        {},
      );
      Actions.setAllBrandStores(allBrandStores);
    };
    fetchAllStores();
  }, []);

  return (
    <Wrapper>
      <h2>指定服務門市</h2>
      <p style={{color: Color.gold}}>
        若您需要維修、保養、線上諮詢...等協助，將由此門市人員替您服務
      </p>

      <div style={{marginTop: 32, maxWidth: 900}}>
        {favStores.map((favStore, idx) => (
          <FavStoreItem
            key={idx}
            item={favStore}
            mobile={!desktop}
            modalRef={modalRef}
            fetchFavStores={fetchFavStores}
          />
        ))}
      </div>

      <Modal
        mobile={!desktop}
        getInstance={inst => {
          modalRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px;

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.27;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default MyFavStore;
