import React, {Fragment} from 'react';
import styled from 'styled-components';
import {useDimension} from '../../Hooks/AppHooks';
import {
  MemberCenterLandingMenu,
  MemberCenterMenu,
  MENU,
} from './MemberCenterMenu';
import PersonalInfo from './PersonalInfo';
import ChangePassword from './ChangePassword';
import CardManagement from './CardMgmt/CardManagement';
import AddrManagement from './AddrMgmt/AddrManagement';
import MyFavStore from './FavStoreMgmt/MyFavStore';
import MyCredits from './MyCredits';
import MyOrders, {ordersActions} from './Orders/MyOrders';
import CustomNav from '../../Components/CustomNav';
import {useLoginRequired} from '../../Hooks/useLoginRequired';
import {ChevronLeft, LogoTungrp} from '../../Components/SvgIcon';
import {
  Actions,
  Breakpoints,
  Context,
  NavActions,
} from '../../Contexts/AppContext';
import {
  Color,
  FlexColCenter,
  FlexRow,
  UnderlineButton,
} from '../../Components/Widget';
import {MiniCartIcon} from '../../Components/MiniCartIcon';
import MaintainService from './MaintainService/MaintainService';
import MaintainHistory from './MaintainService/MaintainHistory';
import MaintainDetail from './MaintainService/MaintainDetail';
import MaintainApply from './MaintainService/MaintainApply';
import MemberLevel from './MemberLevel/MemberLevel';
import BackButton from '../../Components/BackButton';

const qs = require('query-string');

function IndividualPageBackButton(props) {
  const {route, backLabel} = props;
  return (
    <FlexRow
      onClick={() => {
        NavActions.navigate(route);
      }}
      style={{fontSize: 16, fontWeight: 300, cursor: 'pointer'}}>
      <ChevronLeft />
      <div>{backLabel}</div>
    </FlexRow>
  );
}

const IndividualPageFooter = () => {
  return (
    <StyledIndividualPageFooter>
      <div className="title">需要協助 ?</div>
      <div className="subtitle">
        您可以在星期一至星期五，9:00 至 18:00{' '}
        <UnderlineButton style={{color: Color.mainWhite_70}}>
          聯絡線上客服
        </UnderlineButton>
        ，或撥打客服專線{' '}
        <UnderlineButton
          onClick={() => {
            window.open('tel:0800336677', '_self');
          }}
          style={{color: Color.mainWhite_70}}>
          0800336677
        </UnderlineButton>
        。
      </div>
    </StyledIndividualPageFooter>
  );
};

const StyledIndividualPageFooter = styled(FlexColCenter)`
  width: 100%;
  height: 128px;
  background-color: ${Color.mainDark};
  padding: 40px;
  & > .title {
    font-size: 14px;
    font-weight: 500;
    color: ${Color.mainWhite};
  }
  & > .subtitle {
    font-size: 14px;
    font-weight: 300;
    color: ${Color.mainWhite_70};
    text-align: center;
  }
`;

function ProfilePage(props) {
  const {desktop, tablet} = useDimension();
  const {active, orderId, action, subOrderId, returnOrderId} = qs.parse(
    props.location.search,
  );

  const {isLogin, loginRedirectElem} = useLoginRequired({
    hintEle: (
      <div
        style={{
          fontSize: 14,
          fontWeight: '300',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.57,
        }}>
        立即登入，查看完整的會員資訊！
      </div>
    ),
    wrapperStyle: {
      borderBottom: `1px solid ${Color.mainDark_10}`,
    },
  });
  const hasActiveRoute = Object.values(MENU).indexOf(`${active}`) > -1;
  const app = React.useContext(Context);
  const user = app.currentUser;

  function renderActiveRoute() {
    if (!isLogin && active === MENU.memberLevel) {
      return <MemberLevel isLogin={isLogin} />;
    }

    if (!isLogin) {
      return <Fragment>{loginRedirectElem}</Fragment>;
    }
    return (
      <Fragment>
        {active === MENU.credits && <MyCredits />}
        {active === MENU.personalInfo && <PersonalInfo />}
        {active === MENU.changePassword && <ChangePassword />}
        {active === MENU.cardManagement && <CardManagement />}
        {active === MENU.addrManagement && <AddrManagement />}
        {active === MENU.myFavStore && <MyFavStore />}
        {active === MENU.orders && (
          <MyOrders
            action={action}
            orderId={orderId}
            subOrderId={subOrderId}
            returnOrderId={returnOrderId}
          />
        )}
        {active === MENU.maintain && <MaintainService />}
        {active === MENU.maintainHistory && <MaintainHistory />}
        {active === MENU.maintainDetail && <MaintainDetail {...props} />}
        {active === MENU.maintainApply && <MaintainApply />}
        {active === MENU.memberLevel && <MemberLevel isLogin={isLogin} />}
      </Fragment>
    );
  }

  const getIndividualPageNavProps = () => {
    if (orderId && action === ordersActions.RE_CHECKOUT) {
      return {
        backLabel: '訂單明細',
        route: `/profile?active=orders&orderId=${orderId}`,
        middle: <div>重新付款</div>,
        right: null,
      };
    } else if (orderId && action === ordersActions.RETURN) {
      return {
        backLabel: '訂單明細',
        route: `/profile?active=orders&orderId=${orderId}`,
        middle: <div>申請退貨</div>,
        right: null,
      };
    } else if (orderId && action === ordersActions.RETURN_DETAIL) {
      return {
        backLabel: '訂單明細',
        route: `/profile?active=orders&orderId=${orderId}`,
        middle: <div>退貨狀態</div>,
        right: null,
      };
    } else {
      return {
        backLabel: '首頁',
        route: `/home`,
        middle: <LogoTungrp w={28} h={38} />,
        right: <MiniCartIcon style={{cursor: 'pointer'}} />,
      };
    }
  };

  const individualPageNav = (
    <CustomNav
      navType="solid"
      left={
        <BackButton
          title={getIndividualPageNavProps().backLabel}
          onClick={() => {
            NavActions.navigate(getIndividualPageNavProps().route);
          }}
        />
      }
      middle={getIndividualPageNavProps().middle}
      right={getIndividualPageNavProps().right}
    />
  );

  const withoutMenu =
    active === MENU.maintainApply ||
    action === ordersActions.RE_CHECKOUT ||
    action === ordersActions.RETURN ||
    action === ordersActions.RETURN_DETAIL;

  if (!desktop) {
    return (
      <Fragment>
        {
          <CustomNav
            navType="solid"
            left={
              !hasActiveRoute ? null : (
                <ChevronLeft
                  onClick={() => {
                    NavActions.navigate('/profile');
                  }}
                />
              )
            }
            middle={
              <Fragment>
                {!active && <div>會員中心</div>}
                {active === MENU.maintain && <div>維修服務</div>}
                {active === MENU.maintainHistory && <div>歷史紀錄</div>}
                {active === MENU.maintainDetail && <div>維修訂單明細</div>}
                {active === MENU.maintainApply && <div>申請維修</div>}
                {active === MENU.orders && <div>購買記錄</div>}
                {active === MENU.personalInfo && <div>個人資料</div>}
                {active === MENU.changePassword && <div>變更密碼</div>}
                {active === MENU.addrManagement && <div>常用地址</div>}
                {active === MENU.cardManagement && <div>信用卡管理</div>}
                {active === MENU.myFavStore && <div>指定服務門市</div>}
                {active === MENU.memberLevel && <div>會員等級 / 權益</div>}
              </Fragment>
            }
            right={
              <Fragment>
                {active === MENU.maintain && (
                  <UnderlineButton
                    onClick={() => {
                      NavActions.navigate(
                        `/profile?active=${MENU.maintainHistory}`,
                      );
                    }}>
                    歷史紀錄
                  </UnderlineButton>
                )}
              </Fragment>
            }
          />
        }
        <MobileWrapper withPadding={!active}>
          {!hasActiveRoute && user && (
            <div className="greeting">嗨，{user.name}</div>
          )}
          {renderActiveRoute()}
          {!hasActiveRoute && <MemberCenterMenu active={active} />}
        </MobileWrapper>

        {withoutMenu && <IndividualPageFooter />}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {desktop && individualPageNav}
      <Wrapper
        withPadding={active !== MENU.maintainApply}
        withoutMenu={withoutMenu}
        tablet={tablet}>
        {hasActiveRoute ? (
          <Fragment>
            {withoutMenu ? (
              <Fragment>{renderActiveRoute()}</Fragment>
            ) : (
              <Fragment>
                <div className="menu">
                  <MemberCenterMenu active={active} />
                </div>
                <div className="content">{renderActiveRoute()}</div>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <div className="landing-menu">
            <div className="header">
              <h2>會員中心</h2>
              {user ? (
                <>
                  <p>嗨，{user.name}</p>
                  <div
                    style={{marginBottom: 16}}
                    className="logout-btn"
                    onClick={() => {
                      Actions.logout();
                      window.location.reload();
                    }}>
                    登出
                  </div>
                </>
              ) : (
                <div
                  className="login-btn"
                  onClick={() => NavActions.navigate('/login')}>
                  立即登入
                </div>
              )}
            </div>

            <MemberCenterLandingMenu />
          </div>
        )}
      </Wrapper>

      {withoutMenu && <IndividualPageFooter />}
    </Fragment>
  );
}

const MobileWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({withPadding}) => (withPadding ? '20px' : '0')};
  & > .greeting {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    padding-left: 20px;
    margin-bottom: 31px;
    margin-top: 15px;
  }
`;

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  min-height: 100vh;
  justify-content: center;
  padding: ${({withPadding}) => (withPadding ? '40px 50px' : '0px 0px')};
  position: relative;
  & > .menu {
    width: 100%;
    max-width: 392px;
    position: sticky;
    top: 150px;
    left: 0;
    height: 10000%;
  }
  & > .content {
    flex: 1;
    max-width: calc(100% - 240px);
    position: relative;
  }

  & > .landing-menu {
    padding: ${props => (props.tablet ? '40px 35px 64px' : '80px 35px 64px')};
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;

    & > .header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      & > h2 {
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        color: ${Color.mainDark};
        margin-right: 20px;
      }
      & > p {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: ${Color.mainDark};
        padding-right: 10px;
        border-right: 1px solid ${Color.mainDark_30};
      }

      & > .logout-btn {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-left: 10px;
        cursor: pointer;
      }

      & > .login-btn {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #cca75c;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    max-height: unset;
  }
`;

export default ProfilePage;
