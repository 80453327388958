import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import CardItem from './CardItem';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import * as AppContext from '../../../Contexts/AppContext';
import {Actions} from '../../../Contexts/AppContext';
import Modal from '../Modal';
import Overlay from '../Overlay';
import {Color} from '../../../Components/Widget';
import {useDimension} from '../../../Hooks/AppHooks';

function CardManagement(props) {
  const {desktop} = useDimension();
  const [cards, setCards] = React.useState([]);
  const modalRef = React.useRef();
  const overlayRef = React.useRef();
  const [addCreditCardForm, setAddCreditCardForm] = useState('');

  const fetchData = React.useCallback(async () => {
    try {
      const _cards = await AppContext.Actions.getMyCreditCards();
      setCards(_cards);
    } catch (ex) {
      console.warn(ex);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addCreditCard = useCallback(async () => {
    const resp = await Actions.getBindCreditCardForm(
      '/profile?active=card_management',
    );
    const form = resp.form_html;
    setAddCreditCardForm(form);
  }, []);

  useEffect(() => {
    if (addCreditCardForm) {
      const form = document
        .getElementById('add-credit-card-hidden-form')
        .querySelector('form[name="ecpay"]');
      form.submit();
    }
  }, [addCreditCardForm]);

  return (
    <>
      <Wrapper desktop={desktop} isEmpty={cards.length <= 0}>
        {desktop && <h2>信用卡管理</h2>}

        <div style={{marginTop: desktop ? 32 : 0, maxWidth: 900}}>
          {cards.length <= 0 && (
            <div
              style={{
                margin: '16px 0',
                textAlign: !desktop ? 'center' : 'left',
                fontWeight: 300,
              }}>
              尚無常用信用卡
              {!desktop ? <br /> : <span>，</span>}
              立即新增讓結帳更快速！
            </div>
          )}
          {cards.map((card, index) => (
            <CardItem
              card={card}
              key={index}
              mobile={!desktop}
              modalRef={modalRef}
              overlayRef={overlayRef}
              fetchCards={fetchData}
            />
          ))}
        </div>

        <RectButton
          text="+ 新增信用卡"
          onClick={addCreditCard}
          skin={BUTTON_SKIN.CRYSTAL}
          style={{
            width: !desktop ? '100%' : '194px',
            color: Color.gold,
            borderColor: Color.gold,
          }}
        />
        <div style={{paddingTop: 16, fontSize: 12, fontWeight: 500}}>
          新增信用卡完成後，系統會試刷 NT$5 元，但不會從您的帳戶扣款。
        </div>

        <div
          id="add-credit-card-hidden-form"
          style={{display: 'none'}}
          dangerouslySetInnerHTML={{__html: addCreditCardForm}}
        />
      </Wrapper>

      <Modal
        mobile={!desktop}
        getInstance={inst => {
          modalRef.current = inst;
        }}
      />

      <Overlay
        mobile={!desktop}
        getInstance={inst => {
          overlayRef.current = inst;
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({isEmpty}) => (isEmpty ? 'center' : 'flex-start')};

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.27;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default CardManagement;
