import React from 'react';
import styled from 'styled-components';
import {Color} from '../../../Components/Widget';
import {ArrowRight} from '../../../Components/SvgIcon';
import {Breakpoints} from '../../../Contexts/AppContext';

export const StepItem = ({
  icon = null,
  title = '',
  subtitle = '',
  arrow,
  children,
}) => {
  return (
    <Wrapper>
      {arrow && (
        <ArrowRight size={32} color={Color.mainDark} className="arrow-right" />
      )}
      <div className="icon-wrapper">{icon}</div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 32px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .arrow-right {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(65px) translateX(-50%);
  }
  & > button {
    width: 160px;
  }
  & > .icon-wrapper {
    width: 100px;
    height: 100px;
    background-color: ${Color.mainWhite};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    & > img {
      width: 80px;
      height: 80px;
    }
  }
  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
    }
    & > .subtitle {
      text-align: center;
      font-size: 14px;
      color: ${Color.mainDark_50};
      font-weight: 500;
      max-width: 190px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 25px 20px;
    flex-direction: row;
    & > .arrow-right {
      top: 0;
      left: 0;
      transform: rotate(90deg) translateX(-18px) translateY(-35px);
    }
    & > button {
    }
    & > .icon-wrapper {
      width: 60px;
      height: 60px;
      margin-right: 12px;
      margin-bottom: unset;
      & > img {
        width: 40px;
        height: 40px;
      }
    }
    & > .content {
      flex: 1;
      align-items: flex-start;
      & > .title {
      }
      & > .subtitle {
        margin-bottom: unset;
        max-width: unset;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.mobile}px) {
    flex-wrap: wrap;
    & > .arrow-right {
    }
    & > button {
      flex: 1;
      margin-top: 17px;
    }
    & > .icon-wrapper {
      & > img {
      }
    }
    & > .content {
      min-width: 150px;
      & > .title {
      }
      & > .subtitle {
        text-align: left;
      }
    }
  }
`;
