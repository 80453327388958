import React, {Fragment} from 'react';
import styled from 'styled-components';
import Stepper from './Stepper';
import {UnderlineButton} from '../../../Components/Widget';
import {Copy} from '../../../Components/SvgIcon';
import {
  STEPS_BY_SUBORDER_SHIPPING_STATUS,
  SUBORDER_SHIPPING_STATUS,
  SUBORDER_SHIPPING_STATUS_DISPLAY,
} from '../../../Domain/Order';
import CopyToClipboard from '../../../Components/CopyToClipboard';
import {Actions} from '../../../Contexts/AppContext';

function OrderShipping(props) {
  const {order, subOrder, tooltip = false, style = {}} = props;
  const shippingType = order.config.shipping.pick_up_address.type;
  const state = subOrder.shippingStatus;
  const isFailed = state === SUBORDER_SHIPPING_STATUS.failed;
  const steps =
    STEPS_BY_SUBORDER_SHIPPING_STATUS[
      `${shippingType}_${isFailed ? 'failed' : 'normal'}`
    ];
  const currentStep = steps.indexOf(state);

  return (
    <ShippingWrapper style={style}>
      {subOrder.logistic.logistic_id !== '' && (
        <div className="row">
          <UnderlineButton
            onClick={() => {
              window.open(
                `https://htm.sf-express.com/tw/tc/dynamic_function/waybill/#search/bill-number/${subOrder.logistic.logistic_id}`,
              );
            }}>
            查看物流
          </UnderlineButton>
          <Fragment>
            <p style={{margin: '0 10px'}}>{subOrder.logistic.logistic_id}</p>

            <CopyToClipboard
              text={subOrder.logistic.logistic_id}
              showTooltip={false}>
              <Copy
                onClick={() => {
                  Actions.setGlobalToastContent(
                    <label style={{color: '#fff'}}>複製成功</label>,
                  );
                  setTimeout(() => {
                    Actions.setGlobalToastContent(null);
                  }, 2000);
                }}
              />
            </CopyToClipboard>
          </Fragment>
        </div>
      )}

      <Stepper
        isFailed={isFailed}
        status={SUBORDER_SHIPPING_STATUS_DISPLAY[shippingType]}
        history={subOrder.shippingStatusHistory}
        steps={steps}
        current={currentStep}
        tooltip={tooltip}
      />
    </ShippingWrapper>
  );
}

const ShippingWrapper = styled.div`
  & > .row {
    display: flex;
    margin-bottom: 20px;
  }
`;

export default OrderShipping;
