import React from 'react';
import styled from 'styled-components';

function Modal(props) {
  const {getInstance, mobile} = props;
  const [content, setContent] = React.useState(null);

  if (getInstance) {
    getInstance({
      open: nextContent => setContent(nextContent),
      close: () => setContent(null),
    });
  }

  const visible = !!content;

  return (
    <>
      <Backdrop visible={visible} />
      <Dialog visible={visible} mobile={mobile}>
        {content}
      </Dialog>
    </>
  );
}

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 300ms;
`;

const Backdrop = styled(FullScreenWrapper)`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 12;
`;

const Dialog = styled(FullScreenWrapper)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props =>
    props.visible
      ? 'translateY(0px)'
      : props.mobile
      ? 'translateY(-500px)'
      : 'translateY(300px)'};
  transition: 300ms;
  background-color: transparent;
  z-index: 13;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.mobile ? 'flex-start' : 'center')};
  align-items: ${props => (props.mobile ? 'stretch' : 'center')};
`;

export default Modal;
