import React, {useContext} from 'react';
import styled from 'styled-components';
import Stepper from '../Orders/Stepper';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import {Color} from '../../../Components/Widget';
import {
  getCurrentStepIndex,
  MAINTAIN_STATUS,
  MAINTAIN_STATUS_DISPLAY,
  MAINTAIN_STATUS_FOR_STEPPER,
} from '../../../Domain/Maintain';
import {Breakpoints, Context, NavActions} from '../../../Contexts/AppContext';
import {MENU} from '../MemberCenterMenu';
import {brandsDisplayArr, brandIdToBrandName} from '../../../Domain/Brand';
import * as L from '../../../Utils/Lang';

const MaintainRecordItem = props => {
  const app = useContext(Context);
  const {record, enableDetailAction = true} = props;
  return (
    <Wrapper>
      <header>
        <div className="brand-and-category">
          <div className="brand">
            {
              brandsDisplayArr.find(
                b => b.key === brandIdToBrandName[record.brand_id],
              ).display
            }
          </div>
          <div className="category">戒指</div>
        </div>
        <div className="record-number">
          <div className="label">申請編號</div>
          <div className="number">{record.number}</div>
        </div>
      </header>
      <div className="content">
        <div className="brand-and-category">
          <div className="brand">
            {
              brandsDisplayArr.find(
                b => b.key === brandIdToBrandName[record.brand_id],
              ).display
            }
          </div>
          <div className="category">backend should response</div>
        </div>
        <div className="separate-one" />
        <div className="content-inner">
          <div className="record-status">
            <div className="status">
              {L.d(MAINTAIN_STATUS_DISPLAY[record.status])}
            </div>
            <div className="status-description">
              <span className="red">
                {record.status === MAINTAIN_STATUS.refused &&
                  '您已拒絕此報價，請等待客服人員與您聯繫'}
              </span>
              <span className="normal">
                {record.status === MAINTAIN_STATUS.waiting &&
                  `請攜帶商品至 ${L.d(
                    app.allStoreData?.[
                      brandIdToBrandName[record.brand_id]
                    ]?.find(s => s.id === record.store_id)?.name,
                  )}（非台灣購買的商品需攜帶保卡）`}
                {record.status === MAINTAIN_STATUS.estimating &&
                  '等待原廠評估並提供報價'}
                {record.status === MAINTAIN_STATUS.estimated &&
                  '待您同意後即可開始維修'}
                {record.status === MAINTAIN_STATUS.canceled && ''}

                {record.status === MAINTAIN_STATUS.retrieved &&
                  `商品已送回 ${L.d(
                    app.allStoreData[brandIdToBrandName[record.brand_id]].find(
                      s => s.id === record.store_id,
                    ).name,
                  )}，請儘速取回`}
              </span>
              {/*<span className="gold">Lorem.</span>*/}
            </div>
          </div>
          {!(
            record.status === MAINTAIN_STATUS.waiting ||
            record.status === MAINTAIN_STATUS.canceled
          ) && (
            <Stepper
              steps={Object.values(MAINTAIN_STATUS_FOR_STEPPER)}
              current={getCurrentStepIndex(record.status)}
              status={MAINTAIN_STATUS_DISPLAY}
              history={record.status_history}
              isFailed={false}
              isWithContact={false}
            />
          )}
        </div>
        {enableDetailAction && <div className="separate-two" />}
        {enableDetailAction && record.status !== MAINTAIN_STATUS.estimated && (
          <RectButton
            text="查看明細"
            onClick={() => {
              NavActions.navigate(
                `/profile?active=${MENU.maintainDetail}&id=${record.id}`,
              );
            }}
            skin={BUTTON_SKIN.DEFAULT}
          />
        )}
        {enableDetailAction && record.status === MAINTAIN_STATUS.estimated && (
          <RectButton
            text="查看報價"
            onClick={() => {
              NavActions.navigate(
                `/profile?active=${MENU.maintainDetail}&id=${record.id}`,
              );
            }}
            skin={BUTTON_SKIN.PRIMARY}
          />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 970px;
  flex: 1;
  border: 1px solid ${Color.mainDark_10};
  margin-bottom: 24px;
  & > header {
    display: flex;
    background-color: ${Color.mainDark_03};
    padding: 15px 32px;
    & > .brand-and-category {
      display: none;
    }
    & > .record-number {
      flex: 1;
      display: flex;
      font-size: 14px;
      font-weight: normal;
      & > .label {
        margin-right: 8px;
      }
    }
  }
  & > .content {
    display: flex;
    padding: 24px 32px;
    & > .brand-and-category {
      display: flex;
      flex-direction: column;
      min-width: 136px;
      & > .brand {
        font-size: 16px;
        font-weight: normal;
      }
      & > .category {
        font-size: 20px;
        font-weight: bold;
      }
    }
    & > .separate-one {
      margin: 0 32px;
      width: 1px;
      background-color: ${Color.mainDark_10};
    }
    & > .content-inner {
      flex: 1;
      & > .record-status {
        display: flex;
        flex-direction: column;
        & > .status {
          font-size: 20px;
          font-weight: 300;
        }
        & > .status-description {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 16px;
          & > .normal {
            color: ${Color.mainDark_70};
          }
          & > .red {
            color: ${Color.red};
          }
          & > .gold {
            color: ${Color.gold};
          }
        }
      }
    }
    & > button {
      align-self: center;
      margin-left: 61px;
      width: 160px;
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    & > header {
      & > .brand-and-category {
        display: flex;
        & > .brand {
          font-size: 16px;
          font-weight: normal;
        }
        & > .category {
          font-size: 16px;
          font-weight: bold;
          margin-left: 8px;
        }
      }
      & > .record-number {
        justify-content: flex-end;
        & > .label {
          display: none;
        }
        & > .number {
          font-size: 14px;
          font-weight: normal;
          color: ${Color.mainDark_50};
        }
      }
    }
    & > .content {
      flex-direction: column;
      & > .brand-and-category {
        display: none;
      }
      & > .separate-two {
        height: 1px;
        background-color: ${Color.mainDark_10};
        margin: 40px 0 20px 0;
      }
      & > button {
        margin-left: unset;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.mobile}px) {
    & > header {
      & > .record-number {
        & > .label {
        }
      }
    }
    & > .content {
      & > .brand-and-category {
        & > .brand {
        }
        & > .category {
        }
      }
      & > .separate {
      }
      & > .content-inner {
        & > .record-status {
          & > .status {
          }
          & > .status-description {
            & > .normal {
            }
            & > .red {
            }
            & > .gold {
            }
          }
        }
      }
      & > button {
        margin-left: unset;
        width: unset;
        align-self: stretch;
      }
    }
  }
`;

export default MaintainRecordItem;
