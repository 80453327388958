export const MAINTAIN_STATUS = {
  waiting: 'waiting',
  estimating: 'estimating',
  estimated: 'estimated',
  refused: 'refused',
  canceled: 'canceled',
  maintaining: 'maintaining',
  retrieved: 'retrieved',
  done: 'done',
};

export const MAINTAIN_STATUS_FOR_STEPPER = {
  estimating: 'estimating',
  maintaining: 'maintaining',
  retrieved: 'retrieved',
  done: 'done',
};

export const MAINTAIN_STATUS_DISPLAY = {
  [MAINTAIN_STATUS.waiting]: {
    zh: '等待送件',
    en: 'waiting',
  },
  [MAINTAIN_STATUS.estimating]: {
    zh: '評估報價中',
    en: 'estimating',
  },
  [MAINTAIN_STATUS.estimated]: {
    zh: '已報價',
    en: 'estimated',
  },
  [MAINTAIN_STATUS.refused]: {
    zh: '已報價',
    en: 'refused',
  },
  [MAINTAIN_STATUS.canceled]: {
    zh: '已取消',
    en: 'canceled',
  },
  [MAINTAIN_STATUS.maintaining]: {
    zh: '維修/保養中',
    en: 'maintaining',
  },
  [MAINTAIN_STATUS.retrieved]: {
    zh: '待取貨',
    en: 'retrieved',
  },
  [MAINTAIN_STATUS.done]: {
    zh: '已完成',
    en: 'done',
  },
};

export const CATEGORY_MAP = {
  earring: 'earring',
  ring: 'ring',
  necklace: 'necklace',
  watch: 'watch',
  coat: 'coat',
  skirt: 'skirt',
  dress: 'dress',
};

export const SERVICE_MAP = {
  needle_to_clip: 'needle_to_clip',
  clip_to_needle: 'clip_to_needle',
  change_components: 'change_components',
  make_up_diamond: 'make_up_diamond',
  welding: 'welding',
  touch_up: 'touch_up',
  modify_ring_size: 'modify_ring_size',
  modify_necklace_size: 'modify_necklace_size',
  sleeves_shorten_lengthen: 'sleeves_shorten_lengthen',
  body_narrowing_enlarging: 'body_narrowing_enlarging',
  length_shorten: 'length_shorten',
  length_lengthen: 'length_lengthen',
  shoulder_raise: 'shoulder_raise',
  inward: 'inward',
  sleeves_shorten: 'sleeves_shorten',
  change_bust_waist: 'change_bust_waist',
  waist_narrowing: 'waist_narrowing',
  waist_enlarging: 'waist_enlarging',
  waist_narrowing_enlarging: 'waist_narrowing_enlarging',
};

export const GET_CATEGORY_OPTIONS_BY_BRAND = id => {
  switch (parseInt(id)) {
    case 2:
    case 4:
      return [
        {
          value: CATEGORY_MAP.earring,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.earring],
          disabled: false,
        },
        {
          value: CATEGORY_MAP.ring,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.ring],
          disabled: false,
        },
        {
          value: CATEGORY_MAP.necklace,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.necklace],
          disabled: false,
        },
        {
          value: CATEGORY_MAP.watch,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.watch],
          disabled: false,
        },
      ];
    case 1:
    case 3:
    case 5:
      return [
        {
          value: CATEGORY_MAP.coat,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.coat],
          disabled: false,
        },
        {
          value: CATEGORY_MAP.skirt,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.skirt],
          disabled: false,
        },
        {
          value: CATEGORY_MAP.dress,
          display: CATEGORY_OPTIONS_DISPLAY[CATEGORY_MAP.dress],
          disabled: false,
        },
      ];
    default:
      return [];
  }
};

export const CATEGORY_OPTIONS_DISPLAY = {
  [CATEGORY_MAP.coat]: {zh: '外套', en: ''},
  [CATEGORY_MAP.ring]: {zh: '戒指', en: ''},
  [CATEGORY_MAP.skirt]: {zh: '裙子', en: ''},
  [CATEGORY_MAP.watch]: {zh: '手錶', en: ''},
  [CATEGORY_MAP.dress]: {zh: '洋裝', en: ''},
  [CATEGORY_MAP.necklace]: {zh: '項鍊', en: ''},
  [CATEGORY_MAP.earring]: {zh: '耳環', en: ''},
};

export const SERVICE_OPTIONS_DISPLAY = {
  [SERVICE_MAP.needle_to_clip]: {zh: '耳環針式改夾式', en: ''},
  [SERVICE_MAP.clip_to_needle]: {zh: '耳環夾式改針式', en: ''},
  [SERVICE_MAP.change_components]: {zh: '換零件', en: ''},
  [SERVICE_MAP.make_up_diamond]: {zh: '補鑽', en: ''},
  [SERVICE_MAP.welding]: {zh: '焊接', en: ''},
  [SERVICE_MAP.touch_up]: {zh: '補漆 / 琺瑯', en: ''},
  [SERVICE_MAP.modify_ring_size]: {zh: '修改戒圍', en: ''},
  [SERVICE_MAP.modify_necklace_size]: {zh: '修改鍊身', en: ''},
  [SERVICE_MAP.sleeves_shorten_lengthen]: {
    zh: '袖身改短，貼布放長',
    en: '',
  },
  [SERVICE_MAP.body_narrowing_enlarging]: {zh: '衣身改窄，放大', en: ''},
  [SERVICE_MAP.length_shorten]: {zh: '長度改短', en: ''},
  [SERVICE_MAP.length_lengthen]: {zh: '長度放長', en: ''},
  [SERVICE_MAP.shoulder_raise]: {zh: '肩提高', en: ''},
  [SERVICE_MAP.inward]: {zh: '有袖長的肩往內移', en: ''},
  [SERVICE_MAP.sleeves_shorten]: {zh: '袖長改短', en: ''},
  [SERVICE_MAP.change_bust_waist]: {zh: '改胸圍及腰圍', en: ''},
  [SERVICE_MAP.waist_narrowing]: {zh: '腰圍改窄', en: ''},
  [SERVICE_MAP.waist_enlarging]: {zh: '腰圍放大', en: ''},
  [SERVICE_MAP.waist_narrowing_enlarging]: {zh: '腰圍改窄、放大', en: ''},
};

export const SERVICE_OPTIONS_BY_CATEGORY = {
  [CATEGORY_MAP.earring]: [
    {
      value: SERVICE_MAP.needle_to_clip,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.needle_to_clip],
      disabled: false,
    },
    {
      value: SERVICE_MAP.clip_to_needle,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.clip_to_needle],
      disabled: false,
    },
    {
      value: SERVICE_MAP.change_components,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.change_components],
      disabled: false,
    },
    {
      value: SERVICE_MAP.make_up_diamond,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.make_up_diamond],
      disabled: false,
    },
    {
      value: SERVICE_MAP.welding,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.welding],
      disabled: false,
    },
    {
      value: SERVICE_MAP.touch_up,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.touch_up],
      disabled: false,
    },
  ],
  [CATEGORY_MAP.ring]: [
    {
      value: SERVICE_MAP.change_components,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.change_components],
      disabled: false,
    },
    {
      value: SERVICE_MAP.modify_ring_size,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.modify_ring_size],
      disabled: false,
    },
    {
      value: SERVICE_MAP.make_up_diamond,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.make_up_diamond],
      disabled: false,
    },
    {
      value: SERVICE_MAP.welding,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.welding],
      disabled: false,
    },
    {
      value: SERVICE_MAP.touch_up,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.touch_up],
      disabled: false,
    },
  ],
  [CATEGORY_MAP.necklace]: [
    {
      value: SERVICE_MAP.change_components,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.change_components],
      disabled: false,
    },
    {
      value: SERVICE_MAP.make_up_diamond,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.make_up_diamond],
      disabled: false,
    },
    {
      value: SERVICE_MAP.welding,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.welding],
      disabled: false,
    },
    {
      value: SERVICE_MAP.modify_necklace_size,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.modify_necklace_size],
      disabled: false,
    },
    {
      value: SERVICE_MAP.touch_up,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.touch_up],
      disabled: false,
    },
  ],
  [CATEGORY_MAP.watch]: [
    {
      value: SERVICE_MAP.change_components,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.change_components],
      disabled: false,
    },
    {
      value: SERVICE_MAP.make_up_diamond,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.make_up_diamond],
      disabled: false,
    },
    {
      value: SERVICE_MAP.welding,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.welding],
      disabled: false,
    },
    {
      value: SERVICE_MAP.touch_up,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.touch_up],
      disabled: false,
    },
  ],
  [CATEGORY_MAP.coat]: [
    {
      value: SERVICE_MAP.sleeves_shorten_lengthen,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.sleeves_shorten_lengthen],
      disabled: false,
    },
    {
      value: SERVICE_MAP.body_narrowing_enlarging,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.body_narrowing_enlarging],
      disabled: false,
    },
  ],
  [CATEGORY_MAP.skirt]: [
    {
      value: SERVICE_MAP.waist_narrowing,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.waist_narrowing],
      disabled: false,
    },
    {
      value: SERVICE_MAP.length_shorten,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.length_shorten],
      disabled: false,
    },
    {
      value: SERVICE_MAP.length_lengthen,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.length_lengthen],
      disabled: false,
    },
    {
      value: SERVICE_MAP.waist_enlarging,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.waist_enlarging],
      disabled: false,
    },
  ],
  [CATEGORY_MAP.dress]: [
    {
      value: SERVICE_MAP.shoulder_raise,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.shoulder_raise],
      disabled: false,
    },
    {
      value: SERVICE_MAP.length_shorten,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.length_shorten],
      disabled: false,
    },
    {
      value: SERVICE_MAP.inward,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.inward],
      disabled: false,
    },
    {
      value: SERVICE_MAP.sleeves_shorten,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.sleeves_shorten],
      disabled: false,
    },
    {
      value: SERVICE_MAP.change_bust_waist,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.change_bust_waist],
      disabled: false,
    },
    {
      value: SERVICE_MAP.waist_narrowing_enlarging,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.waist_narrowing_enlarging],
      disabled: false,
    },
    {
      value: SERVICE_MAP.length_shorten,
      display: SERVICE_OPTIONS_DISPLAY[SERVICE_MAP.length_shorten],
      disabled: false,
    },
  ],
};

export const getCurrentStepIndex = status => {
  if (
    status === MAINTAIN_STATUS.waiting ||
    status === MAINTAIN_STATUS.estimating ||
    status === MAINTAIN_STATUS.estimated ||
    status === MAINTAIN_STATUS.refused ||
    status === MAINTAIN_STATUS.canceled
  ) {
    return 0;
  } else if (status === MAINTAIN_STATUS.maintaining) {
    return 1;
  } else if (status === MAINTAIN_STATUS.retrieved) {
    return 2;
  } else {
    return 3;
  }
};
